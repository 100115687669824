import "./watchHistoryAllList.css";
import { DataGrid } from "@material-ui/data-grid";
// import { Visibility } from "@material-ui/icons";
// import { Link } from "react-router-dom";
import { useContext, useEffect,useState } from "react";
import { MovieContext } from "../../context/movieContext/MovieContext";
import { getWatchHistoryList,getWatchHistoryListByLocation } from "../../context/movieContext/apiCalls";
import Loader from "../../components/loader/Loader";
// import { getGenresList } from "../../context/genreContext/apiCalls";
import { cityData } from "../../json/cityName";
import Select from 'react-select'




export default function WatchHistoryAllList() {
  const { isFetching, movies, dispatch } = useContext(MovieContext);
  const [options, setOptions] = useState([]);


  useEffect(() => {
    getWatchHistoryList(dispatch);
    // getGenresList().then(function (gen){
      const contentVal = cityData.map((ge) => (
        {value:ge,label:ge}
    ))
    
    setOptions(contentVal)
      // })
  }, [dispatch]);

  const handleLocationChange = (value) => {
    let loc = value.value;
    getWatchHistoryListByLocation(loc,dispatch);
};

  const columns = [
    { field: "title", headerName: "title", width: 300 },
    // {
    //   field: "img",
    //   headerName: "Image",
    //   width: 120,
    //   filterable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div className="productListItem">
    //         <img className="productListImg" src={params.row.img} alt="" />
    //         {/* {params.row.title} */}
    //       </div>
    //     );
    //   },
    // },
    // { field: "title", headerName: "Title", width: 200 },
    // { field: "genre", headerName: "Genre", width: 120 },
    // { field: "year", headerName: "Year", width: 120 },
    { field: "type", headerName: "Type", width: 120 },
    { field: "duration", headerName: "Duration", width: 200 },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   filterable: false,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {/* <Link
    //           to={{ pathname: "/movie/" + params.row._id, movie: params.row ,id: params.row._id}}
    //         >
    //           <button className="productListEdit">Edit</button>
    //         </Link> */}

    //         <Link
    //           to={{ pathname: "/seasons/" + params.row._id , series: params.row }}
    //         >
    //           <button className="widgetSmButton"><Visibility className="widgetSmIcon" /></button>
    //         </Link>
           
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
    <div className="productList">
      <h1 className="addProductTitle">Watch History List</h1>
      <div className="addProductItem">
          <label>Select City</label>
          <Select name="city" id="city" 
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={true}
            onChange={handleLocationChange}
          />
        </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      
      <DataGrid
        rows={movies}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
    </>
  );
}
