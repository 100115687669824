import { MailOutline } from "@material-ui/icons";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import { osm } from "pigeon-maps/providers";
import "./user.css";

export default function User() {
  const [userMetadata, setUserMetadata] = useState({});
  const [center, setCenter] = useState([20.5937, 78.9629]);
  const [zoom, setZoom] = useState(4);
  const location = useLocation();
  const user = location.state;

  useEffect(() => {
    if (user.extra_metadata.length > 0) {
      setUserMetadata(JSON.parse(user.extra_metadata[0]));
    }
  }, []);

  // console.log(userMetadata)

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">User Details</h1>
        {/* <Link to="/newUser">
          <button className="userAddButton">Create</button>
        </Link> */}
      </div>
      <div className="userContainer">
        {/* <div className="userShow">
          <div className="userShowTop">
            <img
              src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="userShowImg"
            />
            <div className="userShowTopTitle">
              <span className="userShowUsername">{user.username}</span>
              <span className="userShowUserTitle">Software Engineer</span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">annabeck99</span>
            </div>
            <div className="userShowInfo">
              <CalendarToday className="userShowIcon" />
              <span className="userShowInfoTitle">10.12.1999</span>
            </div>
            <span className="userShowTitle">Contact Details</span>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitle">+1 123 456 67</span>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <a href={`mailto:${user.email}`}><span className="userShowInfoTitle">{user.email}</span></a>
            </div>
            <div className="userShowInfo">
              <LocationSearching className="userShowIcon" />
              <span className="userShowInfoTitle">New York | USA</span>
            </div>
          </div>
        </div> */}

        <div className="userShow">
          {/* <div className="userDetProfilePic">
            <img
              src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=300"
              alt=""
              className="userDetImage"
            />
          </div> */}
          <div className="userMainDetails">
            <div className="userShowUsername">
              {user.username}
              <div className="userActiveStatus">
                {user.is_active ? (
                  <div className="user_status user__online"></div>
                ) : (
                  <div className="user__offline user_status"></div>
                )}
              </div>
            </div>
          <div className="userPositionInfo">
            {user.isAdmin ? (
              <div className="userDet">(Administrator)</div>
            ) : (
              <div className="userDet">(User)</div>
            )}
          </div>
          </div>
          <div className="userAccountDet">
            <div className="userAccountInfo">
              <div className="userInfoLeft">
                <h3 className="pad_block_5">Account details</h3>
                <div className="userJoinInfo pad_block_5">
                  <span>Joined at</span> -{" "}
                  {new Date(user.createdAt).toLocaleString("en-us")}
                </div>
                <div className="userSubsStatus pad_block_5">
                  <span>Subscribed - </span>{" "}
                  {user.is_subscribed ? (
                    <div className="sub">Subscribed</div>
                  ) : (
                    <div className="notSub">Not Subscribed</div>
                  )}
                </div>
                <div className="userSubDate pad_block_5">
                  <span>Subscription Date - </span>
                  {user.is_subscribed ? (
                    new Date(user.subscribed_date).toLocaleString("en-us")
                  ) : (
                    <div>User doesn't have a subscription.</div>
                  )}
                </div>
                <div className="userSubEndDate pad_block_5">
                  <span>Subscription End Date - </span>
                  {user.is_subscribed ? (
                    new Date(user.subscription_end_date).toLocaleString("en-us")
                  ) : (
                    <div>User doesn't have a subscription.</div>
                  )}
                </div>
              </div>
              <div className="userInfoMiddle">
                <h3 className="pad_block_5">Device details</h3>
                <div className="userDeviceBrand pad_block_5">
                  <span>Device Brand</span> - {userMetadata.deviceBrand}
                </div>
                <div className="userDeviceBrand pad_block_5">
                  <span>Device Model</span> - {userMetadata.deviceModel}
                </div>
                <div className="userDeviceBrand pad_block_5">
                  <span>Device Name</span> - {userMetadata.deviceName}
                </div>
                <div className="userDeviceBrand pad_block_5">
                  <span>ISP</span> - {userMetadata.isp}
                </div>
                <div className="userDeviceBrand pad_block_5">
                  <span>Device Operating System</span> -{" "}
                  {userMetadata.operatingSystem}
                </div>
              </div>
              <div className="userInfoRight">
                <h3 className="pad_block_5">Contact details</h3>
                <div className="userEmail pad_block_5">
                  <MailOutline className="userShowIcon" />
                  <a href={`mailto:${user.email}`}>
                    <span className="userShowInfoTitle">{user.email}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="userAccountDet">
            <div className="userAccountInfo">
              <div className="userInfoLeft">
                <h3 className="pad_block_5">Location details</h3>
                <div className="userLatInfo pad_block_5">
                  <span>Latitude - </span>
                  {userMetadata.lat}
                </div>
                <div className="userLongInfo pad_block_5">
                  <span>Longitude -</span> {userMetadata.lon}
                </div>
                <div className="userRegion pad_block_5">
                  <span>Region - </span>
                  {userMetadata.regionName}({userMetadata.region})
                </div>
                <div className="userTimezone pad_block_5">
                  <span>Timezone - </span>
                  {userMetadata.timezone}
                </div>
                <div className="userLocation pad_block_5">
                  <span>Location - </span>
                  {userMetadata.city}, {userMetadata.country} (
                  {userMetadata.zip})
                </div>
              </div>
              <div className="userInfoRight">
                <h3 className="pad_block_5 map_heading">Location on map</h3>
                <div className="map">
                <Map
                  provider={osm}
                  width={400}
                  height={400}
                  center={center}
                  zoom={zoom}
                  onBoundsChanged={({ center, zoom }) => { 
                    setCenter([userMetadata.lat,userMetadata.lon]) 
                    setZoom(zoom) 
                  }} 
                >
                  <ZoomControl />
                  <Marker width={50} anchor={[userMetadata.lat,userMetadata.lon]} />
                </Map>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        {/* <div className="userUpdate">
          <span className="userUpdateTitle">Edit</span>
          <form className="userUpdateForm">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Username</label>
                <input
                  type="text"
                  placeholder="annabeck99"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Anna Becker"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="annabeck99@gmail.com"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Phone</label>
                <input
                  type="text"
                  placeholder="+1 123 456 67"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="New York | USA"
                  className="userUpdateInput"
                />
              </div>
            </div>
            <div className="userUpdateRight">
              <div className="userUpdateUpload">
                <img
                  className="userUpdateImg"
                  src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                />
                <label htmlFor="file">
                  <Publish className="userUpdateIcon" />
                </label>
                <input type="file" id="file" style={{ display: "none" }} />
              </div>
              <button className="userUpdateButton">Update</button>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
}
