import axios from "axios";
import {
  createKidSliderFailure,
  createKidSliderStart,
  createKidSliderSuccess,
  deleteKidSliderFailure,
  deleteKidSliderStart,
  deleteKidSliderSuccess,
  getKidSlidersFailure,
  getKidSlidersStart,
  getKidSlidersSuccess,
  getKidSliderFailure,
  getKidSliderStart,
  getKidSliderSuccess,
  updateKidSliderStart,
  updateKidSliderSuccess,
  updateKidSliderFailure,
} from "./KidSliderActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getKidSliders = async (dispatch) => {
  dispatch(getKidSlidersStart(null));
  try {
    const res = await axios1.get("/kidsliders", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidSlidersSuccess(res.data));
  } catch (err) {
    dispatch(getKidSlidersFailure());
  }
};

//create
export const createKidSlider = async (Kidslider, dispatch) => {
  dispatch(createKidSliderStart());
  try {
    const res = await axios1.post("/kidsliders", Kidslider, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createKidSliderSuccess(res.data));
  } catch (err) {
    dispatch(createKidSliderFailure());
  }
};

//delete
export const deleteKidSlider = async (id, dispatch) => {
  dispatch(deleteKidSliderStart());
  try {
    await axios1.delete("/kidsliders/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteKidSliderSuccess(id));
  } catch (err) {
    dispatch(deleteKidSliderFailure());
  }
};

export const getKidSlider = async (id, dispatch) => {
  dispatch(getKidSliderStart());
  try {
    const res = await axios1.get("/kidsliders/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidSliderSuccess(res.data));
  } catch (err) {
    dispatch(getKidSliderFailure());
  }
};

export const updateKidSlider = async (id, slider, dispatch) => {
  dispatch(updateKidSliderStart());
  try {
    const res = await axios1.put("/kidsliders/"+ id, slider, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateKidSliderSuccess(res.data));
  } catch (err) {
    dispatch(updateKidSliderFailure());
  }
};