const EpisodeReducer = (state, action) => {
  switch (action.type) {
    case "GET_EPISODES_START":
      return {
        episodes: [],
        isFetching: true,
        error: false,
      };
    case "GET_EPISODES_SUCCESS":
      return {
        episodes: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_EPISODES_FAILURE":
      return {
        episodes: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_EPISODE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_EPISODE_SUCCESS":
      return {
        episodes: [...state.episodes, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_EPISODE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_EPISODE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_EPISODE_SUCCESS":
      return {
        episodes: state.episodes.map(
          (episode) => episode._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_EPISODE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_EPISODE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_EPISODE_SUCCESS":
      return {
        episodes: state.episodes.filter((episode) => episode._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_EPISODE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_EPISODE_START":
        return {
          episode: {},
          isFetching: true,
          error: false,
        };
      case "GET_EPISODE_SUCCESS":
        return {
          episode: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_EPISODE_FAILURE":
        return {
          episode: {},
          isFetching: false,
          error: true,
        };
        case "GET_SEARIES_START":
          return {
            series: [],
            isFetching: true,
            error: false,
          };
        case "GET_SEARIES_SUCCESS":
          return {
            series: action.payload,
            isFetching: false,
            error: false,
          };
        case "GET_SEARIES_FAILURE":
          return {
            series: [],
            isFetching: false,
            error: true,
          };
          case "GET_SEASONS_START":
            return {
              seasons: [],
              isFetching: true,
              error: false,
            };
          case "GET_SEASONS_SUCCESS":
            return {
              seasons: action.payload,
              isFetching: false,
              error: false,
            };
          case "GET_SEASONS_FAILURE":
            return {
              seasons: [],
              isFetching: false,
              error: true,
            };
            case "GET_SEASON_EPISODES_START":
              return {
                seasonEpisodes: [],
                isFetching: true,
                error: false,
              };
            case "GET_SEASON_EPISODES_SUCCESS":
              return {
                seasonEpisodes: action.payload,
                isFetching: false,
                error: false,
              };
            case "GET_SEASON_EPISODES_FAILURE":
              return {
                seasonEpisodes: [],
                isFetching: false,
                error: true,
              };
            case "DELETE_SEASON_EPISODES_SUCCESS":
              return {
                seasonEpisodes: state.seasonEpisodes.filter((episode) => episode._id !== action.payload),
                isFetching: false,
                error: false,
              };
              case "DELETE_SEASON_EPISODE_START":
                return {
                  ...state,
                  isFetching: true,
                  error: false,
                };
              case "DELETE_SEASON_EPISODE_FAILURE":
                return {
                  ...state,
                  isFetching: false,
                  error: true,
                };
            
    default:
      return { ...state };
  }
};

export default EpisodeReducer;
