const PackagingReducer = (state, action) => {
  switch (action.type) {
    case "GET_PACKAGINGS_START":
      return {
        packagings: [],
        isFetching: true,
        error: false,
      };
    case "GET_PACKAGINGS_SUCCESS":
      return {
        packagings: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_PACKAGINGS_FAILURE":
      return {
        packagings: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_PACKAGING_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_PACKAGING_SUCCESS":
      return {
        packagings: [...state.packagings, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_PACKAGING_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_PACKAGING_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_PACKAGING_SUCCESS":
      return {
        packagings: state.packagings.map(
          (packaging) => packaging._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_PACKAGING_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_PACKAGING_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_PACKAGING_SUCCESS":
      return {
        packagings: state.packagings.filter((packaging) => packaging._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_PACKAGING_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_PACKAGING_START":
        return {
          packaging: {},
          isFetching: true,
          error: false,
        };
      case "GET_PACKAGING_SUCCESS":
        return {
          packaging: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_PACKAGING_FAILURE":
        return {
          packaging: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default PackagingReducer;
