import axios from "axios";
import {
  createEpisodeFailure,
  createEpisodeStart,
  createEpisodeSuccess,
  deleteEpisodeFailure,
  deleteEpisodeStart,
  deleteEpisodeSuccess,
  getEpisodesFailure,
  getEpisodesStart,
  getEpisodesSuccess,
  getEpisodeFailure,
  getEpisodeStart,
  getEpisodeSuccess,
  getSeasonsStart,
  getSeasonsSuccess,
  getSeasonsFailure,
  getSeariesStart,
  getSeariesSuccess,
  getSeariesFailure,
  updateEpisodeFailure,
  updateEpisodeStart,
  updateEpisodeSuccess,
  getSeasonEpisodesStart,
  getSeasonEpisodesSuccess,
  getSeasonEpisodesFailure,
  deleteSeasonEpisodeSuccess,
  deleteSeasonEpisodeStart,
  deleteSeasonEpisodeFailure,
} from "./EpisodeActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getEpisodes = async (dispatch) => {
  dispatch(getEpisodesStart());
  try {
    const res = await axios1.get("/episodes", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getEpisodesSuccess(res.data));
  } catch (err) {
    dispatch(getEpisodesFailure());
  }
};

//create
export const createEpisode = async (episode, dispatch) => {
  dispatch(createEpisodeStart());
  try {
    const res = await axios1.post("/episodes", episode, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createEpisodeSuccess(res.data));
  } catch (err) {
    dispatch(createEpisodeFailure());
  }
};

//delete
export const deleteEpisode = async (id, dispatch) => {
  dispatch(deleteEpisodeStart());
  try {
    await axios1.delete("/episodes/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteEpisodeSuccess(id));
  } catch (err) {
    dispatch(deleteEpisodeFailure());
  }
};

export const getEpisode = async (id, dispatch) => {
  dispatch(getEpisodeStart());
  try {
    const res = await axios1.get("/episodes/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getEpisodeSuccess(res.data));
  } catch (err) {
    dispatch(getEpisodeFailure());
  }
};

export const getSeries = async (dispatch) => {
  dispatch(getSeariesStart());
  try {
    const res = await axios1.get("/series", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getSeariesSuccess(res.data));
  } catch (err) {
    dispatch(getSeariesFailure());
  }
};

export const getSeasons = async (id, dispatch) => {
  dispatch(getSeasonsStart());
  try {
    const res = await axios1.get("/episodes/season/list/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getSeasonsSuccess(res.data));
  } catch (err) {
    dispatch(getSeasonsFailure());
  }
};

export const getEpisodeForUpdate = async (id) => {
  try {
    const res = await axios1.get("/episodes/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

//update

export const updateEpisode = async (id, episode, dispatch) => {
  dispatch(updateEpisodeStart());
  try {
    const res = await axios1.put("/episodes/"+ id, episode, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateEpisodeSuccess(res.data));
  } catch (err) {
    dispatch(updateEpisodeFailure());
  }
};

export const getSeasonList = async (id) => {
  // dispatch(getSeasonsStart());
  try {
    const res = await axios1.get("/episodes/season/list/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
    // dispatch(getSeasonsSuccess(res.data));
  } catch (err) {
    return err
    // dispatch(getSeasonsFailure());
  }
};

export const getSeasonsEpisode = async (seriesId,seasonId,dispatch) => {
  dispatch(getSeasonEpisodesStart());
  try {
    const res = await axios1.get("/seasons/episode/list/" + seriesId+"/"+seasonId, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    // console.log(res.data);
    dispatch(getSeasonEpisodesSuccess(res.data));
  } catch (err) {
    dispatch(getSeasonEpisodesFailure());
  }
};

export const deleteSeasonEpisode = async (id, dispatch) => {
  dispatch(deleteSeasonEpisodeStart());
  try {
    await axios1.delete("/episodes/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteSeasonEpisodeSuccess(id));
  } catch (err) {
    dispatch(deleteSeasonEpisodeFailure());
  }
};