import "./sliderList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SliderContext } from "../../context/sliderContext/SliderContext";
import { deleteSlider, getSliders } from "../../context/sliderContext/apiCalls";
import Loader from "../../components/loader/Loader";

export default function SliderList() {
  const { isFetching, sliders, dispatch } = useContext(SliderContext);


  useEffect(() => {
    getSliders(dispatch);
  }, [dispatch]);


  const handleDelete = (id) => {
    deleteSlider(id, dispatch);
  };


  const columns = [
    {
      field: "id",
      headerName: "SNo",
      width: 85,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      disableColumnMenu: true,
      align: 'center',
    },
    //   {
    //     field: 'id' , 
    //     headerName: 'number', 
    //     filterable: false,

    // },
    {
      field: "slider",
      headerName: "Image",
      width: 170,
      align: 'center',
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
          </div>
        );
      },
    },
    { field: "title", headerName: "Title", minWidth: 270 },
    { field: "genre", headerName: "Genre", width: 300 },
    {
      field: "slider_start_date", headerName: "Start Date", width: 200,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('en-us')
      }

    },
    {
      field: "slider_end_date", headerName: "End Date", width: 200,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('en-us')
      }
    },
    // { field: "year", headerName: "Year", align: 'center', width: 85 },
    { field: "type", headerName: "Type", align: 'center', width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/slider/" + params.row._id, slider: params.row }}
            >
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];


  // console.log(sliders)

  return (
    <div className="productList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Slider List</h1>
        <div className="productListHeadingR">
          <h2 className="productNumber">No of records : {sliders.length}</h2>
          <Link to="/newSlider">
            <button className="productAddButton">Create</button>
          </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
      sx={{ overflowX: 'scroll' }}
        rowHeight={150}
        rows={sliders}
        disableSelectionOnClick
        columns={columns}
        pageSize={7}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
      {/* } */}
    </div>
  );
}
