import "./seriesList.css";
import { DataGrid } from "@material-ui/data-grid";
// import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { EpisodeContext } from "../../context/episodeContext/EpisodeContext";
import { getSeries } from "../../context/episodeContext/apiCalls";
import Loader from "../../components/loader/Loader";


export default function EpisodeList() {
  const {isFetching, series, dispatch } = useContext(EpisodeContext);

  useEffect(() => {
    getSeries(dispatch);
  }, [dispatch]);

  // const handleDelete = (id) => {
  //   deleteEpisode(id, dispatch);
  // };

  const columns = [
    { field: "_id", headerName: "ID", width: 90 },
    {
      field: "series",
      headerName: "Series",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
            {params.row.title}
          </div>
        );
      },
    },
    { field: "title", headerName: "Title", width: 120 },
    { field: "genre", headerName: "Genre", width: 120 },
    { field: "year", headerName: "Year", width: 120 },
    { field: "limit", headerName: "Limit", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/series/" + params.row._id, series: params.row }}
            >
              <button className="productListEdit">View</button>
            </Link>
            {/* <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            /> */}
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <h1 className="addProductTitle">Series List</h1>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={series}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        // checkboxSelection
        loading={isFetching}
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
