import "./sidebar.css";
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  PlayCircleOutline,
  List,
  // MailOutline,
  // DynamicFeed,
  // ChatBubbleOutline,
  // WorkOutline,
  // Report,
  // AddToQueue,
  // QueuePlayNext,
  LinearScaleOutlined,
  // PlaylistAddCheckRounded,
  // PlaylistAddCheckOutlined,
  // PlaylistAddOutlined,
  // AddCircleOutlineOutlined
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import NewLiveChannel from "../../pages/newLiveChannel/NewLiveChannel";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <NavLink exact to="/" className="link">
              <li className="sidebarListItem">
                <LineStyle className="sidebarIcon" />
                Home
              </li>
            </NavLink>
            <NavLink to="/analytics" className="link">
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Analytics
            </li>
            </NavLink>
            <li className="sidebarListItem">
              <TrendingUp className="sidebarIcon" />
              Sales
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <NavLink to="/users" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Users
              </li>
            </NavLink>
            <NavLink to="/movies" className="link">
              <li className="sidebarListItem">
                <PlayCircleOutline className="sidebarIcon" />
                Contents
              </li>
            </NavLink>
            
            <NavLink to="/lists" className="link" >
              <li className="sidebarListItem">
                <List className="sidebarIcon" />
                Home Categories
              </li>
            </NavLink>
            <NavLink to="/sliders" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Sliders
              </li>
            </NavLink>
            <NavLink to="/kidsliders" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Kids Sliders
              </li>
            </NavLink>
            <NavLink to="/episodes" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Episodes
              </li>
            </NavLink>
            <NavLink to="/genres" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Genres
              </li>
            </NavLink>
            <NavLink to="/packagings" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Packaging
              </li>
            </NavLink>
            <NavLink to="/subscribe-videos" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Subscribe Now Videos
              </li>
            </NavLink>
            <NavLink to="/internal-contests" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Internal Contest
              </li>
            </NavLink>
            <NavLink to="/configs" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Configs
              </li>
            </NavLink>
            <NavLink to="/kidss" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Kids
              </li>
            </NavLink>
            <NavLink to="/kidsEpisodes" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Kids Episodes
              </li>
            </NavLink>
             <NavLink to="/live-channels" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                 Live Channels
              </li>
            </NavLink> 
            <NavLink to="/live-channel-type-list" className="link">
              <li className="sidebarListItem">
                <LinearScaleOutlined className="sidebarIcon" />
                Live Channel Type
              </li>
            </NavLink>   
            {/* <NavLink to="/newList" className="link">
              <li className="sidebarListItem">
                <PlaylistAddOutlined className="sidebarIcon" />
                Add Home Category
              </li>
            </NavLink> */}
            {/* <NavLink to="/newSlider" className="link">
              <li className="sidebarListItem">
                <AddCircleOutlineOutlined className="sidebarIcon" />
                Add Slider
              </li>
            </NavLink> */}
            {/* <NavLink to="/newEpisode" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Episode
              </li>
            </NavLink> */}
            {/* <NavLink to="/newGenre" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Genre
              </li>
            </NavLink> */}
            {/* <NavLink to="/newPackaging" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Packaging
              </li>
            </NavLink> */}
            {/* <NavLink to="/new-subscribe-video" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Subscribe Now Videos
              </li>
            </NavLink> */}
            {/* <NavLink to="/newKids" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Kids Content
              </li>
            </NavLink> */}
            {/* <NavLink to="/newKidSlider" className="link">
              <li className="sidebarListItem">
                <AddCircleOutlineOutlined className="sidebarIcon" />
                Add Kids Slider
              </li>
            </NavLink> */}
            {/* <NavLink to="/newKidsEpisode" className="link">
              <li className="sidebarListItem">
                <QueuePlayNext className="sidebarIcon" />
                Add Kids Episode
              </li>
            </NavLink> */}
          </ul>
        </div>
        {/* <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notifications</h3>
          <ul className="sidebarList">
            <NavLink to="/mails" className="link">
            <li className="sidebarListItem">
              <MailOutline className="sidebarIcon" />
              Mail
            </li>
            </NavLink>
            
            <li className="sidebarListItem">
              <DynamicFeed className="sidebarIcon" />
              Feedback
            </li>
            <li className="sidebarListItem">
              <ChatBubbleOutline className="sidebarIcon" />
              Messages
            </li>
          </ul>
        </div> */}
        {/* <div className="sidebarMenu">
          <h3 className="sidebarTitle">Staff</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <WorkOutline className="sidebarIcon" />
              Manage
            </li>
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Analytics
            </li>
            <li className="sidebarListItem">
              <Report className="sidebarIcon" />
              Reports
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
}
