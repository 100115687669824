import KidsEpisodeReducer from "./KidsEpisodeReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  kidsepisodes: [],
  kidsseries: [],
  kidsseasons: [],
  isFetching: false,
  error: false,
};

export const KidsEpisodeContext = createContext(INITIAL_STATE);

export const KidsEpisodeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(KidsEpisodeReducer, INITIAL_STATE);

  return (
    <KidsEpisodeContext.Provider
      value={{
        kidsepisodes: state.kidsepisodes,
        kidsseries: state.kidsseries,
        kidsseasons: state.kidsseasons,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </KidsEpisodeContext.Provider>
  );
};
