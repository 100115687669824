// import Chart from "../../components/chart/Chart";
import WatchChart from "../../components/barChart/BarChart";
import WatchPieChart from "../../components/pieChart/PieChart";
// import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./analytics.css";
// import { userData } from "../../dummyData";
// import WidgetSm from "../../components/widgetSm/WidgetSm";
// import WidgetLg from "../../components/widgetLg/WidgetLg";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";

export default function Analytics() {
  const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const [dataKeyname] = useState("Total Watch Duration (Hrs)");

  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const [userStats, setUserStats] = useState([]);
  // const [totalUsers, setTotalUsers] = useState(0);
  // const [totalSubscribedUsers, setTotalSubscribedUsers] = useState(0);

  useEffect(() => {
    const getStats = async () => {
      try {
        const res = await axios1.get("/v1/get/watchHistory/data?limit=8", {
          headers: {
            token:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwZTZmYzQ2NDk0Mjc3MTYwNDg4MmMxNiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTYyNTgzMjMxMSwiZXhwIjoxNjI2MjY0MzExfQ.ATXV-1TTWIGyVBttTQSf0erRWjsgZ8jHQv1ZsUixbng",
          },
        });
        // console.log(res.data)
        const statsList = res.data.sort(function (a, b) {
          return a._id - b._id;
        });
        statsList.map((item) =>
          setUserStats((prev) => [
            ...prev,
            { name: item.title, "Total Watch Duration (Hrs)": item.duration },
          ])
        );
      } catch (err) {
        // console.log(err);
      }
    };
    // const getUsers = async () => {
    //   try {
    //     const res = await axios1.get("/users", {
    //       headers: {
    //         token:
    //           "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwZTZmYzQ2NDk0Mjc3MTYwNDg4MmMxNiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTYyNTgzMjMxMSwiZXhwIjoxNjI2MjY0MzExfQ.ATXV-1TTWIGyVBttTQSf0erRWjsgZ8jHQv1ZsUixbng",
    //       },
    //     });
    //     // let sub_count = res.data.filter((a)=>a.is_subscribed === true)
    //     // console.log(res.data.filter((a)=>a.is_subscribed === true).length);
    //     // setTotalUsers(res.data.length);
    //     // setTotalSubscribedUsers(res.data.filter((a)=>a.is_subscribed === true).length);
    //     // var date = new Date(res.data.createdAt);
    //     // // console.log(date)
    //     // // console.log(res.data)
    //     // setJoiningDate(date.toISOString().substring(0, 10));

    //   } catch (err) {
    //     // console.log(err);
    //   }
    // };
    // getUsers();
    getStats();
  }, [MONTHS]);

  return (
    <div className="home">

      <WatchChart data={userStats} title="Watch History Analytics (from: 08 Dec 2022)" grid dataKey="Total Watch Duration (Hrs)" />
      {/* <WatchPieChart data={userStats} title="Watch History Locations" grid dataKey="Total Watch Duration (Hrs)" /> */}
      <div className="homeWidgets">

        {/* <WidgetLg /> */}
      </div>
    </div>
  );
}
