import axios from "axios";
import {
  createLiveChannelFailure,
  createLiveChannelStart,
  createLiveChannelSuccess,
  deleteLiveChannelFailure,
  deleteLiveChannelStart,
  deleteLiveChannelSuccess,
  getLiveChannelsFailure,
  getLiveChannelsStart,
  getLiveChannelsSuccess,
  getLiveChannelFailure,
  getLiveChannelStart,
  getLiveChannelSuccess,
  updateLiveChannelFailure,
  updateLiveChannelStart,
  updateLiveChannelSuccess,
} from "./LiveChannelActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getLiveChannels = async (dispatch) => {
  dispatch(getLiveChannelsStart());
  try {
    const res = await axios1.get("/liveChannels", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getLiveChannelsSuccess(res.data));
  } catch (err) {
    dispatch(getLiveChannelsFailure());
  }
};

//create
export const createLiveChannel = async (packaging, dispatch) => {
  dispatch(createLiveChannelStart());
  try {
    const res = await axios1.post("/liveChannels", packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createLiveChannelSuccess(res.data));
  } catch (err) {
    dispatch(createLiveChannelFailure());
  }
};

//delete
export const deleteLiveChannel = async (id, dispatch) => {
  dispatch(deleteLiveChannelStart());
  try {
    await axios1.delete("/liveChannels/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteLiveChannelSuccess(id));
  } catch (err) {
    dispatch(deleteLiveChannelFailure());
  }
};

export const getLiveChannel = async (id, dispatch) => {
  dispatch(getLiveChannelStart());
  try {
    const res = await axios1.get("/liveChannels/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getLiveChannelSuccess(res.data));
  } catch (err) {
    dispatch(getLiveChannelFailure());
  }
};

export const getLiveChannelsList = async () => {
  try {
    const res = await axios1.get("/liveChannels", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    // getLiveChannelsSuccess(res.data);
    return res.data
  } catch (err) {
    return err
  }
};

export const getPackageForUpdate = async (id) => {
  try {
    const res = await axios1.get("/liveChannels/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const updateLiveChannel = async (id, packaging, dispatch) => {
  dispatch(updateLiveChannelStart());
  try {
    const res = await axios1.put("/liveChannels/"+ id, packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateLiveChannelSuccess(res.data));
  } catch (err) {
    dispatch(updateLiveChannelFailure());
  }
};

export const getLiveChannelsForMovies = async () => {
  try {
    const res = await axios1.get("/liveChannels", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};

export const getLiveChannelForUpdate = async (id) => {
  try {
    const res = await axios1.get("/liveChannels/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

// export const getLiveChannelEpisodeDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/episodes/liveChannel/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getLiveChannelKidsEpisodeDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/kidsepisodes/liveChannel/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getLiveChannelKidsDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/kids/liveChannel/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };