import { useContext, useState,useEffect } from "react";
import "./newemail.css";
import axios from 'axios';


export default function NewEmail() {
    const initialValues = { recipients:[], subject: "", content: "", Cc: "", Bcc:"" }
    const [mailValues, setMailValues] = useState(initialValues);
    const [mailErrors, setMailErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMailValues({ ...mailValues, [name]: value })
        // console.log(mailValues)
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setMailErrors(validate(mailValues));
        const recipientsList = mailValues.recipients.split(',');
        setMailValues({...mailValues,recipients:recipientsList})
        setIsSubmit(true)
    }

    const handleReset = () => {
        setMailValues(initialValues)
    }

    const validate = (values) => {
        const errors = {};
        if (!values.subject) {
            errors.subject = "Subject is required";
        }
        // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        //     errors.email = "Email is required";
        // }
        if (!values.content) {
            errors.content = "Content should not be empty"
        }
        return errors;
    }

    useEffect(() => {
        if (Object.keys(mailErrors).length === 0 && isSubmit) {
            axios.post('link', {
                "recipients": mailValues.recipients,
                "subject": mailValues.subject,
                "content": mailValues.content,
            })
                .then((res) => {
                    // console.log(res)
                })
                .catch(err => {
                    // console.log("Error", err)
                })
        }
    })


  return (
    <div className="newProduct">
      <h1 className="addProductTitle">Compose Mail</h1>
      <form className="addProductForm" id="email__form">
        <div className="addEmail">
          <label className="msg_heading">New Message</label>
          <input
            type="text" 
            placeholder="Recipients"
            name="recipients"
            required="required"
            value={mailValues.recipients} 
            onChange={handleChange}
          />
          <input
            type="text" 
            placeholder="Subject"
            name="subject"
            required="required"
            value={mailValues.subject} 
            onChange={handleChange}
          />
          <textarea
            className="email__content"
            type="text" 
            placeholder="Content"
            name="content"
            required="required"
            value={mailValues.content} 
            onChange={handleChange}
          />
          <div className="button__holder">
          <button className="resetButton" onClick={handleReset}>
            Reset
          </button>
          <button className="sendButton" onClick={handleSubmit}>
            Send
          </button>
          </div>
        </div>

      </form>
    </div>
  );
}
