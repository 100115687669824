export const getMoviesStart = () => ({
  type: "GET_MOVIES_START",
});

export const getMoviesSuccess = (movies,count) => ({
  type: "GET_MOVIES_SUCCESS",
  payload: movies,
  total:count
});

export const getMoviesFailure = () => ({
  type: "GET_MOVIES_FAILURE",
});

export const createMovieStart = () => ({
  type: "CREATE_MOVIE_START",
});

export const createMovieSuccess = (movie) => ({
  type: "CREATE_MOVIE_SUCCESS",
  payload: movie,
});

export const createMovieFailure = () => ({
  type: "CREATE_MOVIE_FAILURE",
});

export const updateMovieStart = () => ({
  type: "UPDATE_MOVIE_START",
});

export const updateMovieSuccess = (movie) => ({
  type: "UPDATE_MOVIE_SUCCESS",
  payload: movie,
});

export const updateMovieFailure = () => ({
  type: "UPDATE_MOVIE_FAILURE",
});

export const deleteMovieStart = () => ({
  type: "DELETE_MOVIE_START",
});

export const deleteMovieSuccess = (id) => ({
  type: "DELETE_MOVIE_SUCCESS",
  payload: id,
});

export const deleteMovieFailure = () => ({
  type: "DELETE_MOVIE_FAILURE",
});

export const getMovieStart = () => ({
  type: "GET_MOVIE_START",
});

export const getMovieSuccess = (movies) => ({
  type: "GET_MOVIE_SUCCESS",
  payload: movies,
});

export const getMovieFailure = () => ({
  type: "GET_MOVIE_FAILURE",
});

export const getWatchHistoryListStart = () => ({
  type: "GET_WATCHHISTORY_START",
});

export const getWatchHistoryListSuccess = (movies) => ({
  type: "GET_WATCHHISTORY_SUCCESS",
  payload: movies,
});

export const getWatchHistoryListFailure = () => ({
  type: "GET_WATCHHISTORY_FAILURE",
});
