import LiveChannelReducer from "./LiveChannelReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  liveChannels: [],
  isFetching: false,
  error: false,
};

export const LiveChannelContext = createContext(INITIAL_STATE);

export const LiveChannelContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LiveChannelReducer, INITIAL_STATE);

  return (
    <LiveChannelContext.Provider
      value={{
        liveChannels: state.liveChannels,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </LiveChannelContext.Provider>
  );
};
