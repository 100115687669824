const GenreReducer = (state, action) => {
  switch (action.type) {
    case "GET_GENRES_START":
      return {
        genres: [],
        isFetching: true,
        error: false,
      };
    case "GET_GENRES_SUCCESS":
      return {
        genres: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_GENRES_FAILURE":
      return {
        genres: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_GENRE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_GENRE_SUCCESS":
      return {
        genres: [...state.genres, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_GENRE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_GENRE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_GENRE_SUCCESS":
      return {
        genres: state.genres.map(
          (genre) => genre._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_GENRE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_GENRE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_GENRE_SUCCESS":
      return {
        genres: state.genres.filter((genre) => genre._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_GENRE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_GENRE_START":
        return {
          genre: {},
          isFetching: true,
          error: false,
        };
      case "GET_GENRE_SUCCESS":
        return {
          genre: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_GENRE_FAILURE":
        return {
          genre: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default GenreReducer;
