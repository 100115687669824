import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import "./kids.css";
import { Publish } from "@material-ui/icons";
import ReactS3Client from "../../s3";
import { KidsContext } from "../../context/kidsContext/KidsContext";
import { getKidsForUpdate, updateKids } from "../../context/kidsContext/apiCalls";
import Select from 'react-select'
import { getPackagings, getPackagingKidsDataForUpdate } from "../../context/packagingContext/apiCalls";
import { getGenres, getKidsGenreDataForUpdate } from "../../context/genreContext/apiCalls";
import { PackagingContext } from "../../context/packagingContext/PackagingContext";
import { GenreContext } from "../../context/genreContext/GenreContext";



export default function Kids() {
  // const location = useLocation();
  // const kids = location.kids;
  const {kidsId} = useParams();
  const {dispatch } = useContext(KidsContext);
  const [kids, setKids] = useState([]);
  const [uploaded, setUploaded] = useState(0);
  const [packagingOptions, setPackagingOptions] = useState([]);
  const [genOption, setGenOptions] = useState([]);
  const { packagings, dispatch: dispatchPackaging } = useContext(PackagingContext);
  const { genres, dispatch: dispatchGenre } = useContext(GenreContext);
  const [img, setImg] = useState(null);
  const [imgTitle, setImgTitle] = useState(null);
  const [imgSm, setImgSm] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgTitleSrc, setImgTitleSrc] = useState(null);
  const [imgSmSrc, setImgSmSrc] = useState(null);
  const history = useHistory();
  const [imgDimension, setImgDimensions] = useState({
    "image": { width: '', height: '',size:0,oldWidth:0,oldHeight:0 },
    "title_image": { width: '', height: '',size:0,oldWidth:0,oldHeight:0},
    "thumbnail_image": { width: '', height: '',size:0,oldWidth:0,oldHeight:0 },
  })
  const [oldImgDimension,setOldImgDimension]= useState({
    "image":{ dim:''},
    "title_image":{ dim:''},
    "thumbnail_image":{ dim:''}
  })  

  


  useEffect(() => {
    getGenres(dispatchGenre);
    getPackagings(dispatchPackaging);
  
    getKidsForUpdate(kidsId).then(function (res){
     
      setKids(res)
      setImgSrc(res.img)
      setImgSmSrc(res.imgSm)
      setImgTitleSrc(res.imgTitle)
      // console.log(movie)
    })

    getPackagingKidsDataForUpdate(kidsId).then(function (pac){
      // console.log(pac)
      setPackagingOptions({value:pac[0]?.content_data?.url,label:pac[0]?.content_data?.title})
    })

    getKidsGenreDataForUpdate(kidsId).then(function (res){
      // console.log(res)
      const genVal = res.map((ge) => (
        {value:ge.content_data._id,label:ge.content_data.name}
      ))
      setGenOptions(genVal)
    })

  }, [dispatch, dispatchGenre, dispatchPackaging, kidsId]);

  const allGenreData =  genres.map((gen) => (
    {value:gen._id,label:gen.name}
))
 
  const allPackData =  packagings.map((pac) => (
    {value:pac.url,label:pac.title}
))


  const handleChange = (e) => {
    const value = e.target.value;
    setKids({ ...kids, [e.target.name]: value });
  };

  // const selectedGenValues = () => {
  //   const test = options.filter(item => !genre.includes(item));
  //   console.log(test);
  // }

  const upload = (items) => {
    items.forEach((item) => {
      // const fileName = new Date().getTime() + item.label + item.file.name;

      ReactS3Client
        .uploadFile(item.file)
        .then((data) => {
          setKids((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
                    setUploaded((prev) => prev - 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      { file: imgTitle, label: "imgTitle" },
      { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ]);
  };

  // const handleInputChange = (value) => {
  //   const val = []
  //   for (let i = 0; i < value.length; i++) {
  //      val.push(value[i].value);
  //   }
  //   setKids({ ...kids, 'genre': val });
  // };


  const handleSubmit = (e) => {
    e.preventDefault();
    updateKids(kids._id,kids, dispatch);
    history.push("/kidss")

  };

  const handlePackInputChange = (value) => {
    // console.log(value)
    setKids({ ...kids, 'packaging': value.value });
    setPackagingOptions(value)

};

const handleVipInputChange = (value) => {
  // console.log(value)
  setKids({ ...kids, 'vip': value.value });
  // setVip(value)
};

const handleInputGenChange = (value) => {
  const val = []
  for (let i = 0; i < value.length; i++) {
         val.push(value[i].label);
      }
      Promise.resolve(setKids({ ...kids, 'genre': val }));

  setGenOptions(value)


};
const vipValue = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const handleChangeImg = (e) => {
  setImgSrc(URL.createObjectURL(e.target.files[0]))
  getImgDimensions("img",e.target.files[0])
  setImg(e.target.files[0])
  setUploaded((prev) => prev + 1);
  
};

const handleChangeImgSm = (e) => {
  setImgSmSrc(URL.createObjectURL(e.target.files[0]))
  getImgDimensions("thumb_img",e.target.files[0])
  setImgSm(e.target.files[0])
  setUploaded((prev) => prev + 1);
  
};

const handleChangeImgTitle = (e) => {
  setImgTitleSrc(URL.createObjectURL(e.target.files[0]))
  getImgDimensions("title_img",e.target.files[0])
  setImgTitle(e.target.files[0])
  setUploaded((prev) => prev + 1);

};

const oldImgDimensions = () =>{
  const oldImg = document.getElementById('image')
  const oldThumbImg = document.getElementById('thum_image')
  const oldTitleImg = document.getElementById('title_img')

  const oldImgDim = `${oldImg.naturalWidth}` + ' x ' + `${oldImg.naturalHeight}`;
  const oldThumImgDim = `${oldThumbImg.naturalWidth}` + ' x ' + `${oldThumbImg.naturalHeight}`;
  const oldTitleImgDim = `${oldTitleImg.naturalWidth}` + ' x ' + `${oldTitleImg.naturalHeight}`;

  // console.log(oldImgDim,oldThumImgDim,oldTitleImgDim)

  if(oldImgDimension.image.dim == ''){
    setOldImgDimension(prev => ({...prev, image:{ dim:oldImgDim}, title_image:{dim: oldTitleImgDim}, thumbnail_image:{dim: oldThumImgDim}}))
  }
}

const getImgDimensions = (str,target) => {
  let image = new Image()
  image.src = window.URL.createObjectURL(target)
  image.onload = () => {

    if(str === "img"){
      // const oldImg = document.getElementById('image')
      setImgDimensions(prev => ({...prev, image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "title_img"){
      setImgDimensions(prev => ({...prev, title_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "thumb_img"){
      setImgDimensions(prev => ({...prev, thumbnail_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
  }
}

  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Kids</h1>
        <Link to="/newKids">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={kids.img} alt="" className="productInfoImg" />
            <span className="productName">{kids.title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{kids._id}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">genre:</span>
              <span className="productInfoValue">{kids.genre?.map((v)=>v+', ')}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">year:</span>
              <span className="productInfoValue">{kids.year}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">limit:</span>
              <span className="productInfoValue">{kids.limit}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Kids Title</label>
            <input type="text" value={kids.title} placeholder={kids.title} onChange={handleChange}/>
            <label>Description</label>
            {/* <input type="textbox" name="desc" className=""  placeholder={kids.desc} onChange={handleChange}/> */}
            <textarea id="textarea" name="desc" rows="6" cols="55" placeholder={kids.desc} onChange={handleChange}></textarea>

            <label>Year</label>
            <input type="text" name="year" placeholder={kids.year} onChange={handleChange}/>
            <label>Skip Intro(in seconds)</label>
            <input type="number" name="skip_intro" placeholder={kids.skip_intro} onChange={handleChange}/>
            <label>Skip Intro End(in seconds)</label>
            <input type="number" name="skip_intro_end" placeholder={kids.skip_intro_end} onChange={handleChange}/>
            <label>Video Duration(in seconds)</label>
            <input type="number" name="video_duration" placeholder={kids.video_duration} onChange={handleChange}/>
            <label>Limit</label>
            <input type="text" name="limit"  placeholder={kids.limit} onChange={handleChange}/>
            <div className="addProductItem">
              <label>Vip</label>
              <Select name="vip" id="vip" 
                options={vipValue}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={handleVipInputChange}
                value={kids.vip?vipValue[0]:vipValue[1]}
              />
            </div>
            <div className="addProductItem">
              <label>Genre</label>
              <Select name="genre" id="genre" 
                options={allGenreData}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                onChange={handleInputGenChange}
                value={genOption}
                />
            </div>
              
            <div className="addProductItem">
              <label>Packaging</label>
              <Select name="packaging" id="packaging" 
                options={allPackData}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={handlePackInputChange}
                value={packagingOptions}
              />
            </div>
              <label>Trailer</label>
              <input type="text" name="trailer" placeholder={kids.trailer} className="urlField" onChange={handleChange}/>
              <label>Video</label>
              <input type="text" name="video"  placeholder={kids.video} className="urlField" onChange={handleChange}/>
            </div>
          <div className="productFormRight">
          <div className="productUpload">
          <div className="gen_btn" onClick={oldImgDimensions}>Show Image Details</div>
          <h3>Image</h3>
          {oldImgDimension.image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.image.dim}`}</p> : null}
                <img
                id="image"
                  src={imgSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="file" accept="image/webp" name="img"  onChange={handleChangeImg}/>
                {imgDimension.image.width && imgDimension.image.height != undefined ? <p>Image dimensions : {`${imgDimension.image.width} x ${imgDimension.image.height}`}</p> : null}
          {imgDimension.image.size !== 0 ? <p>Image size : {`${imgDimension.image.size}`}KB</p> : null}
              </div>

              <div className="productUpload">
              <h3>Thumbnail Image</h3>
              {oldImgDimension.thumbnail_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.thumbnail_image.dim}`}</p> : null}
                <img
                  id="thum_image"
                  src={imgSmSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="imgSm" accept="image/webp" name="imgSm"  onChange={handleChangeImgSm}/>
                {imgDimension.thumbnail_image.width && imgDimension.thumbnail_image.height != undefined ? <p>Image dimensions : {`${imgDimension.thumbnail_image.width} x ${imgDimension.thumbnail_image.height}`}</p> : null}
          {imgDimension.thumbnail_image.size !== 0 ? <p>Image size : {`${imgDimension.thumbnail_image.size}`}KB</p> : null}
              </div>

              <div className="productUpload">
              <h3>Title Image</h3>
              {oldImgDimension.title_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.title_image.dim}`}</p> : null}
                <img
                  id="title_img"
                  src={imgTitleSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="imgTitle" accept="image/webp" name="imgTitle"  onChange={handleChangeImgTitle}/>
                {imgDimension.title_image.width && imgDimension.title_image.height != undefined ? <p>Image dimensions : {`${imgDimension.title_image.width} x ${imgDimension.title_image.height}`}</p> : null}
          {imgDimension.title_image.size !== 0 ? <p>Image size : {`${imgDimension.title_image.size}`}KB</p> : null}
              </div>

            {uploaded === 0 ? (
          <button className="addProductButton" onClick={handleSubmit}>
            Update
          </button>
        ) : (
          <button className="addProductButton" onClick={handleUpload}>
            Upload
          </button>
        )}

            {/* <button className="productButton">Update</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
