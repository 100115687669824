import "./movieList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MovieContext } from "../../context/movieContext/MovieContext";
import { deleteMovie, getMovies } from "../../context/movieContext/apiCalls";
// import Loader from "../../components/loader/Loader";


export default function MovieList() {
  const { isFetching, movies, dispatch } = useContext(MovieContext);

  var previousPgaeNo = localStorage.getItem("moviePageNo")
  const [pageState, setPageState] = useState(0);
  
  useEffect(() => {
    if(localStorage.getItem("checkPreviousPage")==="yes"){
      setPageState(previousPgaeNo)
      localStorage.setItem("checkPreviousPage", "no");
    } 
    getMovies(dispatch);
  }, [dispatch, previousPgaeNo]);

  const handleDelete = (id) => {
    deleteMovie(id, dispatch);
  };

  const columns = [
    { field: "_id",
      headerName: "SNo",
      width: 86,
      align:'center',
      renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 + (15*pageState) ,
      disableColumnMenu:true, 
    },
    {
      field: "img",
      headerName: "Image",
      width: 170,
      align:'center',
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
            {/* {params.row.title} */}
          </div>
        );
      },
    },
    { field: "title", flex:1,headerName: "Title", width: 200 },
    // { field: "genre", headerName: "Genre", width: 120 },
    { field: "year", headerName: "Year", width: 120 },
    // { field: "limit", headerName: "Limit", width: 120 },
    { field: "type", headerName: "Type", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
          
            <Link
              to={{ pathname: "/movie/" + params.row._id, movie: params.row ,id: params.row._id,pageNo:pageState}}
            >
              <button className="productListEdit">Edit</button>
            </Link>
            {(params.row.type === 'series') ?
            <Link
              // to={{ pathname: "/seasons/" + params.row._id , series: params.row }}
              to={{ pathname: "/seasons/" + params.row._id , state: params.row }}
            >
              <button className="widgetSmButton"><Visibility className="widgetSmIcon" /></button>
            </Link>:<></>
      }

            <DeleteOutline
              className="productListDelete"
              onClick={
                () => {
                  let answer = window.confirm("Do you want to delete this item?")
                  if(answer){
                    handleDelete(params.row._id)
                  }
                }
              }
            />
          </>
        );
      },
    },
  ];

  const handlePageChange = (value) => {
    localStorage.setItem("moviePageNo",value)
    setPageState(value)
  };

  return (
    <>
    <div className="productList">
    <div className="productListHeading">
        <h1 className="addProductTitle" >Contents List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {movies.length}</h2>
        <Link to="/newMovie">
          <button className="productAddButton">Create</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rowHeight={150}
        rows={movies}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        rowCount={movies.length}
        page={pageState}
        onPageChange={handlePageChange}
        loading={isFetching}
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
    </>
  );
}
