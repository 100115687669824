import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./app.css";
import Home from "./pages/home/Home";
import Analytics from "./pages/analytics/Analytics";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import Login from "./pages/login/Login";
import { AuthContext } from "./context/authContext/AuthContext";
import { useContext } from "react";
import ListList from "./pages/listList/ListList";
import List from "./pages/list/List";
import NewList from "./pages/newList/NewList";
import MovieList from "./pages/movieList/MovieList";
import WatchHistoryAllList from "./pages/watchHistoryAllList/WatchHistoryAllList";
import KidsList from "./pages/kidsList/KidsList";
import Movie from "./pages/movie/Movie";
import Kids from "./pages/kids/Kids";
import NewMovie from "./pages/newMovie/NewMovie";
import NewEpisode from "./pages/newEpisode/NewEpisode";
import SliderList from "./pages/sliderList/SliderList";
import KidSliderList from "./pages/kidsliderList/KidSliderList";
import Slider from "./pages/slider/Slider";
import NewSlider from "./pages/newSlider/NewSlider";
import EpisodeList from "./pages/episodeList/EpisodeList";
import SeriesList from "./pages/seriesList/SeriesList";
import SeasonList from "./pages/seasonList/SeasonList";
import GenreList from "./pages/genreList/GenreList";
import NewGenre from "./pages/newGenre/NewGenre";
import NewPackaging from "./pages/newPackaging/NewPackaging";
import NewKids from "./pages/newKids/NewKids";
import PackagingList from "./pages/packagingList/PackagingList";
import NewKidSlider from "./pages/newKidSlider/NewKidSlider";
import Episode from "./pages/episode/Episode";
import Packaging from "./pages/packaging/Packaging";
import NewKidsEpisode from "./pages/newKidsEpisode/NewKidsEpisode";
import KidsEpisodeList from "./pages/kidsEpisodeList/KidsEpisodeList";
import KidsEpisode from "./pages/kidsEpisode/KidsEpisode";
import SeasonEpisodeList from "./pages/seasonEpisodeList/SeasonEpisodeList";
import KidSlider from "./pages/kidSlider/KidSlider";
import Genre from "./pages/genre/Genre";
import SubNowVideoList from "./pages/subNowVideoList/SubNowVideoList";
import NewSubNowVideo from "./pages/newSubNowVideo/NewSubNowVideo";
import ConfigList from "./pages/configList/ConfigList";
import SubscribedUserList from "./pages/subscribedUsersList/subscribedUsers";
import InternalContestList from "./pages/internalContestList/InternalContestList";
import NewInternalContest from "./pages/newInternalContest/NewInternalContest";
import InteralContest from "./pages/internalContest/InternalContest";
import Emails from "./pages/emails/Emails";
import NewEmail from "./pages/newEmail/NewEmail";
import ActiveSubscribedUserList from "./pages/activeSubscribedUsersList/activeSubscribedUserList";
import SubEndUserList from "./pages/subEndUserList/subEndUserList";
import NewLiveChannel from "./pages/newLiveChannel/NewLiveChannel";
import NewLiveChannelType from "./pages/newLiveChannelType/NewLiveChannelType";
import LiveChannelTypeList from "./pages/liveChannelTypeList/LiveChannelTypeList";
import LiveChannelList from "./pages/liveChannelList/LiveChannelList";
import LiveChannel from "./pages/liveChannel/LiveChannel";
import LiveChannelType from "./pages/liveChannelType/LiveChannelType";

// import Packaging from "./pages/packaging/Packaging";


function App() {
  const { user } = useContext(AuthContext);
  // console.log(user);
  return (
    <Router>
      <Switch>
        <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>
        {user && (
          <>
            <Topbar />
            <div className="container">
              <Sidebar />
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/users">
                <UserList />
              </Route>
              <Route path="/subscribedusers">
                <SubscribedUserList/>
              </Route>
              <Route path="/activesubscribedusers">
                <ActiveSubscribedUserList/>
              </Route>
              <Route path="/subscriptionendusers">
                <SubEndUserList/>
              </Route>
              <Route path="/user/:userId">
                <User />
              </Route>
              <Route path="/newUser">
                <NewUser />
              </Route>
              <Route path="/movies">
                <MovieList />
              </Route>
              <Route path="/kidss">
                <KidsList />
              </Route>
              <Route path="/movie/:movieId">
                <Movie />
              </Route>
              <Route path="/kids/:kidsId">
                <Kids />
              </Route>
              <Route path="/newMovie">
                <NewMovie />
              </Route>
              <Route path="/lists">
                <ListList />
              </Route>
              <Route path="/sliders">
                <SliderList />
              </Route>
              <Route path="/kidsliders">
                <KidSliderList />
              </Route>
              <Route path="/slider/:sliderId">
                <Slider />
              </Route>
              <Route path="/newSlider">
                <NewSlider />
              </Route>
              <Route path="/list/:listId">
                <List />
              </Route>
              <Route path="/newlist">
                <NewList />
              </Route>
              <Route path="/seasons/:seriesId">
                <SeasonList />
              </Route>
              <Route path="/seasonEpisode/episodes/list/:seriesId/:seasonId">
                <SeasonEpisodeList />
              </Route>
              <Route path="/episodes">
                <EpisodeList />
              </Route>
              <Route path="/series">
                <SeriesList />
              </Route>
              <Route path="/newEpisode">
                <NewEpisode />
              </Route>
              <Route path="/genres">
                <GenreList />
              </Route>
              <Route path="/genre/:genreId">
                <Genre />
              </Route>
              <Route path="/packagings">
                <PackagingList />
              </Route>
              <Route path="/newGenre">
                <NewGenre />
              </Route>
              <Route path="/newPackaging">
                <NewPackaging />
              </Route>
              <Route path="/newKids">
                <NewKids />
              </Route>
              <Route path="/newKidSlider">
                <NewKidSlider />
              </Route>
              <Route path="/kidslider/:sliderId">
                <KidSlider />
              </Route>
              <Route path="/episode/:episodeId">
                <Episode />
              </Route>
              <Route path="/packaging/:packagingId">
                <Packaging />
              </Route>
              <Route path="/newKidsEpisode">
                <NewKidsEpisode />
              </Route>
              <Route path="/kidsEpisodes">
                <KidsEpisodeList />
              </Route>
              <Route path="/kidsEpisode/:episodeId">
                <KidsEpisode />
              </Route>
              <Route path="/subscribe-videos">
                <SubNowVideoList />
              </Route>
              <Route path="/subscribe-video/:subId">
                <NewGenre />
              </Route>
              <Route path="/new-subscribe-video">
                <NewSubNowVideo />
              </Route>
              <Route path="/configs">
                <ConfigList />
              </Route>
              <Route path="/analytics">
                <Analytics />
              </Route>
              <Route path="/watchHistoryAll">
                <WatchHistoryAllList />
              </Route>
              <Route path="/internal-contests">
                <InternalContestList />
              </Route>
              <Route path="/new-internal-contest">
                <NewInternalContest />
              </Route>
              <Route path="/internal-contest/:internalContestId">
                <InteralContest />
              </Route>
              <Route path="/mails">
                <Emails/>
              </Route>
              <Route path="/new-email">
                <NewEmail/>
              </Route>
              <Route path="/new-live-channel">
                <NewLiveChannel/>
              </Route>
              <Route path="/live-channels">
                <LiveChannelList/>
              </Route>
              <Route path="/liveChannel/:liveChannelId">
                <LiveChannel/>
              </Route>

              <Route path="/new-live-channel-type">
                <NewLiveChannelType/>
              </Route>
              <Route path="/live-channel-type-list">
                <LiveChannelTypeList/>
              </Route>
              <Route path="/liveChannelType/:liveChannelTypeId">
                <LiveChannelType/>
              </Route>
            </div>
          </>
        )}
      </Switch>
    </Router>
  );
}

export default App;
