import "./featuredInfo.css";
// import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import { useEffect,useContext} from 'react';
import { UserContext } from "../../context/userContext/UserContext";
import { getActiveSubUsers } from "../../context/userContext/apiCalls";



export default function FeaturedInfo({totalusers,totalSub,totalSubExp}) {
  const history = useHistory();
  const { users, dispatch } = useContext(UserContext);


  useEffect(() =>{
    getActiveSubUsers(dispatch);

  },[dispatch])

  return (
    <div className="featured">
      <div className="featuredItem" onClick={() => {history.push('/users')}}>
         <span className="featuredTitle">Total Users</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{totalusers.toLocaleString('en-IN')}</span>
          {/* <span className="featuredMoneyRate">
            -11.4 <ArrowDownward  className="featuredIcon negative"/>
          </span> */}
        </div>
        {/* <span className="featuredSub">Compared to last month</span>  */}
      </div>
      <div className="featuredItem" onClick={() => {history.push('/subscribedusers')}}>
        <span className="featuredTitle">Lifetime Subscribed Users</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{(totalSub+totalSubExp).toLocaleString('en-IN')}</span>
          {/* <span className="featuredMoneyRate">
            -1.4 <ArrowDownward className="featuredIcon negative"/>
          </span> */}
        </div>
        {/* <span className="featuredSub">Compared to last month</span> */}
      </div>
      <div className="featuredItem" onClick={() => {history.push('/activesubscribedusers')}}>
        <span className="featuredTitle">Active Subscribed Users</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{users.length.toLocaleString('en-IN')}</span>
          {/* <span className="featuredMoneyRate">
            -1.4 <ArrowDownward className="featuredIcon negative"/>
          </span> */}
        </div>
        {/* <span className="featuredSub">Compared to last month</span> */}
      </div>
      <div className="featuredItem" onClick={() => {history.push('/subscriptionendusers')}}>
        <span className="featuredTitle">Inactive Subscribed Users</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{totalSubExp.toLocaleString('en-IN')}</span>
          {/* <span className="featuredMoneyRate">
            -1.4 <ArrowDownward className="featuredIcon negative"/>
          </span> */}
        </div>
        {/* <span className="featuredSub">Compared to last month</span> */}
      </div>
      {/* <div className="featuredItem"> */}
        {/* <span className="featuredTitle">Cost</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">$2,225</span>
          <span className="featuredMoneyRate">
            +2.4 <ArrowUpward className="featuredIcon"/>
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span> */}
      {/* </div> */}
    </div>
  );
}
