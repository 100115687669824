export const getLiveChannelsStart = () => ({
  type: "GET_LIVECHANNELS_START",
});

export const getLiveChannelsSuccess = (liveChannels) => ({
  type: "GET_LIVECHANNELS_SUCCESS",
  payload: liveChannels,
});

export const getLiveChannelsFailure = () => ({
  type: "GET_LIVECHANNELS_FAILURE",
});

export const createLiveChannelStart = () => ({
  type: "CREATE_LIVECHANNEL_START",
});

export const createLiveChannelSuccess = (liveChannel) => ({
  type: "CREATE_LIVECHANNEL_SUCCESS",
  payload: liveChannel,
});

export const createLiveChannelFailure = () => ({
  type: "CREATE_LIVECHANNEL_FAILURE",
});

export const updateLiveChannelStart = () => ({
  type: "UPDATE_LIVECHANNEL_START",
});

export const updateLiveChannelSuccess = (liveChannel) => ({
  type: "UPDATE_LIVECHANNEL_SUCCESS",
  payload: liveChannel,
});

export const updateLiveChannelFailure = () => ({
  type: "UPDATE_LIVECHANNEL_FAILURE",
});

export const deleteLiveChannelStart = () => ({
  type: "DELETE_LIVECHANNEL_START",
});

export const deleteLiveChannelSuccess = (id) => ({
  type: "DELETE_LIVECHANNEL_SUCCESS",
  payload: id,
});

export const deleteLiveChannelFailure = () => ({
  type: "DELETE_LIVECHANNEL_FAILURE",
});

export const getLiveChannelStart = () => ({
  type: "GET_LIVECHANNEL_START",
});

export const getLiveChannelSuccess = (liveChannel) => ({
  type: "GET_LIVECHANNEL_SUCCESS",
  payload: liveChannel,
});

export const getLiveChannelFailure = () => ({
  type: "GET_LIVECHANNEL_FAILURE",
});
