import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
// import { userData } from "../../dummyData";
import WidgetSm from "../../components/widgetSm/WidgetSm";
// import WidgetLg from "../../components/widgetLg/WidgetLg";
import { useEffect, useMemo, useState, useContext} from "react";
import axios from "axios";
import { logout } from "../../context/authContext/AuthActions";
import { AuthContext } from "../../context/authContext/AuthContext";


export default function Home() {
  const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const [userStats, setUserStats] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSubscribedUsers, setTotalSubscribedUsers] = useState(0);
  const [totalSubExpUsers, setTotalSubExpUsers] = useState(0);
  const { dispatch } = useContext(AuthContext);





  useEffect(() => {


    const getStats = async () => {
      try {
        const res = await axios1.get("/users/stats", {
          headers: {
            token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        });
        // console.log(res.data)
        const statsList = res.data.sort(function (a, b) {
          var n = a._id.year - b._id.year;
          if (n !== 0) {
              return n;
          }
          return a._id.month - b._id.month;
        });
        // console.log(statsList)
        statsList.map((item) =>
          setUserStats((prev) => [
            ...prev,
            { name: MONTHS[item._id.month - 1] + " " + item._id.year, "New User": item.total },
          ])
        );
      } catch (err) {
        // console.log(err);
        dispatch(logout())
      }
    };
    const getUsers = async () => {
      try {
        const res = await axios1.get("/users/count", {
          headers: {
            token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        });
        setTotalUsers(res.data[0].count+res.data[1].count+res.data[2].count);
        // console.log(res.data[3])
        setTotalSubExpUsers(res.data[3].expSubUsers);
        setTotalSubscribedUsers(res.data.filter((a)=>a._id === true)[0].count);

      } catch (err) {
        // console.log(err);
        dispatch(logout())


      }
    };
    getUsers();
    getStats();
  }, [MONTHS]);

  return (
    <div className="home">
      <FeaturedInfo totalusers={totalUsers} totalSub = {totalSubscribedUsers} totalSubExp={totalSubExpUsers}/>
      <Chart data={userStats} title="User Analytics" grid dataKey="New User" />
      <div className="homeWidgets">
        <WidgetSm />
        {/* <WidgetLg /> */}
      </div>
    </div>
  );
}
