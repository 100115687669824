const SliderReducer = (state, action) => {
  switch (action.type) {
    case "GET_SLIDERS_START":
      return {
        sliders: [],
        isFetching: true,
        error: false,
      };
    case "GET_SLIDERS_SUCCESS":
      return {
        sliders: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_SLIDERS_FAILURE":
      return {
        sliders: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_SLIDER_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_SLIDER_SUCCESS":
      return {
        sliders: [...state.sliders, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_SLIDER_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_SLIDER_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_SLIDER_SUCCESS":
      return {
        sliders: state.sliders.map(
          (slider) => slider._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_SLIDER_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_SLIDER_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_SLIDER_SUCCESS":
      return {
        sliders: state.sliders.filter((slider) => slider._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_SLIDER_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_SLIDER_START":
        return {
          slider: {},
          isFetching: true,
          error: false,
        };
      case "GET_SLIDER_SUCCESS":
        return {
          slider: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_SLIDER_FAILURE":
        return {
          slider: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default SliderReducer;
