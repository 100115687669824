import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./context/authContext/AuthContext";
import { MovieContextProvider } from "./context/movieContext/MovieContext";
import { ListContextProvider } from "./context/listContext/ListContext";
import { SliderContextProvider } from "./context/sliderContext/SliderContext";
import { EpisodeContextProvider } from "./context/episodeContext/EpisodeContext";
import { GenreContextProvider } from "./context/genreContext/GenreContext";
import { LiveChannelTypeContextProvider } from "./context/liveChannelTypeContext/LiveChannelTypeContext";
import { LiveChannelContextProvider } from "./context/liveChannelContext/LiveChannelContext";
import { UserContextProvider } from "./context/userContext/UserContext";
import { KidsContextProvider } from "./context/kidsContext/KidsContext";
import { PackagingContextProvider } from "./context/packagingContext/PackagingContext";
import { SubNowVideoContextProvider } from "./context/subNowVideoContext/SubNowVideoContext";
import { KidSliderContextProvider } from "./context/kidsliderContext/KidSliderContext";
import { KidsEpisodeContextProvider } from "./context/kidsEpisodeContext/KidsEpisodeContext";
import { SeasonEpisodeContextProvider } from "./context/seasonEpisodeContext/SeasonEpisodeContext";
import { ConfigContextProvider } from "./context/configContext/ConfigContext";
import { InternalContestContextProvider } from "./context/internalContestContext/InternalContestContext";

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <MovieContextProvider>
        <ListContextProvider>
          <SliderContextProvider>
            <EpisodeContextProvider>
              <GenreContextProvider>
                <UserContextProvider>
                  <KidsContextProvider>
                    <PackagingContextProvider>
                      <KidSliderContextProvider>
                        <KidsEpisodeContextProvider>
                          <SeasonEpisodeContextProvider>
                            <SubNowVideoContextProvider>
                             <ConfigContextProvider>
                              <InternalContestContextProvider>
                                <LiveChannelTypeContextProvider>
                                  <LiveChannelContextProvider>
                                    <App />
                                  </LiveChannelContextProvider>
                                </LiveChannelTypeContextProvider>
                              </InternalContestContextProvider>
                              </ConfigContextProvider>
                            </SubNowVideoContextProvider>
                          </SeasonEpisodeContextProvider>
                        </KidsEpisodeContextProvider>
                      </KidSliderContextProvider>
                    </PackagingContextProvider>
                  </KidsContextProvider>
                </UserContextProvider>
              </GenreContextProvider>
            </EpisodeContextProvider>
          </SliderContextProvider>
        </ListContextProvider>
      </MovieContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
