import { useContext, useEffect,useState } from "react";
import "./newKidSlider.css";
import ReactS3Client from "../../s3";
import { createKidSlider } from "../../context/kidsliderContext/apiCalls";
import { KidSliderContext } from "../../context/kidsliderContext/KidSliderContext";
import {v4 as uuid} from "uuid";
import Select from 'react-select'
import { getGenresList } from "../../context/genreContext/apiCalls";
import { useHistory } from "react-router-dom";



export default function NewKidSlider() {
  const history = useHistory()
  const [slider, setMovie] = useState(null);
  const [imgDimension, setImgDimensions] = useState({
    "image": { width: '', height: '',size:0 },
    "title_image": { width: '', height: '',size:0 },
    "thumbnail_image": { width: '', height: '',size:0 },
  })
  const [img, setImg] = useState(null);
  const [imgTitle, setImgTitle] = useState(null);
  const [imgSm, setImgSm] = useState(null);
  const [options, setOptions] = useState([]);

  // const [trailer, setTrailer] = useState(null);
  // const [video, setVideo] = useState(null);
  const [uploaded, setUploaded] = useState(0);

  const { dispatch } = useContext(KidSliderContext);


  useEffect(() => {
    getGenresList().then(function (gen){
      const contentVal = gen.map((ge) => (
        {value:ge.name,label:ge.name}
    ))
    setOptions(contentVal)
      // console.log(gen)
    })
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setMovie({ ...slider, [e.target.name]: value });
  };

  const upload = (items) => {
    items.forEach((item) => {
      const fileName = new Date().getTime() + uuid();

      ReactS3Client
        .uploadFile(item.file,'kid-slider-images/'+fileName)
        .then((data) => {
          setMovie((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
                    setUploaded((prev) => prev + 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handleInputChange = (value) => {
    const val = []
    for (let i = 0; i < value.length; i++) {
       val.push(value[i].value);
    }
      setMovie({ ...slider, 'genre': val });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      { file: imgTitle, label: "imgTitle" },
      { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createKidSlider(slider, dispatch);
    history.push("/kidsliders")
  };

  const getImgDimensions = (str,target) => {
    let dim;
    let image = new Image()
    image.src = window.URL.createObjectURL(target)
    image.onload = () => {
      dim = {width: image.width, height: image.height }
      if(str === "img"){
        setImgDimensions(prev => ({...prev, image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
      else if(str === "title_img"){
        setImgDimensions(prev => ({...prev, title_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
      else if(str === "thumb_img"){
        setImgDimensions(prev => ({...prev, thumbnail_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
    }
  }

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Kids Slider</h1>
      <form className="addProductForm">
        <div className="input_fields">
        <div className="addContentItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="John Wick"
            name="title"
            required="required"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem">
          <label>Description</label>
          <input
            type="text"
            placeholder="Description"
            name="desc"
            required="required"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem">
          <label>Content ID</label>
          <input
            required
            type="text"
            placeholder="Content ID"
            name="content_id"
            onChange={handleChange}
          />
        </div>
        <div className="inline_input_holder">

        <div className="addContentItem inline">
          <label>Year</label>
          <input
            type="text"
            placeholder="Year"
            required="required"
            name="year"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem inline">
          <label>Type</label>
          <select name="type" id="type" onChange={handleChange} required="required">
            <option value="" >Select Type</option>
            <option value="campaign">Campaign</option>
            <option value="movie">Movie</option>
            <option value="series">Series</option>
          </select>
        </div>
        </div>
      <div className="addContentItem">
          <label>Genre</label>
          <Select name="genre" id="genre" 
            // onInputChange={handleInputChange} 
            options={options}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            onChange={handleInputChange}
            // value={selectedOption}
            // onInputChange={handleInputChange}
          />
        </div>
        {/* <div className="addContentItem">
          <label>Duration</label>
          <input
            required
            type="text"
            placeholder="Duration"
            name="duration"
            onChange={handleChange}
          />
        </div> */}
        
        <div className="addContentItem">
          <label>Trailer</label>
          <input
            type="text"
            name="trailer"
            required="required"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem">
          <label>Video</label>
          <input
            type="text"
            name="video"
            required="required"
            onChange={handleChange}
          />
        </div>
        </div>
        
        
        <div className="image_input">
        <div className="addContentItem">
          <label>Image</label>
          <input
            type="file"
            accept="image/webp"
            id="img"
            name="img"
            required
            onChange={(e) => {setImg(e.target.files[0])
              getImgDimensions("img",e.target.files[0])}}
          />
          {imgDimension.image.width && imgDimension.image.height != undefined ? <p>Image dimensions : {`${imgDimension.image.width} x ${imgDimension.image.height}`}</p> : null}
          {imgDimension.image.size !== 0 ? <p>Image size : {`${imgDimension.image.size}`}KB</p> : null}
        </div>
        <div className="addContentItem">
          <label>Title image</label>
          <input
            type="file"
            accept="image/webp"
            id="imgTitle"
            name="imgTitle"
            required
            onChange={(e) => {setImgTitle(e.target.files[0])
              getImgDimensions("title_img",e.target.files[0])}}
          />
          {imgDimension.title_image.width && imgDimension.title_image.height != undefined ? <p>Image dimensions : {`${imgDimension.title_image.width} x ${imgDimension.title_image.height}`}</p> : null}
          {imgDimension.title_image.size !== 0 ? <p>Image size : {`${imgDimension.title_image.size}`}KB</p> : null}
        </div>
        <div className="addContentItem">
          <label>Thumbnail image</label>
          <input
            type="file"
            accept="image/webp"
            id="imgSm"
            name="imgSm"
            required
            onChange={(e) => {setImgSm(e.target.files[0])
              getImgDimensions("thumb_img",e.target.files[0])}}
          />
          {imgDimension.thumbnail_image.width && imgDimension.thumbnail_image.height != undefined ? <p>Image dimensions : {`${imgDimension.thumbnail_image.width} x ${imgDimension.thumbnail_image.height}`}</p> : null}
          {imgDimension.thumbnail_image.size !== 0 ? <p>Image size : {`${imgDimension.thumbnail_image.size}`}KB</p> : null}
        </div>
        </div>
        <div className="button_holder">

        {uploaded === 3 ? (
          <button className="addProductButton padding-20" onClick={handleSubmit}>
            Create
          </button>
        ) : (
          <button className="addProductButton padding-20" onClick={handleUpload} >
            Upload
          </button>
        )}
        </div>
      </form>
    </div>
  );
}
