const LiveChannelTypeReducer = (state, action) => {
  switch (action.type) {
    case "GET_LIVECHANNELTYPES_START":
      return {
        liveChannelTypes: [],
        isFetching: true,
        error: false,
      };
    case "GET_LIVECHANNELTYPES_SUCCESS":
      return {
        liveChannelTypes: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_LIVECHANNELTYPES_FAILURE":
      return {
        liveChannelTypes: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_LIVECHANNELTYPE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_LIVECHANNELTYPE_SUCCESS":
      return {
        liveChannelTypes: [...state.liveChannelTypes, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_LIVECHANNELTYPE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_LIVECHANNELTYPE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_LIVECHANNELTYPE_SUCCESS":
      return {
        liveChannelTypes: state.liveChannelTypes.map(
          (genre) => genre._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_LIVECHANNELTYPE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_LIVECHANNELTYPE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_LIVECHANNELTYPE_SUCCESS":
      return {
        liveChannelTypes: state.liveChannelTypes.filter((liveChannelType) => liveChannelType._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_LIVECHANNELTYPE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_LIVECHANNELTYPE_START":
        return {
          liveChannelType: {},
          isFetching: true,
          error: false,
        };
      case "GET_LIVECHANNELTYPE_SUCCESS":
        return {
          liveChannelType: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_LIVECHANNELTYPE_FAILURE":
        return {
          liveChannelType: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default LiveChannelTypeReducer;
