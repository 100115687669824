import "./barChart.css";
import {useHistory } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function WatchChart({ title, data, dataKey }) {

  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push("/watchHistoryAll")

  };

  return (
    <div className="chart">
      <h3 className="chartTitle">{title}</h3>
      <ResponsiveContainer width="100%" aspect={4 / 1}>
        <BarChart data={data}>
        <CartesianGrid strokeDasharray="1 3" />
        <XAxis tick={{ fontSize: 11 }} interval={0} dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={dataKey}  fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      <button className="addProductButton" onClick={handleSubmit}>
            View all
          </button>
    </div>
  );
}
