import "./kidsEpisodeList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { KidsEpisodeContext } from "../../context/kidsEpisodeContext/KidsEpisodeContext";
import { deleteKidsEpisode, getKidsEpisodes } from "../../context/kidsEpisodeContext/apiCalls";
import Loader from "../../components/loader/Loader";


export default function KidsEpisodeList() {
  const {isFetching, kidsepisodes, dispatch } = useContext(KidsEpisodeContext);

  useEffect(() => {
    getKidsEpisodes(dispatch);
  }, [dispatch]);

  // console.log(episodes);
  const handleDelete = (id) => {
    deleteKidsEpisode(id, dispatch);
  };

  const columns = [
    { field: "_id", headerName: "SNo", width: 84, filterable: false,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true,  },
    {
      field: "kidsepisode",
      headerName: "Kids Episode",
      align:"center",
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
            {/* {params.row.title} */}
          </div>
        );
      },
    },
    { field: "title", headerName: "Title", width: 340 },
    { field: "year", headerName: "Year", width: 120 },
    { field: "limit", headerName: "Limit", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/kidsEpisode/" + params.row._id, kidsepisode: params.row }}
            >
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Kids Episode List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {kidsepisodes.length}</h2>
        <Link to="/newKidsEpisode">
          <button className="productAddButton">Create</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
      sx={{ overflowX: 'scroll' }}
        rowHeight={90}
        rows={kidsepisodes}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        // filterMode="server"
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
