import "./packagingList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { PackagingContext } from "../../context/packagingContext/PackagingContext";
import { deletePackaging, getPackagings } from "../../context/packagingContext/apiCalls";
import Loader from "../../components/loader/Loader";

export default function MovieList() {
  const {isFetching, packagings, dispatch } = useContext(PackagingContext);

  useEffect(() => {
    getPackagings(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deletePackaging(id, dispatch);
  };

  const columns = [
    { field: "_id", headerName: "SNo", width: 84,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true,  },
    {
      field: "packaging",
      headerName: "Package Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="packagingListItem">
            {params.row.title}
          </div>
        );
      },
    },
    { field: "url", headerName: "URL",flex:1, width: 300 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/packaging/" + params.row._id, packaging: params.row,id: params.row._id }}
            >
              <button className="packagingListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="packagingListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="packagingList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Packaging List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {packagings.length}</h2>
        <Link to="/newPackaging">
          <button className="productAddButton">Create</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={packagings}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
