import "./activesubscribeduserlist.css";
import { DataGrid } from "@material-ui/data-grid";
// import { DeleteOutline } from "@material-ui/icons";
// import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
// import { useState } from "react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext/UserContext";
import { getActiveSubUsers } from "../../context/userContext/apiCalls";
import { Visibility } from "@material-ui/icons";
// import Loader from "../../components/loader/Loader";

export default function ActiveSubscribedUserList() {
  // const [users, setData] = useState(userRows);
  const [activeSubUser,setActiveSubUser] = useState([]);
  const {isFetching, users, dispatch } = useContext(UserContext);

  // const handleDelete = (id) => {
  //   setData(users.filter((item) => item.id !== id));
  // };
  
  useEffect(() => {
    getActiveSubUsers(dispatch);
  }, [dispatch]);



  // const handleDelete = (id) => {
  //  deleteUser(id, dispatch);
  // };
  const columns = [
    // { field: "_id", headerName: "ID", width: 90 },
    { field: "_id",
    headerName: "SNo",
    width: 86,
    align:'center',
    renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true, 
  },
    {
      field: "user",
      headerName: "User",
      width: 120,
      renderCell: (params) => {
        // let subEndDate = new Date(params.row.subscription_end_date).getTime()
        return (
          <div className="productListItem">
            {/* <img className="productListImg" src={params.row.img} alt="" /> */}
        
            {params.row.username}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "location",
      headerName: "Location",
      width: 140,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* {console.log(params.row.extra_metadata.length)} */}
            {params.row.extra_metadata.length > 0 ? JSON.parse(params.row.extra_metadata[0]).city + ',' + JSON.parse(params.row.extra_metadata[0]).country : "No location"}
          </div>
        );
      },
    },
    {
      field: "is_subscribed",
      headerName: "Subscribed",
      width: 145,
      valueFormatter: (params) => {
        let setSub = "";
        if (params.value) {
          setSub = "Subscribed";
        } else {
          setSub = "Unsubscribed";
        }
        return `${setSub}`;
      },
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 240,
      valueFormatter: (params) => {
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        let date = new Date(params.value);
        let formattedDate = date.toLocaleDateString('en-us',options);
        let formattedTime = date.toLocaleTimeString('en-us');
        return `${formattedTime + ', ' + formattedDate}`
      }
    },
    // { field: "updatedAt", headerName: "Updated Date", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              // to={{ pathname: "/user/" + params.row._id, user: params.row }}
              to={{ pathname: "/user/" + params.row._id, state: params.row }} 
            >
              <button className="widgetSmButton"><Visibility className="widgetSmIcon" /></button>
              {/* <button className="productListEdit">Edit</button> */}
            </Link>
            {/* <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            /> */}
          </>
        );
      },
    },
  ];

  return (
    <div className="userList">
      {console.log(users)}
      <h1 className="addProductTitle">Active Subscription User List</h1>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={users}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        // checkboxSelection
        loading={isFetching}
        getRowId={(r) => r._id}

      />
  {/* } */}
    </div>
  );
}
