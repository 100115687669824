export const getPackagingsStart = () => ({
  type: "GET_PACKAGINGS_START",
});

export const getPackagingsSuccess = (packagings) => ({
  type: "GET_PACKAGINGS_SUCCESS",
  payload: packagings,
});

export const getPackagingsFailure = () => ({
  type: "GET_PACKAGINGS_FAILURE",
});

export const createPackagingStart = () => ({
  type: "CREATE_PACKAGING_START",
});

export const createPackagingSuccess = (packaging) => ({
  type: "CREATE_PACKAGING_SUCCESS",
  payload: packaging,
});

export const createPackagingFailure = () => ({
  type: "CREATE_PACKAGING_FAILURE",
});

export const updatePackagingStart = () => ({
  type: "UPDATE_PACKAGING_START",
});

export const updatePackagingSuccess = (packaging) => ({
  type: "UPDATE_PACKAGING_SUCCESS",
  payload: packaging,
});

export const updatePackagingFailure = () => ({
  type: "UPDATE_PACKAGING_FAILURE",
});

export const deletePackagingStart = () => ({
  type: "DELETE_PACKAGING_START",
});

export const deletePackagingSuccess = (id) => ({
  type: "DELETE_PACKAGING_SUCCESS",
  payload: id,
});

export const deletePackagingFailure = () => ({
  type: "DELETE_PACKAGING_FAILURE",
});

export const getPackagingStart = () => ({
  type: "GET_PACKAGING_START",
});

export const getPackagingSuccess = (packaging) => ({
  type: "GET_PACKAGING_SUCCESS",
  payload: packaging,
});

export const getPackagingFailure = () => ({
  type: "GET_PACKAGING_FAILURE",
});
