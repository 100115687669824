export const getKidSlidersStart = () => ({
  type: "GET_KID_SLIDERS_START",
});

export const getKidSlidersSuccess = (Kidsliders) => ({
  type: "GET_KID_SLIDERS_SUCCESS",
  payload: Kidsliders,
});

export const getKidSlidersFailure = () => ({
  type: "GET_KID_SLIDERS_FAILURE",
});

export const createKidSliderStart = () => ({
  type: "CREATE_KID_SLIDER_START",
});

export const createKidSliderSuccess = (Kidsliders) => ({
  type: "CREATE_KID_SLIDER_SUCCESS",
  payload: Kidsliders,
});

export const createKidSliderFailure = () => ({
  type: "CREATE_KID_SLIDER_FAILURE",
});

export const updateKidSliderStart = () => ({
  type: "UPDATE_KID_SLIDER_START",
});

export const updateKidSliderSuccess = (Kidslider) => ({
  type: "UPDATE_KID_SLIDER_SUCCESS",
  payload: Kidslider,
});

export const updateKidSliderFailure = () => ({
  type: "UPDATE_KID_SLIDER_FAILURE",
});

export const deleteKidSliderStart = () => ({
  type: "DELETE_KID_SLIDER_START",
});

export const deleteKidSliderSuccess = (id) => ({
  type: "DELETE_KID_SLIDER_SUCCESS",
  payload: id,
});

export const deleteKidSliderFailure = () => ({
  type: "DELETE_KID_SLIDER_FAILURE",
});

export const getKidSliderStart = () => ({
  type: "GET_KID_SLIDER_START",
});

export const getKidSliderSuccess = (Kidslider) => ({
  type: "GET_KID_SLIDER_SUCCESS",
  payload: Kidslider,
});

export const getKidSliderFailure = () => ({
  type: "GET_KID_SLIDER_FAILURE",
});
