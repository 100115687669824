import { useContext, useState } from "react";
import "./newLiveChannelType.css";
import { createLiveChannelType } from "../../context/liveChannelTypeContext/apiCalls";
import { LiveChannelTypeContext } from "../../context/liveChannelTypeContext/LiveChannelTypeContext";
import { useHistory } from "react-router-dom";


export default function NewLiveChannelType() {
  const history = useHistory()
  const [liveChannelType, setGenre] = useState(null);

  const { dispatch } = useContext(LiveChannelTypeContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setGenre({ ...liveChannelType, [e.target.name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    createLiveChannelType(liveChannelType, dispatch);
    history.push("/live-channel-type-list")
  };

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Live Channel Type</h1>
      <form className="addProductForm">
        <div className="addProductItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="John Wick"
            name="name"
            required
            onChange={handleChange}
          />
          <label>Position</label>
          <input
            type="number"
            name="position"
            onChange={handleChange}
          />
        </div>
          <button className="addProductButton" onClick={handleSubmit}>
            Create
          </button>
      </form>
    </div>
  );
}
