import axios from "axios";
import {
  createGenreFailure,
  createGenreStart,
  createGenreSuccess,
  deleteGenreFailure,
  deleteGenreStart,
  deleteGenreSuccess,
  getGenresFailure,
  getGenresStart,
  getGenresSuccess,
  getGenreFailure,
  getGenreStart,
  getGenreSuccess,
  updateGenreFailure,
  updateGenreStart,
  updateGenreSuccess,
} from "./GenreActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getGenres = async (dispatch) => {
  dispatch(getGenresStart());
  try {
    const res = await axios1.get("/genres", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getGenresSuccess(res.data));
  } catch (err) {
    dispatch(getGenresFailure());
  }
};

//create
export const createGenre = async (genre, dispatch) => {
  dispatch(createGenreStart());
  try {
    const res = await axios1.post("/genres", genre, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createGenreSuccess(res.data));
  } catch (err) {
    dispatch(createGenreFailure());
  }
};

//delete
export const deleteGenre = async (id, dispatch) => {
  dispatch(deleteGenreStart());
  try {
    await axios1.delete("/genres/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteGenreSuccess(id));
  } catch (err) {
    dispatch(deleteGenreFailure());
  }
};

export const getGenre = async (id, dispatch) => {
  dispatch(getGenreStart());
  try {
    const res = await axios1.get("/genres/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getGenreSuccess(res.data));
  } catch (err) {
    dispatch(getGenreFailure());
  }
};

export const getGenresList = async () => {
  try {
    const res = await axios1.get("/genres", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};

export const getGenreDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/movies/genre/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getSliderGenreDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/sliders/genre/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getGenreEpisodeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/episodes/genre/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getKidsGenreDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/kids/genre/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getGenreForUpdate = async (id) => {
  try {
    const res = await axios1.get("/genres/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const updateGenre = async (id, genre, dispatch) => {
  dispatch(updateGenreStart());
  try {
    const res = await axios1.put("/genres/"+ id, genre, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateGenreSuccess(res.data));
  } catch (err) {
    dispatch(updateGenreFailure());
  }
};