import LiveChannelTypeReducer from "./LiveChannelTypeReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  liveChannelTypes: [],
  isFetching: false,
  error: false,
};

export const LiveChannelTypeContext = createContext(INITIAL_STATE);

export const LiveChannelTypeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LiveChannelTypeReducer, INITIAL_STATE);

  return (
    <LiveChannelTypeContext.Provider
      value={{
        liveChannelTypes: state.liveChannelTypes,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </LiveChannelTypeContext.Provider>
  );
};
