import "./emails.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { PackagingContext } from "../../context/packagingContext/PackagingContext";
import { deletePackaging, getPackagings } from "../../context/packagingContext/apiCalls";
import Loader from "../../components/loader/Loader";

export default function Emails() {
  const {isFetching, packagings, dispatch } = useContext(PackagingContext);

  useEffect(() => {
    getPackagings(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deletePackaging(id, dispatch);
  };

  const columns = [
    {
      field: "packaging",
      headerName: "Email Inbox",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="packagingListItem">
            <h3>{params.row.title}</h3>
          </div>
        );
      },
    },
  ];

  return (
    <div className="packagingList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Email</h1>
        <div className="productListHeadingR">
        {/* <h2 className="productNumber">No of records : {packagings.length}</h2> */}
        <Link to="/new-email">
          <button className="productAddButton wx-10">Compose Email</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={packagings}
        rowHeight={120}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />

  {/* } */}
    </div>
  );
}
