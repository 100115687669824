export const getSubNowVideosStart = () => ({
  type: "GET_SUBNOWVIDEOS_START",
});

export const getSubNowVideosSuccess = (subnowvideos) => ({
  type: "GET_SUBNOWVIDEOS_SUCCESS",
  payload: subnowvideos,
});

export const getSubNowVideosFailure = () => ({
  type: "GET_SUBNOWVIDEOS_FAILURE",
});

export const createSubNowVideoStart = () => ({
  type: "CREATE_SUBNOWVIDEO_START",
});

export const createSubNowVideoSuccess = (subnowvideo) => ({
  type: "CREATE_SUBNOWVIDEO_SUCCESS",
  payload: subnowvideo,
});

export const createSubNowVideoFailure = () => ({
  type: "CREATE_SUBNOWVIDEO_FAILURE",
});

export const updateSubNowVideoStart = () => ({
  type: "UPDATE_SUBNOWVIDEO_START",
});

export const updateSubNowVideoSuccess = (subnowvideo) => ({
  type: "UPDATE_SUBNOWVIDEO_SUCCESS",
  payload: subnowvideo,
});

export const updateSubNowVideoFailure = () => ({
  type: "UPDATE_SUBNOWVIDEO_FAILURE",
});

export const deleteSubNowVideoStart = () => ({
  type: "DELETE_SUBNOWVIDEO_START",
});

export const deleteSubNowVideoSuccess = (id) => ({
  type: "DELETE_SUBNOWVIDEO_SUCCESS",
  payload: id,
});

export const deleteSubNowVideoFailure = () => ({
  type: "DELETE_SUBNOWVIDEO_FAILURE",
});

export const getSubNowVideoStart = () => ({
  type: "GET_SUBNOWVIDEO_START",
});

export const getSubNowVideoSuccess = (subnowvideo) => ({
  type: "GET_SUBNOWVIDEO_SUCCESS",
  payload: subnowvideo,
});

export const getSubNowVideoFailure = () => ({
  type: "GET_SUBNOWVIDEO_FAILURE",
});
