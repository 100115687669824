import { useContext, useEffect, useState } from "react";
import "./newSlider.css";
import ReactS3Client from "../../s3";
import { createSlider } from "../../context/sliderContext/apiCalls";
import { SliderContext } from "../../context/sliderContext/SliderContext";
import { v4 as uuid } from "uuid";
import Select from 'react-select'
import { getGenresList } from "../../context/genreContext/apiCalls";
import { getContentsList } from "../../context/movieContext/apiCalls";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/Loader";




export default function NewSlider() {
  const [slider, setMovie] = useState(null);
  const [img, setImg] = useState(null);
  const [imgDimension, setImgDimensions] = useState({
    "image": { width: '', height: '',size:0 },
    "title_image": { width: '', height: '',size:0 },
    "thumbnail_image": { width: '', height: '',size:0 },
  })
  const [imgTitle, setImgTitle] = useState(null);
  const [imgSm, setImgSm] = useState(null);
  const [options, setOptions] = useState([]);
  const [contentOptions,setContentOptions] = useState([]);
  const [allMovies,setAllMovies] = useState([]);
  const [selectedMovie,setSelectedMovie] = useState({});
  const [selectedGenre,setSelectedGenre] = useState([]);


  // const [flag, setFlag] = useState([]);

  // const [trailer, setTrailer] = useState(null);
  // const [video, setVideo] = useState(null);
  const [uploaded, setUploaded] = useState(0);
  const [loading, setLoading] = useState(false);

  const { dispatch } = useContext(SliderContext);
  const history = useHistory();


  useEffect(() => {
    getGenresList().then(function (gen) {
      const contentVal = gen.map((ge) => (
        { value: ge.name, label: ge.name }
      ))
      setOptions(contentVal)
      // console.log(gen)
    })

    getContentsList().then(function(movie){
      // console.log(movie)
      setAllMovies(movie);
      const moviesOptions = movie.map((mov) =>(
        { value : mov._id, label : mov.title }
      ))
      setContentOptions(moviesOptions)
    })
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    // console.log(value);
    setMovie({ ...slider, [e.target.name]: value });
    
  
  };

  const upload = (items) => {
    setLoading(true)

    items.forEach((item) => {
      const fileName = new Date().getTime() + uuid();
      ReactS3Client
        .uploadFile(item.file, 'slider-images/' + fileName)
        .then((data) => {
          setMovie((prev) => {
            return { ...prev, [item.label]: data.location };
          });
          setUploaded((prev) => prev + 1);
          setLoading(false)

          // console.log(data)

        })
        .catch(err => console.error(err))
    });
  };

  const handleInputChange = (value) => {
    const val = []
    for (let i = 0; i < value.length; i++) {
      val.push(value[i].value);
    }
    setMovie({ ...slider, 'genre': val });
  };

  const handleContentInputChange = (value) => {
    // console.log(value)
    setMovie({ ...slider, 'content_id': value.value,'title': value.label });
    const filteredData = allMovies.filter(obj => obj._id === value.value);
    console.log(filteredData[0]);
    setSelectedMovie(filteredData[0])
    // const gen = 
    const contentVal = filteredData[0].genre.map((ge) => (
      { value: ge, label: ge }
    ))
    setSelectedGenre(contentVal)
    // setMovie({ ...slider, 'title': value.label });
  };


  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      { file: imgTitle, label: "imgTitle" },
      { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ]);
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    let dd = new Date(value)
    setMovie({ ...slider, [e.target.name]: dd });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createSlider(slider, dispatch);
    history.push("/sliders")
  };

  const uploadButtonHandler = () => {
    if (loading === true) {
      return (
        <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>
      )
    }
    else {
      return (
        <button className="addProductButton" onClick={handleUpload} >
          Upload
        </button>
      )
    }
  }

  const getImgDimensions = (str,target) => {
    let image = new Image()
    image.src = window.URL.createObjectURL(target)
    image.onload = () => {
      if(str === "img"){
        setImgDimensions(prev => ({...prev, image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
      else if(str === "title_img"){
        setImgDimensions(prev => ({...prev, title_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
      else if(str === "thumb_img"){
        setImgDimensions(prev => ({...prev, thumbnail_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
    }
  }


  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Slider</h1>
      <form className="addProductForm">
        <div className="input_fields">
        <div className="addContentItem">
          <label>Content Title</label>
          <Select name="content_id" id="content_id"
            // onInputChange={handleInputChange} 
            options={contentOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={true}
            onChange={handleContentInputChange}
          // value={selectedOption}
          // onInputChange={handleInputChange}
          />
        </div>
        {/* <div className="addContentItem">
          <label>Year</label>
          <input
            type="text"
            placeholder="Year"
            required="required"
            name="year"
            value={selectedMovie.year}
            onChange={handleChange}
          />
        </div> */}
        {/* Adding slider start and end date */}
        <div className="addContentItem">
          <label>Slider Start Date-Time</label>
          <input
            type="datetime-local"
            placeholder="Slider Start Date-TIme"
            required="required"
            name="slider_start_date"
            onChange={handleDateChange}
          />
        </div>
        <div className="addContentItem">
          <label>Slider End Date-Time</label>
          <input
            type="datetime-local"
            placeholder="Slider End Date-Time"
            required="required"
            name="slider_end_date"
            onChange={handleDateChange}
          />
        </div>
        {/* <div className="addContentItem">
          <label>Genre</label>
          <Select name="genre" id="genre"
            // onInputChange={handleInputChange} 
            options={options}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            onChange={handleInputChange}
          value={selectedGenre}
          // onInputChange={handleInputChange}
          />
        </div> */}
        {/* <div className="addContentItem">
          <label>Duration</label>
          <input
            required
            type="text"
            placeholder="Duration"
            name="duration"
            onChange={handleChange}
          />
        </div> */}
        <div className="addContentItem">
          <label>Type</label>
          <select name="type" id="type" onChange={handleChange} required="required" value={selectedMovie.type}>
            <option value="" >Select Type</option>
            <option value="campaign">Campaign</option>
            <option value="movie">Movie</option>
            <option value="series">Series</option>
          </select>
        </div>
        {/* Removing Trailer Temporarily */}
        {/* <div className="addContentItem">
          <label>Trailer</label>
          <input
            type="text"
            name="trailer"
            required="required"
            onChange={handleChange}
          />
        </div> */}
        {/* Removing Video Temporarily */}
        {/* <div className="addContentItem">
          <label>Video</label>
          <input
            type="text"
            name="video"
            required="required"
            onChange={handleChange}
          />
        </div> */}
        </div>
        
        {/* <div className="addContentItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="John Wick"
            name="title"
            required="required"
            onChange={handleChange}
          />
        </div> */}
        {/* Removing Description Temporarily */}
        {/* <div className="addContentItem">
          <label>Description</label>
          <input
            type="text"
            placeholder="description"
            name="desc"
            required="required"
            onChange={handleChange}
          />
        </div> */}

        {/* <div className="addContentItem">
          <label>Content ID</label>
          <input
            required
            type="text"
            placeholder="Content ID"
            name="content_id"
            onChange={handleChange}
          />
        </div> */}
        
        <div className="image_input">
        <div className="addContentItem">
          <label>Mobile Slider Image</label>
          Suggested Image Dimensions : 600 x 1000
          <input
            type="file"
            accept="image/webp"
            id="img"
            name="img"
            required="required"
            onChange={(e) => {setImg(e.target.files[0])
              getImgDimensions("img",e.target.files[0])}}
          />
          {imgDimension.image.width && imgDimension.image.height != undefined ? <p>Image dimensions : {`${imgDimension.image.width} x ${imgDimension.image.height}`}</p> : null}
          {imgDimension.image.size !== 0 ? <p>Image size : {`${imgDimension.image.size}`}KB</p> : null}
        </div>
        <div className="addContentItem">
          <label>Title Slider image</label>
          Suggested Image Dimensions : 400 x 300
          <input
            type="file"
            accept="image/webp"
            id="imgTitle"
            name="imgTitle"
            required="required"
            onChange={(e) => {setImgTitle(e.target.files[0])
              getImgDimensions("title_img",e.target.files[0])
            }}
          />
          {imgDimension.title_image.width && imgDimension.title_image.height != undefined ? <p>Image dimensions : {`${imgDimension.title_image.width} x ${imgDimension.title_image.height}`}</p> : null}
          {imgDimension.title_image.size !== 0 ? <p>Image size : {`${imgDimension.title_image.size}`}KB</p> : null}
        </div>
        <div className="addContentItem">
          <label>TV/Web Slider image</label>
          Suggested Image Dimensions : 3840 x 2160
          <input
            type="file"
            accept="image/webp"
            id="imgSm"
            name="imgSm"
            required="required"
            onChange={(e) => {setImgSm(e.target.files[0])
              getImgDimensions("thumb_img",e.target.files[0])
            }}
          />
          {imgDimension.thumbnail_image.width && imgDimension.thumbnail_image.height != undefined ? <p>Image dimensions : {`${imgDimension.thumbnail_image.width} x ${imgDimension.thumbnail_image.height}`}</p> : null}
          {imgDimension.thumbnail_image.size !== 0 ? <p>Image size : {`${imgDimension.thumbnail_image.size}`}KB</p> : null}
        </div>
        </div>
        <div className="button_holder">
        {uploaded === 3 ? (
          <button className="addProductButton padding-20" onClick={handleSubmit}>
            Create
          </button>
        ) : uploadButtonHandler()}
        </div>
      </form>

    </div>
  );
}
