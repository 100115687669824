import SeasonEpisodeReducer from "./SeasonEpisodeReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  episodes: [],
  series: [],
  seasons: [],
  isFetching: false,
  error: false,
};

export const SeasonEpisodeContext = createContext(INITIAL_STATE);

export const SeasonEpisodeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SeasonEpisodeReducer, INITIAL_STATE);

  return (
    <SeasonEpisodeContext.Provider
      value={{
        episodes: state.episodes,
        series: state.series,
        seasons: state.seasons,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </SeasonEpisodeContext.Provider>
  );
};
