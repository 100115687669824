import axios from "axios";
import {
  createKidsEpisodeFailure,
  createKidsEpisodeStart,
  createKidsEpisodeSuccess,
  deleteKidsEpisodeFailure,
  deleteKidsEpisodeStart,
  deleteKidsEpisodeSuccess,
  getKidsEpisodesFailure,
  getKidsEpisodesStart,
  getKidsEpisodesSuccess,
  getKidsEpisodeFailure,
  getKidsEpisodeStart,
  getKidsEpisodeSuccess,
  getKidsSeasonsStart,
  getKidsSeasonsSuccess,
  getKidsSeasonsFailure,
  getKidsSeriesStart,
  getKidsSeriesSuccess,
  getKidsSeriesFailure,
  updateKidsEpisodeFailure,
  updateKidsEpisodeStart,
  updateKidsEpisodeSuccess,
} from "./KidsEpisodeActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getKidsEpisodes = async (dispatch) => {
  dispatch(getKidsEpisodesStart());
  try {
    const res = await axios1.get("/kidsepisodes", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidsEpisodesSuccess(res.data));
  } catch (err) {
    dispatch(getKidsEpisodesFailure());
  }
};

//create
export const createKidsEpisode = async (kidsEpisode, dispatch) => {
  dispatch(createKidsEpisodeStart());
  try {
    const res = await axios1.post("/kidsepisodes", kidsEpisode, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createKidsEpisodeSuccess(res.data));
  } catch (err) {
    dispatch(createKidsEpisodeFailure());
  }
};

//delete
export const deleteKidsEpisode = async (id, dispatch) => {
  dispatch(deleteKidsEpisodeStart());
  try {
    await axios1.delete("/kidsepisodes/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteKidsEpisodeSuccess(id));
  } catch (err) {
    dispatch(deleteKidsEpisodeFailure());
  }
};

export const getKidsEpisode = async (id, dispatch) => {
  dispatch(getKidsEpisodeStart());
  try {
    const res = await axios1.get("/kidsepisodes/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidsEpisodeSuccess(res.data));
  } catch (err) {
    dispatch(getKidsEpisodeFailure());
  }
};

export const getSeries = async (dispatch) => {
  dispatch(getKidsSeriesStart());
  try {
    const res = await axios1.get("/kidsSeries", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidsSeriesSuccess(res.data));
  } catch (err) {
    dispatch(getKidsSeriesFailure());
  }
};

export const getKidsSeasons = async (id, dispatch) => {
  dispatch(getKidsSeasonsStart());
  try {
    const res = await axios1.get("/kidsSeries/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidsSeasonsSuccess(res.data));
  } catch (err) {
    dispatch(getKidsSeasonsFailure());
  }
};

export const getKidsEpisodeForUpdate = async (id) => {
  try {
    console.log(id)
    const res = await axios1.get("/kidsepisodes/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

//update

export const updateKidsEpisode = async (id, episode, dispatch) => {
  dispatch(updateKidsEpisodeStart());
  try {
    const res = await axios1.put("/kidsepisodes/"+ id, episode, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateKidsEpisodeSuccess(res.data));
  } catch (err) {
    dispatch(updateKidsEpisodeFailure());
  }
};