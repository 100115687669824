import { useContext, useEffect, useState } from "react";
import "./newList.css";
import { getMovies } from "../../context/movieContext/apiCalls";
import { MovieContext } from "../../context/movieContext/MovieContext";
import { ListContext } from "../../context/listContext/ListContext";
import { createList } from "../../context/listContext/apiCalls";
import { useHistory } from "react-router-dom";
import Select from 'react-select'
// import { getGenresList } from "../../context/genreContext/apiCalls";


export default function NewList() {
  const [list, setList] = useState(null);
  const history = useHistory()

  const { dispatch } = useContext(ListContext);
  const { movies, dispatch: dispatchMovie } = useContext(MovieContext);
  // const [options, setOptions] = useState([]);


  useEffect(() => {
    getMovies(dispatchMovie);
    // getGenresList().then(function (gen){
    //   const contentVal = gen.map((ge) => (
    //     {value:ge.name,label:ge.name}
    // ))
    // setOptions(contentVal)
    //   // console.log(gen)
    // })
  }, [dispatchMovie]);

  const handleChange = (e) => {
    const value = e.target.value;
    setList({ ...list, [e.target.name]: value });
  };

  // const handleSelect = (e) => {
  //   let value = Array.from(e.target.selectedOptions, (option) => option.value);
  //   setList({ ...list, [e.target.name]: value });
  // };
  // const handleInputGenreChange = (value) => {
  //   const val = []
  //   for (let i = 0; i < value.length; i++) {
  //      val.push(value[i].value);
  //   }
  //     setList({ ...list, 'genre': val });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    createList(list, dispatch);
    history.push("/lists")
  };

  const handleInputChange = (value) => {
    const val = []
    for (let i = 0; i < value.length; i++) {
       val.push(value[i].value);
    }
    Promise.resolve(setList({ ...list, 'content': val }));

  };

  // const handleInputGenreChange = (value) => {
  //   // const gen = []
  //   // for (let i = 0; i < value.length; i++) {
  //     //  gen.push(value[i].value);
  //   // }
  //   setList({ ...list, 'genre': value.value });
  // };

  // const options = [
  //   { value: 'action', label: 'Action' },
  //   { value: 'comedy', label: 'Comedy' },
  //   { value: 'horror', label: 'Horror' },
  //   { value: 'crime', label: 'Crime' },
  // ]

  const contentVal =  movies.map((movie) => (
      {value:movie._id,label:movie.title}
  ))

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New List</h1>
      <form className="addProductForm">
        <div className="formLeft">
          <div className="addListItem">
            <label>Title</label>
            <input
              type="text"
              placeholder="Popular Movies"
              name="title"
              onChange={handleChange}
            />
          </div>
          {/* <div className="addListItem"> */}
          {/* <label>Genre</label> */}
          {/* <input
            required
              type="hidden"
              placeholder="Popular Movies"
              name="genre"
              value="comedy"
              // onChange={handleChange}
            /> */}
          {/* <Select name="genre" id="genre" 
            // onInputChange={handleInputChange} 
            options={options}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            onChange={handleInputGenreChange}
            /> */}
          {/* </div> */}
          <div className="addListItem">
            <label>Type</label>
            <select name="type" onChange={handleChange}>
              <option>Type</option>
              <option value="movie">Movie</option>
              <option value="series">Series</option>
              <option value="both">Both</option>
            </select>
          </div>
          <div className="addListItem">
          <label>Position</label>
          <input
            required
            type="number"
            placeholder="Position on home screen"
            name="position"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem">
        <label>View Type</label>
            <select name="view_type" onChange={handleChange}>
              <option>View Type</option>
              <option value="default">Default</option>
              <option value="ext_height">Extended Height</option>
              <option value="ext_width">Extended Width</option>
            </select>
        </div>
        </div>
        <div className="formRight">
          <div className="addListItem">
            <label>Content</label>
            <Select
            options={contentVal}
            isMulti
            closeMenuOnSelect={false}
            onChange={handleInputChange}
            style={{ height: "280px" }}
              
            >
            </Select>
          </div>
        </div>
        <div className="button_holder">
        <button className="addProductButton padding-20" onClick={handleSubmit}>
          Create
        </button>
        </div>
      </form>
    </div>
  );
}
