import axios from "axios";
import {
  createLiveChannelTypeFailure,
  createLiveChannelTypeStart,
  createLiveChannelTypeSuccess,
  deleteLiveChannelTypeFailure,
  deleteLiveChannelTypeStart,
  deleteLiveChannelTypeSuccess,
  getLiveChannelTypesFailure,
  getLiveChannelTypesStart,
  getLiveChannelTypesSuccess,
  getLiveChannelTypeFailure,
  getLiveChannelTypeStart,
  getLiveChannelTypeSuccess,
  updateLiveChannelTypeFailure,
  updateLiveChannelTypeStart,
  updateLiveChannelTypeSuccess,
} from "./LiveChannelTypeActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getLiveChannelTypes = async (dispatch) => {
  dispatch(getLiveChannelTypesStart());
  try {
    const res = await axios1.get("/liveChannelTypes", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getLiveChannelTypesSuccess(res.data));
  } catch (err) {
    dispatch(getLiveChannelTypesFailure());
  }
};

//create
export const createLiveChannelType = async (liveChannelType, dispatch) => {
  dispatch(createLiveChannelTypeStart());
  try {
    const res = await axios1.post("/liveChannelTypes", liveChannelType, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createLiveChannelTypeSuccess(res.data));
  } catch (err) {
    dispatch(createLiveChannelTypeFailure());
  }
};

//delete
export const deleteLiveChannelType = async (id, dispatch) => {
  dispatch(deleteLiveChannelTypeStart());
  try {
    await axios1.delete("/liveChannelTypes/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteLiveChannelTypeSuccess(id));
  } catch (err) {
    dispatch(deleteLiveChannelTypeFailure());
  }
};

export const getLiveChannelType = async (id, dispatch) => {
  dispatch(getLiveChannelTypeStart());
  try {
    const res = await axios1.get("/liveChannelTypes/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getLiveChannelTypeSuccess(res.data));
  } catch (err) {
    dispatch(getLiveChannelTypeFailure());
  }
};

export const getLiveChannelTypesList = async () => {
  try {
    const res = await axios1.get("/liveChannelTypes", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};

export const getLiveChannelTypeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/movies/liveChannelType/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getSliderLiveChannelTypeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/sliders/liveChannelType/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getLiveChannelTypeEpisodeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/episodes/liveChannelType/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getKidsLiveChannelTypeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/kids/liveChannelType/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getLiveChannelTypeForUpdate = async (id) => {
  try {
    const res = await axios1.get("/liveChannelTypes/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const updateLiveChannelType = async (id, liveChannelType, dispatch) => {
  dispatch(updateLiveChannelTypeStart());
  try {
    const res = await axios1.put("/liveChannelTypes/"+ id, liveChannelType, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateLiveChannelTypeSuccess(res.data));
  } catch (err) {
    dispatch(updateLiveChannelTypeFailure());
  }
};