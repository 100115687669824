import { useContext, useState } from "react";
import "./newPackaging.css";
import { createPackaging } from "../../context/packagingContext/apiCalls";
import { PackagingContext } from "../../context/packagingContext/PackagingContext";
import { useHistory } from "react-router-dom";


export default function NewPackaging() {
  const history = useHistory()
  const [packaging, setPackaging] = useState(null);

  const { dispatch } = useContext(PackagingContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setPackaging({ ...packaging, [e.target.name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    createPackaging(packaging, dispatch);
    history.push("/packagings")
  };

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Packaging</h1>
      <form className="addProductForm">
        <div className="addProductItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="John Wick"
            name="title"
            required="required"
            onChange={handleChange}
          />

          <label>URL</label>
          <input
            type="text"
            placeholder="https://player.vimeo.com"
            name="url"
            required="required"
            onChange={handleChange}
          />
          <button className="addProductPackButton" onClick={handleSubmit}>
            Create
          </button>
        </div>

      </form>
    </div>
  );
}
