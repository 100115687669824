import { useContext, useState } from "react";
import "./newSubNowVideo.css";
import { createSubNowVideo } from "../../context/subNowVideoContext/apiCalls";
import { SubNowVideoContext } from "../../context/subNowVideoContext/SubNowVideoContext";
import { useHistory } from "react-router-dom";
import {v4 as uuid} from "uuid";
import ReactS3Client from "../../s3";


export default function NewSubNowVideo() {
  const history = useHistory()
  const [packaging, setPackaging] = useState(null);
  const [img, setImg] = useState(null);
  const [uploaded, setUploaded] = useState(0);



  const { dispatch } = useContext(SubNowVideoContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setPackaging({ ...packaging, [e.target.name]: value });
  };

  const upload = (items) => {
    items.forEach((item) => {
      const fileName = new Date().getTime() + uuid();

      ReactS3Client
        .uploadFile(item.file,'subMovies/'+fileName)
        .then((data) => {
          setPackaging((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
                    setUploaded((prev) => prev + 1);
        })
        .catch(err => console.error(err))
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createSubNowVideo(packaging, dispatch);
    history.push("/subscribe-videos")
  };

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Subscribe Now Video</h1>
      <form className="addProductForm">
        <div className="input_fields">
          <div className="addContentItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="John Wick"
            name="title"
            required="required"
            onChange={handleChange}
          />
          </div>
          <div className="addContentItem">
          <label>URL</label>
          <input
            type="text"
            placeholder="https://player.vimeo.com"
            name="url"
            required="required"
            onChange={handleChange}
          />
          </div>
          <div className="addContentItem">
          <label>Short Description</label>
          <input
            required
            type="text"
            placeholder="Description"
            name="sort_desc"
            onChange={handleChange}
          />
          </div>
          <div className="addContentItem">
          <label>Position</label>
          <input
            required
            type="number"
            placeholder="Position on home screen"
            name="position"
            onChange={handleChange}
          />
          </div>

        </div>
        <div className="image_input">
        <div className="addContentItem">
          <label>Image</label>
          <input
            type="file"
            id="img"
            name="img"
            required="required"
            onChange={(e) => setImg(e.target.files[0])}
          />
        </div>
        </div>
        
         

          
          
        <div className="button_holder">
          {uploaded === 1 ? (
          <button className="addProductButton padding-20" onClick={handleSubmit}>
            Create
          </button>
        ) : (
          <button className="addProductButton padding-20" onClick={handleUpload}>
            Upload
          </button>
        )}
        </div>

      </form>
    </div>
  );
}
