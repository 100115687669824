import "./userList.css";
import { DataGrid, GridToolbarContainer } from "@material-ui/data-grid";
// import SearchBar from "material-ui-search-bar";
// import { DeleteOutline } from "@material-ui/icons";
// import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
// import { useState } from "react";
import { useContext, useEffect, useState,React } from "react";
import { UserContext } from "../../context/userContext/UserContext";
import axios from "axios";
import { Visibility } from "@material-ui/icons"; 
import { getUsers } from "../../context/userContext/apiCalls";
// import SyncIcon from '@mui/icons-material/Sync';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import Loader from "../../components/loader/Loader";


// const SUBMIT_FILTER_STROKE_TIME = 500;

// function InputNumberInterval(props) {
//   const { item, applyValue, focusElementRef = null } = props;

//   const filterTimeout = React.useRef();
//   const [filterValueState, setFilterValueState] = React.useState(item.value ?? '');

//   const [applying, setIsApplying] = React.useState(false);

//   React.useEffect(() => {
//     return () => {
//       clearTimeout(filterTimeout.current);
//     };
//   }, []);

//   React.useEffect(() => {
//     const itemValue = item.value ?? [undefined, undefined];
//     setFilterValueState(itemValue);
//   }, [item.value]);

//   const updateFilterValue = (lowerBound, upperBound) => {
//     clearTimeout(filterTimeout.current);
//     setFilterValueState([lowerBound, upperBound]);

//     setIsApplying(true);
//     filterTimeout.current = setTimeout(() => {
//       setIsApplying(false);
//       applyValue({ ...item, value: [lowerBound, upperBound] });
//     }, SUBMIT_FILTER_STROKE_TIME);
//   };

//   const handleUpperFilterChange = (event) => {
//     const newUpperBound = event.target.value;
//     updateFilterValue(filterValueState[0], newUpperBound);
//   };
//   const handleLowerFilterChange = (event) => {
//     const newLowerBound = event.target.value;
//     updateFilterValue(newLowerBound, filterValueState[1]);
//   };

//   return (
//     <Box
//       sx={{
//         display: 'inline-flex',
//         flexDirection: 'row',
//         alignItems: 'end',
//         height: 48,
//         pl: '20px',
//       }}
//     >
//       <TextField
//         name="lower-bound-input"
//         placeholder="From"
//         label="From"
//         variant="standard"
//         value={Number(filterValueState[0])}
//         onChange={handleLowerFilterChange}
//         type="number"
//         inputRef={focusElementRef}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         name="upper-bound-input"
//         placeholder="To"
//         label="To"
//         variant="standard"
//         value={Number(filterValueState[1])}
//         onChange={handleUpperFilterChange}
//         type="number"
//         InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
//       />
//     </Box>
//   );
// }

// const CustomToolbar = (props) => (
//   <div>
//     <GridToolbarContainer>
//     </GridToolbarContainer>
//     {/* <SearchBar {...props} /> */}
//   </div>
// );


export default function UserList() {
  // const [users, setData] = useState(userRows);
  const [totalUsers, setTotalUsers] = useState(0);
  const {isFetching, users, dispatch } = useContext(UserContext);
  const [pageState, setPageState] = useState(0);
  // const [searchText, setSearchText] = useState("");
  // const [tableData, setTableData] = useState(users);
  const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const getUsersCount = async () => {
      try {
        const res = await axios1.get("/users/count", {
          headers: {
            token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        });
        setTotalUsers(res.data[0].count+res.data[1].count+res.data[2].count);
      } catch (err) {
        // console.log(err);
      }
    };
    getUsersCount();
    // console.log(pageState)
    getUsers(dispatch,pageState);
  }, [dispatch, pageState]); 

  const columns = [
    { field: "_id",
    headerName: "SNo",
    width: 100,
    align:'center',
    renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 + (15*pageState),
    disableColumnMenu:true, 
  },
    {
      field: "user",
      headerName: "User",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* <img className="productListImg" src={params.row.img} alt="" /> */}
            {params.row.username}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 360 },
    {
      field: "location",
      headerName: "Location",
      width: 190,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* {console.log(JSON.parse(params.row.extra_metadata))} */}
            {params.row.extra_metadata.length > 0 ? JSON.parse(params.row.extra_metadata[0]).city + ',' + JSON.parse(params.row.extra_metadata[0]).country : "No location"}
          </div>
        );
      },
    },
    {
      field: "is_subscribed",
      headerName: "Subscribed",
      width: 145,
      valueFormatter: (params) => {
        let setSub = "";
        if (params.value) {
          setSub = "Subscribed";
        } else {
          setSub = "Unsubscribed";
        }
        return `${setSub}`;
      },
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 260,
      valueFormatter: (params) => {
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        let date = new Date(params.value);
        let formattedDate = date.toLocaleDateString('en-us',options);
        let formattedTime = date.toLocaleTimeString('en-us');
        return `${formattedTime + ', ' + formattedDate}`
      }
    },
    // { field: "updatedAt", headerName: "Updated Date", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
          {/* {console.log("uSER DETAILS",params.row)} */}
            <Link
              // to={{ pathname: "/user/" + params.row._id, user: params.row }}
              to={{ pathname: "/user/" + params.row._id, state: params.row }} 
            >
              <button className="widgetSmButton"><Visibility className="widgetSmIcon" /></button>
              {/* <button className="productListEdit">Edit</button> */}
            </Link>
            {/* <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            /> */}
          </>
        );
      },
    },
  ];

  // const requestSearch = (searchValue) => {
  //   const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
  //   const filteredRows = users.filter((o) => {
  //     return Object.keys(o).some((k) => {
  //       return searchRegex.test(o[k]);
  //     });
  //   });
  //   setTableData(filteredRows);
  // };

  // const cancelSearch = () => {
  //   setSearchText("");
  //   requestSearch(searchText);
  // };


  const handlePageChange = (value) => {
    setPageState(value)
  };

  return (
    <div className="userList">
      <h1 className="addProductTitle">User List</h1>
      <DataGrid
        // components={{ Toolbar : CustomToolbar}}
        sx={{ overflowX : "scroll"}}
        rows={users}
        columns={columns}
        // filterModel={{
        //   items: [
        //     { columnField: 'createdAt',
        //       operatorValue: 'contains', 
        //       value: '23' },
        //   ],
        // }}
        pagination
        pageSize={15}
        rowCount={totalUsers}
        paginationMode="server"
        onPageChange={handlePageChange}
        loading={isFetching}
        getRowId={(r) => r._id}
        // componentsProps={{
        //   toolbar: {
        //     value: searchText,
        //     onChange: (searchVal) => requestSearch(searchVal),
        //     onCancelSearch: () => cancelSearch()
        //   }
        // }}
      />
    </div>
  );
}
