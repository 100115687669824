export const getConfigsStart = () => ({
  type: "GET_CONFIGS_START",
});

export const getConfigsSuccess = (configs) => ({
  type: "GET_CONFIGS_SUCCESS",
  payload: configs,
});

export const getConfigsFailure = () => ({
  type: "GET_CONFIGS_FAILURE",
});

export const createConfigStart = () => ({
  type: "CREATE_CONFIG_START",
});

export const createConfigSuccess = (config) => ({
  type: "CREATE_CONFIG_SUCCESS",
  payload: config,
});

export const createConfigFailure = () => ({
  type: "CREATE_CONFIG_FAILURE",
});

export const updateConfigStart = () => ({
  type: "UPDATE_CONFIG_START",
});

export const updateConfigSuccess = (config) => ({
  type: "UPDATE_CONFIG_SUCCESS",
  payload: config,
});

export const updateConfigFailure = () => ({
  type: "UPDATE_CONFIG_FAILURE",
});

export const deleteConfigStart = () => ({
  type: "DELETE_CONFIG_START",
});

export const deleteConfigSuccess = (id) => ({
  type: "DELETE_CONFIG_SUCCESS",
  payload: id,
});

export const deleteConfigFailure = () => ({
  type: "DELETE_CONFIG_FAILURE",
});

export const getConfigStart = () => ({
  type: "GET_CONFIG_START",
});

export const getConfigSuccess = (config) => ({
  type: "GET_CONFIG_SUCCESS",
  payload: config,
});

export const getConfigFailure = () => ({
  type: "GET_CONFIG_FAILURE",
});
