import axios from "axios";
import {
  createInternalContestFailure,
  createInternalContestStart,
  createInternalContestSuccess,
  deleteInternalContestFailure,
  deleteInternalContestStart,
  deleteInternalContestSuccess,
  getInternalContestsFailure,
  getInternalContestsStart,
  getInternalContestsSuccess,
  getInternalContestFailure,
  getInternalContestStart,
  getInternalContestSuccess,
  updateInternalContestFailure,
  updateInternalContestStart,
  updateInternalContestSuccess,
} from "./InternalContestActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getInternalContests = async (dispatch) => {
  dispatch(getInternalContestsStart());
  try {
    const res = await axios1.get("/internalContests", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getInternalContestsSuccess(res.data));
  } catch (err) {
    dispatch(getInternalContestsFailure());
  }
};

//create
export const createInternalContest = async (packaging, dispatch) => {
  dispatch(createInternalContestStart());
  try {
    const res = await axios1.post("/internalContests", packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createInternalContestSuccess(res.data));
  } catch (err) {
    dispatch(createInternalContestFailure());
  }
};

//delete
export const deleteInternalContest = async (id, dispatch) => {
  dispatch(deleteInternalContestStart());
  try {
    await axios1.delete("/internalContests/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteInternalContestSuccess(id));
  } catch (err) {
    dispatch(deleteInternalContestFailure());
  }
};

export const getInternalContest = async (id, dispatch) => {
  dispatch(getInternalContestStart());
  try {
    const res = await axios1.get("/internalContests/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getInternalContestSuccess(res.data));
  } catch (err) {
    dispatch(getInternalContestFailure());
  }
};

export const getInternalContestsList = async () => {
  try {
    const res = await axios1.get("/internalContests", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    // getInternalContestsSuccess(res.data);
    return res.data
  } catch (err) {
    return err
  }
};

export const getPackageForUpdate = async (id) => {
  try {
    const res = await axios1.get("/internalContests/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const updateInternalContest = async (id, packaging, dispatch) => {
  dispatch(updateInternalContestStart());
  try {
    const res = await axios1.put("/internalContests/"+ id, packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateInternalContestSuccess(res.data));
  } catch (err) {
    dispatch(updateInternalContestFailure());
  }
};

export const getInternalContestsForMovies = async () => {
  try {
    const res = await axios1.get("/internalContests", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};

// export const getInternalContestDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/movies/internalContest/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getInternalContestEpisodeDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/episodes/internalContest/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getInternalContestKidsEpisodeDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/kidsepisodes/internalContest/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getInternalContestKidsDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/kids/internalContest/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };