import { Link, useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import "./genre.css";
import { GenreContext } from "../../context/genreContext/GenreContext";
import { getGenreForUpdate, updateGenre } from "../../context/genreContext/apiCalls";

export default function Genre() {
  const {genreId} = useParams();
  const {dispatch } = useContext(GenreContext);
  const [genre, setGenre] = useState([]);

  const history = useHistory()

  useEffect(() => {
    getGenreForUpdate(genreId).then(function (res){
      setGenre(res)
    })
  }, [genreId]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    updateGenre(genre._id,genre, dispatch);
    history.push("/genres")
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setGenre({ ...genre, [e.target.name]: value });
  };

  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Edit Genre</h1>
        <Link to="/newGenre">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <span className="productInfoKey">Title:</span>
            <span className="productName">{genre.name}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{genre._id}</span>
            </div>
          </div>
        </div>
        
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Genre Title</label>
            <input name="name" type="text" placeholder={genre.name} onChange={handleChange} />
        </div>
          <div className="productFormRight">
            <button className="productButton" onClick={handleSubmit}>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
