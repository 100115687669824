export const getEpisodesStart = () => ({
  type: "GET_SEASON_EPISODES_START",
});

export const getEpisodesSuccess = (episodes) => ({
  type: "GET_SEASON_EPISODES_SUCCESS",
  payload: episodes,
});

export const getEpisodesFailure = () => ({
  type: "GET_SEASON_EPISODES_FAILURE",
});

export const createEpisodeStart = () => ({
  type: "CREATE_SEASON_EPISODE_START",
});

export const createEpisodeSuccess = (episode) => ({
  type: "CREATE_SEASON_EPISODE_SUCCESS",
  payload: episode,
});

export const createEpisodeFailure = () => ({
  type: "CREATE_SEASON_EPISODE_FAILURE",
});

export const updateEpisodeStart = () => ({
  type: "UPDATE_SEASON_EPISODE_START",
});

export const updateEpisodeSuccess = (episode) => ({
  type: "UPDATE_SEASON_EPISODE_SUCCESS",
  payload: episode,
});

export const updateEpisodeFailure = () => ({
  type: "UPDATE_SEASON_EPISODE_FAILURE",
});

export const deleteEpisodeStart = () => ({
  type: "DELETE_SEASON_EPISODE_START",
});

export const deleteEpisodeSuccess = (id) => ({
  type: "DELETE_SEASON_EPISODE_SUCCESS",
  payload: id,
});

export const deleteEpisodeFailure = () => ({
  type: "DELETE_SEASON_EPISODE_FAILURE",
});

export const getEpisodeStart = () => ({
  type: "GET_SEASON_EPISODE_START",
});

export const getEpisodeSuccess = (episodes) => ({
  type: "GET_SEASON_EPISODE_SUCCESS",
  payload: episodes,
});

export const getEpisodeFailure = () => ({
  type: "GET_SEASON_EPISODE_FAILURE",
});

export const getSeasonsStart = () => ({
  type: "GET_SEASONS_START",
});

export const getSeasonsSuccess = (seasons) => ({
  type: "GET_SEASONS_SUCCESS",
  payload: seasons,
});

export const getSeasonsFailure = () => ({
  type: "GET_SEASONS_FAILURE",
});

export const getSeariesStart = () => ({
  type: "GET_SEARIES_START",
});

export const getSeariesSuccess = (series) => ({
  type: "GET_SEARIES_SUCCESS",
  payload: series,
});

export const getSeariesFailure = () => ({
  type: "GET_SEARIES_FAILURE",
});

