import { useContext, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import "./internalContest.css";
import { InternalContestContext } from '../../context/internalContestContext/InternalContestContext';
import { useEffect } from "react";
import { getPackageForUpdate, updateInternalContest } from "../../context/internalContestContext/apiCalls";
import { Toggle } from "../../components/toggleButton/toggleButton";



export default function InteralContest() {
  const { dispatch } = useContext(InternalContestContext);
  const [internalContest, setInteralContest] = useState([]);
  const { internalContestId } = useParams();
  const history = useHistory();


  useEffect(() => {
    getPackageForUpdate(internalContestId).then(function (res) {
      setInteralContest(res)
    })
  }, [internalContestId])


  const handleChange = (e) => {
    const value = e.target.value;
    setInteralContest({ ...internalContest, [e.target.name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    updateInternalContest(internalContest._id, internalContest, dispatch);
    history.push("/internal-contests")

    // console.log(rr)
  };

  // console.log(internalContest)

  const logStatusState = state => {
    // console.log("Status:", state)
  }

  const logContestStartStatus = state => {
    // console.log("Contest Start Status:",state)
  }
  



  return (

    <div className="product">
      {/* {console.log(internalContest)} */}
      <div className="productTitleContainer">
        <h1 className="productTitle">Edit Internal Contest</h1>
        <Link to="/new-internal-contest">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">Id:</span>
              <span className="productInfoValue">{internalContest?._id}</span>
            </div>
            <div className="">
              <span className="productInfoKey">Package Name:</span>
              <span className="productInfoValue">{internalContest?.title}</span>
            </div>
            <div className="">
              <span className="productInfoKey">URL:</span>
              <span className="productInfoValue">{internalContest?.url}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <div className="goLiveToggle contest_status">
              <div className="toggleLabel">Status </div>
              <div className="toggle">
                <Toggle label="" toggled={`${internalContest?.status}`} onClick={logStatusState}/>
              </div>
            </div>
            <div className="goLiveToggle contest_status">
              <div className="toggleLabel">Contest Start Status </div>
              <div className="toggle">
                <Toggle label="" toggled={false} onClick={logContestStartStatus}/>  {/*Value to be changed */}
              </div>
            </div>
            <label>Interal Contest Label</label>
            <input type="text" name="title" placeholder={internalContest?.title} onChange={handleChange} />         

            <label>Interal Contest Label</label>
            <input type="text" name="title" placeholder={internalContest?.title} onChange={handleChange} />
            <label>URL</label>
            <input type="text" name="url" className="urlField" placeholder={internalContest?.url} onChange={handleChange} />
            <label>Short Description</label>
            {/* <input type="text" name="url" className="short_desc" placeholder={internalContest?.sort_desc} onChange={handleChange}/> */}
            <textarea id="textarea" name="desc" rows="6" cols="55" placeholder={internalContest?.sort_desc} onChange={handleChange}></textarea>
            <label>Long Description</label>
            <textarea id="textarea" name="desc" rows="6" cols="55" placeholder={internalContest?.sort_desc} onChange={handleChange}></textarea>  {/*Value to be changed */}
            {/* <label>Status</label>
            <input type="text" name="url" className="status" value={internalContest?.status} onChange={handleChange}/> */}
          </div>
          <div className="productFormRight">
            <button className="productButton" onClick={handleSubmit}>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
