import { useContext, useEffect, useState } from "react";
import "./newKids.css";
// import storage from "../../firebase";
import ReactS3Client from "../../s3";
import { createKids } from "../../context/kidsContext/apiCalls";
import { KidsContext } from "../../context/kidsContext/KidsContext";
// import { GenreContext } from "../../context/genreContext/GenreContext";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import Select from 'react-select'
import { getGenresList } from "../../context/genreContext/apiCalls";
import { getPackagingsForMovies } from "../../context/packagingContext/apiCalls";



// const options = [
//   { value: 'action', label: 'Action' },
//   { value: 'comedy', label: 'Comedy' },
//   { value: 'horror', label: 'Horror' },
//   { value: 'crime', label: 'Crime' },
// ]


export default function NewKids() {
  const [kids, setKids] = useState(null);
  const [img, setImg] = useState(null);
  const [imgDimension, setImgDimensions] = useState({
    "image": { width: '', height: '', size: 0 },
    "title_image": { width: '', height: '', size: 0 },
    "thumbnail_image": { width: '', height: '', size: 0 },
  })
  const [imgTitle, setImgTitle] = useState(null);
  const [imgSm, setImgSm] = useState(null);
  const [options, setOptions] = useState([]);
  const [uploaded, setUploaded] = useState(0);
  const [packagingOptions, setPackagingOptions] = useState([]);

  const { dispatch } = useContext(KidsContext);
  // const { generDispatch } = useContext(GenreContext);
  const history = useHistory()

  useEffect(() => {
    getGenresList().then(function (gen) {
      const contentVal = gen.map((ge) => (
        { value: ge.name, label: ge.name }
      ))
      setOptions(contentVal)
      // console.log(gen)
    })

    getPackagingsForMovies().then(function (pac) {
      const packValue = pac?.map((pa) => (
        { value: pa.url, label: pa.title }
      ))

      setPackagingOptions(packValue)
    })

  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setKids({ ...kids, [e.target.name]: value });
  };

  const handlePackInputChange = (value) => {
    setKids({ ...kids, 'packaging': value.value });
  };

  const handleInputChange = (value) => {
    const val = []
    for (let i = 0; i < value.length; i++) {
      val.push(value[i].value);
    }
    setKids({ ...kids, 'genre': val });
  };

  const upload = (items) => {
    items.forEach((item) => {
      const fileName = new Date().getTime() + uuid();

      ReactS3Client
        .uploadFile(item.file, 'kidss/' + fileName)
        .then((data) => {
          setKids((prev) => {
            return { ...prev, [item.label]: data.location };
          });
          setUploaded((prev) => prev + 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
    });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      { file: imgTitle, label: "imgTitle" },
      { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createKids(kids, dispatch);
    history.push("/kidss")

  };

  const getImgDimensions = (str, target) => {
    let dim;
    let image = new Image()
    image.src = window.URL.createObjectURL(target)
    image.onload = () => {
      if (str === "img") {
        setImgDimensions(prev => ({ ...prev, image: { width: image.width, height: image.height, size: (target.size / 1024).toFixed(2) } }))
      }
      else if (str === "title_img") {
        setImgDimensions(prev => ({ ...prev, title_image: { width: image.width, height: image.height, size: (target.size / 1024).toFixed(2) } }))
      }
      else if (str === "thumb_img") {
        setImgDimensions(prev => ({ ...prev, thumbnail_image: { width: image.width, height: image.height, size: (target.size / 1024).toFixed(2) } }))
      }
    }
  }



  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Kids Content</h1>
      <form className="addProductForm">
        <div className="input_fields">
          <div className="addContentItem">
            <label>Title</label>
            <input
              type="text"
              placeholder="John Wick"
              name="title"
              required
              onChange={handleChange}
            />
          </div>
          <div className="addContentItem">
            <label>Description</label>
            <input
              required
              type="text"
              placeholder="Description"
              name="desc"
              onChange={handleChange}
            />
          </div>
          <div className="addContentItem">
            <label>Year</label>
            <input
              required
              type="text"
              placeholder="Year"
              name="year"
              onChange={handleChange}
            />
          </div>
          <div className="addContentItem">
            <label>Genre</label>
            <Select name="genre" id="genre"
              // onInputChange={handleInputChange} 
              options={options}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              onChange={handleInputChange}
            // value={selectedOption}
            // onInputChange={handleInputChange}
            />
          </div>
          <div className="addContentItem">
            <label>Packaging</label>
            <Select name="packaging" id="packaging"
              options={packagingOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={true}
              onChange={handlePackInputChange}
            />
          </div>
          <div className="inline_input_holder">
            <div className="addContentItem inline">
              <label>Skip Intro</label>
              <input
                required
                type="number"
                placeholder="Skip Intro in sec"
                name="skip_intro"
                onChange={handleChange}
              />
            </div>
            <div className="addContentItem inline">
              <label>Skip Intro End</label>
              <input
                required
                type="number"
                placeholder="Skip Intro end in sec"
                name="skip_intro_end"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="inline_input_holder">
            <div className="addContentItem inline">
              <label>Video Duration</label>
              <input
                required
                type="number"
                placeholder="Video duration in sec"
                name="video_duration"
                onChange={handleChange}
              />
            </div>
            <div className="addContentItem inline">
              <label>Limit</label>
              <input
                required
                type="text"
                placeholder="Limit"
                name="limit"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="inline_input_holder">

            <div className="addContentItem inline">
              <label>Type</label>
              <select name="type" id="type" onChange={handleChange}>
                <option value="">Select type</option>
                <option value="movie">Movie</option>
                <option value="series">Series</option>
              </select>
            </div>
            <div className="addContentItem inline">
              <label>Is VIP</label>
              <select name="vip" id="vip" onChange={handleChange}>
                <option value="">Select VIP </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>


        </div>
        <div className="image_input">
          <div className="addContentItem">
            <label>Trailer</label>
            <input
              required
              type="text"
              name="trailer"
              onChange={handleChange}
            />
          </div>
          <div className="addContentItem">
            <label>Video</label>
            <input
              required
              type="text"
              name="video"
              onChange={handleChange}
            />
          </div>

          <div className="addContentItem">
            <label>Image</label>
            <input
              type="file"
              accept="image/webp"
              id="img"
              name="img"
              required
              onChange={(e) => {
                setImg(e.target.files[0])
                getImgDimensions("img", e.target.files[0])
              }}
            />
            {imgDimension.image.width && imgDimension.image.height != undefined ? <p>Image dimensions : {`${imgDimension.image.width} x ${imgDimension.image.height}`}</p> : null}
            {imgDimension.image.size !== 0 ? <p>Image size : {`${imgDimension.image.size}`}KB</p> : null}
          </div>
          <div className="addContentItem">
            <label>Title image</label>
            <input
              required
              type="file"
              accept="image/webp"
              id="imgTitle"
              name="imgTitle"
              onChange={(e) => {
                setImgTitle(e.target.files[0])
                getImgDimensions("title_img", e.target.files[0])
              }}
            />
            {imgDimension.title_image.width && imgDimension.title_image.height != undefined ? <p>Image dimensions : {`${imgDimension.title_image.width} x ${imgDimension.title_image.height}`}</p> : null}
            {imgDimension.title_image.size !== 0 ? <p>Image size : {`${imgDimension.title_image.size}`}KB</p> : null}
          </div>
          <div className="addContentItem">
            <label>Thumbnail image</label>
            <input
              required
              type="file"
              accept="image/webp"
              id="imgSm"
              name="imgSm"
              onChange={(e) => {
                setImgSm(e.target.files[0])
                getImgDimensions("thumb_img", e.target.files[0])
              }}
            />
            {imgDimension.thumbnail_image.width && imgDimension.thumbnail_image.height != undefined ? <p>Image dimensions : {`${imgDimension.thumbnail_image.width} x ${imgDimension.thumbnail_image.height}`}</p> : null}
            {imgDimension.thumbnail_image.size !== 0 ? <p>Image size : {`${imgDimension.thumbnail_image.size}`}KB</p> : null}
          </div>

        </div>

        <div className="button_holder">

          {uploaded === 3 ? (
            <button className="addProductButton padding-20" onClick={handleSubmit}>
              Create
            </button>
          ) : (
            <button className="addProductButton padding-20" onClick={handleUpload}>
              Upload
            </button>
          )}
        </div>

      </form>
    </div>
  );
}
