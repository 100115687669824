export const getKidssStart = () => ({
  type: "GET_KIDSS_START",
});

export const getKidssSuccess = (kidss) => ({
  type: "GET_KIDSS_SUCCESS",
  payload: kidss,
});

export const getKidssFailure = () => ({
  type: "GET_KIDSS_FAILURE",
});

export const createKidsStart = () => ({
  type: "CREATE_KIDS_START",
});

export const createKidsSuccess = (kids) => ({
  type: "CREATE_KIDS_SUCCESS",
  payload: kids,
});

export const createKidsFailure = () => ({
  type: "CREATE_KIDS_FAILURE",
});

export const updateKidsStart = () => ({
  type: "UPDATE_KIDS_START",
});

export const updateKidsSuccess = (kids) => ({
  type: "UPDATE_KIDS_SUCCESS",
  payload: kids,
});

export const updateKidsFailure = () => ({
  type: "UPDATE_KIDS_FAILURE",
});

export const deleteKidsStart = () => ({
  type: "DELETE_KIDS_START",
});

export const deleteKidsSuccess = (id) => ({
  type: "DELETE_KIDS_SUCCESS",
  payload: id,
});

export const deleteKidsFailure = () => ({
  type: "DELETE_KIDS_FAILURE",
});

export const getKidsStart = () => ({
  type: "GET_KIDS_START",
});

export const getKidsSuccess = (kidss) => ({
  type: "GET_KIDS_SUCCESS",
  payload: kidss,
});

export const getKidsFailure = () => ({
  type: "GET_KIDS_FAILURE",
});
