import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "./movie.css";
import ReactS3Client from "../../s3";
import { MovieContext } from "../../context/movieContext/MovieContext";
import { getMovieForUpdate, updateMovie } from "../../context/movieContext/apiCalls";
import {v4 as uuid} from "uuid";
import Select from 'react-select'
import { getPackagings, getPackagingDataForUpdate } from "../../context/packagingContext/apiCalls";
import { getGenres, getGenreDataForUpdate } from "../../context/genreContext/apiCalls";
import { PackagingContext } from "../../context/packagingContext/PackagingContext";
import { GenreContext } from "../../context/genreContext/GenreContext"; 
import { Toggle } from "../../components/toggleButton/toggleButton";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// import axios from "axios";






export default function Movie() {
  // const location = useLocation();
  // const pageNo = location.pageNo;
  const {movieId} = useParams();
  const {dispatch } = useContext(MovieContext);
  // const [updateMovie, setUpdateMovie] = useState(movie);
  const [uploaded, setUploaded] = useState(0);
  const [movie, setMovie] = useState([]);
  const [imgDimension, setImgDimensions] = useState({
    "image": { width: '', height: '',size:0 },
    "title_image": { width: '', height: '',size:0},
    "thumbnail_image": { width: '', height: '',size:0 },
  })
  const [oldImgDimension,setOldImgDimension]= useState({
    "image":{ dim:''},
    "title_image":{ dim:''},
    "thumbnail_image":{ dim:''}
  })  
  const [img, setImg] = useState(null);
  const [imgTitle, setImgTitle] = useState(null);
  const [imgSm, setImgSm] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgTitleSrc, setImgTitleSrc] = useState(null);
  const [imgSmSrc, setImgSmSrc] = useState(null);
  const [goLiveStatus, setGoLiveStatus] = useState(false);
  // const [vip, setVip] = useState([]);
  const history = useHistory()
  const [packagingOptions, setPackagingOptions] = useState([]);
  const [genOption, setGenOptions] = useState([]);
  const { packagings, dispatch: dispatchPackaging } = useContext(PackagingContext);
  const { genres, dispatch: dispatchGenre } = useContext(GenreContext);

// console.log(pageNo)
localStorage.setItem("checkPreviousPage", "yes");
  
  useEffect(() => {
    
    getGenres(dispatchGenre);
    getPackagings(dispatchPackaging);

    getMovieForUpdate(movieId).then(function (res){
      // console.log(typeof res.go_live_status)
      setMovie(res)
      setImgSrc(res.img)
      setImgSmSrc(res.imgSm)
      setImgTitleSrc(res.imgTitle)
      setGoLiveStatus(res.go_live_status)
    })

    
    
    getPackagingDataForUpdate(movieId).then(function (pac){
      setPackagingOptions({value:pac[0]?.content_data?.url,label:pac[0]?.content_data?.title})
    })

    getGenreDataForUpdate(movieId).then(function (res){
      // console.log(res)
      const genVal = res.map((ge) => (
        {value:ge.content_data._id,label:ge.content_data.name}
      ))
      setGenOptions(genVal)
    })

    
  }, [dispatchGenre, dispatchPackaging, movieId]);
  
  const allGenreData =  genres.map((gen) => (
    {value:gen._id,label:gen.name}
))
 
  const allPackData =  packagings.map((pac) => (
    {value:pac.url,label:pac.title}
))

  const handleChange = (e) => {
    const value = e.target.value;
    setMovie({ ...movie, [e.target.name]: value });
  };


  const upload = async (items) => {
    items.forEach((item) => {
      // const fileName = new Date().getTime() + item.label + item.file.name;
      const fileName = new Date().getTime() + uuid();

      ReactS3Client
        .uploadFile(item.file,'movies/'+fileName)
        .then((data) => {
          setMovie((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
          setUploaded((prev) => prev - 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      { file: imgTitle, label: "imgTitle" },
      { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ])
  };

  const handleChangeImg = (e) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]))
    getImgDimensions("img",e.target.files[0])
    setImg(e.target.files[0])
    setUploaded((prev) => prev + 1);
  };
  
  const handleChangeImgSm = (e) => {
    setImgSmSrc(URL.createObjectURL(e.target.files[0]))
    getImgDimensions("thumb_img",e.target.files[0])
    setImgSm(e.target.files[0])
    setUploaded((prev) => prev + 1);
    
  };
  
  const handleChangeImgTitle = (e) => {
    setImgTitleSrc(URL.createObjectURL(e.target.files[0]))
    getImgDimensions("title_img",e.target.files[0])
    setImgTitle(e.target.files[0])
    setUploaded((prev) => prev + 1);

  };

  const vipValue = [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ]

  // const handleInputChange = (value) => {
  //   const val = []
  //   for (let i = 0; i < value.length; i++) {
  //      val.push(value[i].value);
  //   }
  //   setMovie({ ...movie, 'genre': val });
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    updateMovie(movie._id,movie, dispatch);
    history.push("/movies")
  };

  const handlePackInputChange = (value) => {
    // console.log(value)
    setMovie({ ...movie, 'packaging': value.value });
    setPackagingOptions(value)

};

const handleVipInputChange = (value) => {
  // console.log(value)
  setMovie({ ...movie, 'vip': value.value });
  // setVip(value)
};

const handleInputGenChange = (value) => {
  const val = []
  for (let i = 0; i < value.length; i++) {
         val.push(value[i].label);
      }
      Promise.resolve(setMovie({ ...movie, 'genre': val }));

  setGenOptions(value)
};

const oldImgDimensions = () =>{
  const oldImg = document.getElementById('image')
  const oldThumbImg = document.getElementById('thum_image')
  const oldTitleImg = document.getElementById('title_img')

  const oldImgDim = `${oldImg.naturalWidth}` + ' x ' + `${oldImg.naturalHeight}`;
  const oldThumImgDim = `${oldThumbImg.naturalWidth}` + ' x ' + `${oldThumbImg.naturalHeight}`;
  const oldTitleImgDim = `${oldTitleImg.naturalWidth}` + ' x ' + `${oldTitleImg.naturalHeight}`;

  // console.log(oldImgDim,oldThumImgDim,oldTitleImgDim)

  if(oldImgDimension.image.dim == ''){
    setOldImgDimension(prev => ({...prev, image:{ dim:oldImgDim}, title_image:{dim: oldTitleImgDim}, thumbnail_image:{dim: oldThumImgDim}}))
  }
}

const getImgDimensions = (str,target) => {
  let image = new Image()
  image.src = window.URL.createObjectURL(target)
  image.onload = () => {

    if(str === "img"){
      // console.log(oldImgHeight)
      setImgDimensions(prev => ({...prev, image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "title_img"){
      setImgDimensions(prev => ({...prev, title_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "thumb_img"){
      setImgDimensions(prev => ({...prev, thumbnail_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
  }
}

const logState = (event) => {
  const val = event.target.checked ? true : false;
  setMovie({ ...movie, "go_live_status": val });
  setGoLiveStatus(val)

}


  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Movie</h1>
        <Link to="/newMovie">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={movie.img} alt="" className="productInfoImg" />
            <span className="productName">{movie.title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">Id:</span>
              <span className="productInfoValue">{movie._id}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Genre:</span>
              <span className="productInfoValue">{movie.genre?.map((v)=>v+',')}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Year:</span>
              <span className="productInfoValue">{movie.year}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Audio Langauge:</span>
              <span className="productInfoValue">{movie.audio_langauge}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Limit:</span>
              <span className="productInfoValue">{movie.limit}</span>
            </div>
          </div>
        </div>
        <div className="productTopCorner">
          <div className="goLiveToggle">
            <div className="toggleLabel">Go Live - </div>
            <div className="toggle">
            <FormControlLabel
        control={
            <Switch 
            checked={goLiveStatus}
            onChange={logState}
            color="primary"
            name="go_live_status"
             />

        }
        />
            {/* <Toggle
            label=""
            toggled={goLiveStatus}
            onClick={logState}
        /> */}
        
            </div>
            
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Movie Title</label>
            <input type="text" name="title" placeholder={movie.title} onChange={handleChange}/>
            <label>Description</label>
            {/* <input type="text" name="desc" className="textarea"  placeholder={movie.desc} onChange={handleChange}/> */}
            <textarea id="textarea" name="desc" rows="6" cols="55" placeholder={movie.desc} onChange={handleChange}></textarea>
            <label>Year</label>
            <input type="text" name="year" placeholder={movie.year} onChange={handleChange}/>
            <label>Skip Intro(in seconds)</label>
            <input type="number" name="skip_intro" placeholder={movie.skip_intro} onChange={handleChange}/>
            <label>Skip Intro End(in seconds)</label>
            <input type="number" name="skip_intro_end" placeholder={movie.skip_intro_end} onChange={handleChange}/>
            <label>Video Duration(in seconds)</label>
            <input type="number" name="video_duration" placeholder={movie.video_duration} onChange={handleChange}/>
            <label>Limit</label>
            <input type="number" name="video_duration" placeholder={movie.limit} onChange={handleChange}/>

            {/* Adding SLider start and end date */}
            {/* <div className="addProductItem">
          <label>Slider Start Date-Time</label>
          <input
            type="datetime-local"
            placeholder="Slider Start Date-TIme"
            required="required"
            name="slider_start_date"
            onChange={handleChange}
          />
        </div> */}
        {/* <div className="addProductItem">
          <label>Slider End Date-Time</label>
          <input
            type="datetime-local"
            placeholder="Slider End Date-Time"
            required="required"
            name="slider_end_date"
            onChange={handleChange}
          />
        </div> */}

            <div className="addProductItem">
              <label>Vip</label>
              <Select name="vip" id="vip" 
                options={vipValue}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={handleVipInputChange}
                value={movie.vip?vipValue[0]:vipValue[1]}
              />
            </div>
            <div className="addProductItem">
              <label>Genre</label>
              <Select name="genre" id="genre" 
                options={allGenreData}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                onChange={handleInputGenChange}
                value={genOption}
                />
            </div>
             
              
            <div className="addProductItem">
              <label>Packaging</label>
              <Select name="packaging" id="packaging" 
                options={allPackData}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={handlePackInputChange}
                value={packagingOptions}
              />
            </div>
              <label>Trailer</label>
              <input type="text" name="trailer" placeholder={movie.trailer} className="urlField" onChange={handleChange}/>
              <label>Video</label>
              <input type="text" name="video"  placeholder={movie.video} className="urlField" onChange={handleChange}/>
            </div>
            <div className="productFormRight">
              <div className="productUpload">
              <div className="gen_btn" onClick={oldImgDimensions}>Show Image Details</div>
                <h3>Image</h3>
                {oldImgDimension.image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.image.dim}`}</p> : null}
                <img
                  id="image"
                  src={imgSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="file" accept="image/webp" name="img"  onChange={handleChangeImg}/>
                {imgDimension.image.width && imgDimension.image.height != undefined ? <p>Uploaded Image dimensions : {`${imgDimension.image.width} x ${imgDimension.image.height}`}</p> : null}
          {imgDimension.image.size !== 0 ? <p>Uploaded Image size : {`${imgDimension.image.size}`}KB</p> : null}
              </div>

              <div className="productUpload">
                <h3>Thumbnail Image</h3>
                {oldImgDimension.thumbnail_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.thumbnail_image.dim}`}</p> : null}
                <img
                  id="thum_image"
                  src={imgSmSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="imgSm" accept="image/webp" name="imgSm"  onChange={handleChangeImgSm}/>
                {imgDimension.thumbnail_image.width && imgDimension.thumbnail_image.height != undefined ? <p>Uploaded Image dimensions : {`${imgDimension.thumbnail_image.width} x ${imgDimension.thumbnail_image.height}`}</p> : null}
          {imgDimension.thumbnail_image.size !== 0 ? <p>Uploaded Image size : {`${imgDimension.thumbnail_image.size}`}KB</p> : null}
              </div>

              <div className="productUpload title_img">
                <h3>Title Image</h3>
                {oldImgDimension.title_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.title_image.dim}`}</p> : null}
                <img
                  id="title_img"
                  src={imgTitleSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="imgTitle" accept="image/webp" name="imgTitle"  onChange={handleChangeImgTitle}/>
                {imgDimension.title_image.width && imgDimension.title_image.height != undefined ? <p>Uploaded Image dimensions : {`${imgDimension.title_image.width} x ${imgDimension.title_image.height}`}</p> : null}
          {imgDimension.title_image.size !== 0 ? <p>Uploaded Image size : {`${imgDimension.title_image.size}`}KB</p> : null}
              </div>

            {uploaded === 0 ? (
            <button className="addProductButton" onClick={handleSubmit}>
              Update
            </button>
            ) : (
            <button className="addProductButton" onClick={handleUpload}>
              Confirm
            </button>
            )}

            {/* <button className="productButton">Update</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
