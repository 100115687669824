const InternalContestReducer = (state, action) => {
  switch (action.type) {
    case "GET_INTERNALCONTESTS_START":
      return {
        internalcontests: [],
        isFetching: true,
        error: false,
      };
    case "GET_INTERNALCONTESTS_SUCCESS":
      return {
        internalcontests: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_INTERNALCONTESTS_FAILURE":
      return {
        internalcontests: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_INTERNALCONTEST_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_INTERNALCONTEST_SUCCESS":
      return {
        internalcontests: [...state.internalcontests, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_INTERNALCONTEST_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_INTERNALCONTEST_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_INTERNALCONTEST_SUCCESS":
      return {
        internalcontests: state.internalcontests.map(
          (internalcontest) => internalcontest._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_INTERNALCONTEST_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_INTERNALCONTEST_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_INTERNALCONTEST_SUCCESS":
      return {
        internalcontests: state.internalcontests.filter((internalcontest) => internalcontest._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_INTERNALCONTEST_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_INTERNALCONTEST_START":
        return {
          internalcontest: {},
          isFetching: true,
          error: false,
        };
      case "GET_INTERNALCONTEST_SUCCESS":
        return {
          internalcontest: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_INTERNALCONTEST_FAILURE":
        return {
          internalcontest: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default InternalContestReducer;
