import axios from "axios";
import {
  createKidsFailure,
  createKidsStart,
  createKidsSuccess,
  deleteKidsFailure,
  deleteKidsStart,
  deleteKidsSuccess,
  getKidssFailure,
  getKidssStart,
  getKidssSuccess,
  getKidsFailure,
  getKidsStart,
  getKidsSuccess,
  updateKidsFailure,
  updateKidsStart,
  updateKidsSuccess,
} from "./KidsActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getKidss = async (dispatch) => {
  dispatch(getKidssStart());
  try {
    const res = await axios1.get("/kids", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidssSuccess(res.data));
  } catch (err) {
    dispatch(getKidssFailure());
  }
};

//create
export const createKids = async (kids, dispatch) => {
  dispatch(createKidsStart());
  try {
    const res = await axios1.post("/kids", kids, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createKidsSuccess(res.data));
  } catch (err) {
    dispatch(createKidsFailure());
  }
};

//delete
export const deleteKids = async (id, dispatch) => {
  dispatch(deleteKidsStart());
  try {
    await axios1.delete("/kids/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteKidsSuccess(id));
  } catch (err) {
    dispatch(deleteKidsFailure());
  }
};

export const getKids = async (id, dispatch) => {
  dispatch(getKidsStart());
  try {
    const res = await axios1.get("/kids/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getKidsSuccess(res.data));
    return res.data;
  } catch (err) {
    dispatch(getKidsFailure());
  }
};

//update

export const updateKids = async (id, kids, dispatch) => {
  dispatch(updateKidsStart());
  try {
    const res = await axios1.put("/kids/"+ id, kids, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateKidsSuccess(res.data));
  } catch (err) {
    dispatch(updateKidsFailure());
  }
};

export const getKidsForUpdate = async (id) => {
  try {
    const res = await axios1.get("/kids/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    // console.log(err)
    return err
  }
};
