import axios from "axios";
import {
  createMovieFailure,
  createMovieStart,
  createMovieSuccess,
  deleteMovieFailure,
  deleteMovieStart,
  deleteMovieSuccess,
  getMoviesFailure,
  getMoviesStart,
  getMoviesSuccess,
  getMovieFailure,
  getMovieStart,
  getMovieSuccess,
  updateMovieFailure,
  updateMovieStart,
  updateMovieSuccess,
  getWatchHistoryListStart,
  getWatchHistoryListSuccess,
  getWatchHistoryListFailure
} from "./MovieActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getMovies = async (dispatch) => {
  dispatch(getMoviesStart());
  try {
    const res = await axios1.get("/movies", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getMoviesSuccess(res.data));
  } catch (err) {
    dispatch(getMoviesFailure());
  }
};

//create
export const createMovie = async (movie, dispatch) => {
  dispatch(createMovieStart());
  try {
    const res = await axios1.post("/movies", movie, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createMovieSuccess(res.data));
  } catch (err) {
    dispatch(createMovieFailure());
  }
};

//delete
export const deleteMovie = async (id, dispatch) => {
  dispatch(deleteMovieStart());
  try {
    await axios1.delete("/movies/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteMovieSuccess(id));
  } catch (err) {
    dispatch(deleteMovieFailure());
  }
};

export const getMovie = async (id, dispatch) => {
  dispatch(getMovieStart());
  try {
    const res = await axios1.get("/movies/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getMovieSuccess(res.data));
    return res.data;
  } catch (err) {
    dispatch(getMovieFailure());
  }
};

//update

export const updateMovie = async (id, movie, dispatch) => {
  dispatch(updateMovieStart());
  try {
    const res = await axios1.put("/movies/"+ id, movie, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateMovieSuccess(res.data));
  } catch (err) {
    dispatch(updateMovieFailure());
  }
};

export const getContentsList = async () => {
  try {
    const res = await axios1.get("/movies", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};


export const getMovieForUpdate = async (id) => {
  try {
    const res = await axios1.get("/movies/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getWatchHistoryList = async (dispatch) => {
  dispatch(getWatchHistoryListStart());
  try {
    const res = await axios1.get("/v1/get/watchHistory/data", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getWatchHistoryListSuccess(res.data));
  } catch (err) {
    dispatch(getWatchHistoryListFailure());
  }
};

export const getWatchHistoryListByLocation = async (location,dispatch) => {
  dispatch(getWatchHistoryListStart());
  try {
    const res = await axios1.get("/v1/get/watchHistory/data/location/" + location, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getWatchHistoryListSuccess(res.data));
  } catch (err) {
    dispatch(getWatchHistoryListFailure());
  }
};
