import { useContext,useEffect, useState } from "react";
import { Link, useHistory,useParams } from "react-router-dom";
import "./liveChannel.css";
import ReactS3Client from "../../s3";
import { LiveChannelContext } from "../../context/liveChannelContext/LiveChannelContext";
import { updateLiveChannel,getLiveChannelForUpdate } from "../../context/liveChannelContext/apiCalls";
import { getLiveChannelTypesList } from "../../context/liveChannelTypeContext/apiCalls";
import Select from 'react-select'


export default function LiveChannel() {
  const {liveChannelId} = useParams();
  const [slider, setSlider] = useState([]);
  const { dispatch } = useContext(LiveChannelContext);
  const [uploaded, setUploaded] = useState(0);
  const [options, setOptions] = useState([]);
  const [img, setImg] = useState(slider.img);
  const [genValue, setGenValue] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgDimension, setImgDimensions] = useState({
    "image": { width: '', height: '',size:0 },
  })
  const [oldImgDimension,setOldImgDimension]= useState({
    "image":{ dim:''},
  })  

  const history = useHistory()

  useEffect(() => {
    getLiveChannelTypesList().then(function (gen) {
      const contentVal = gen.map((ge) => (
        { value: ge.name, label: ge.name }
      ))
      setOptions(contentVal)
    })

    getLiveChannelForUpdate(liveChannelId).then(function (res){
      setSlider(res)
      setImgSrc(res.img)
      setGenValue({ value: res.type, label: res.type })
    })
    
  }, [liveChannelId]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSlider({ ...slider, [e.target.name]: value });
  };

  const handleInputChange = (value) => {
    setGenValue({ value: value.value, label: value.value })
    setSlider({ ...slider, 'type': value.value });
  };

  const upload = (items) => {
    items.forEach((item) => {

      ReactS3Client
        .uploadFile(item.file)
        .then((data) => {
          setSlider((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
                    setUploaded((prev) => prev - 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
    ]);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    updateLiveChannel(slider._id,slider, dispatch);
    history.push("/live-channels")
    // history.goBack();
  };

const handleChangeImg = (e) => {
  setImgSrc(URL.createObjectURL(e.target.files[0]))
  getImgDimensions("img",e.target.files[0])
  setImg(e.target.files[0])
  setUploaded((prev) => prev + 1);
  
};

const getImgDimensions = (str,target) => {
  let image = new Image()
  image.src = window.URL.createObjectURL(target)
  image.onload = () => {

    if(str === "img"){
      // const oldImg = document.getElementById('image')
      setImgDimensions(prev => ({...prev, image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "title_img"){
      setImgDimensions(prev => ({...prev, title_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "thumb_img"){
      setImgDimensions(prev => ({...prev, thumbnail_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
  }
}


  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Edit Live Channel</h1>
        <Link to="/newSlider">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={slider.img} alt="" className="productInfoImg" />
            <span className="productName">{slider.title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{slider._id}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">Title:</span>
              <span className="productInfoValue">{slider.title}</span>
            </div>
            
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Title</label>
            <input name="title" type="text" placeholder={slider.title} onChange={handleChange}/>
            {/* <label>Description</label> */}
            {/* <input name="desc" type="text" placeholder={slider.desc} onChange={handleChange}/> */}
            {/* <textarea id="textarea" name="desc" rows="6" cols="55" placeholder={slider.desc} onChange={handleChange}></textarea> */}
            <label>URL</label>
            <input name="url" type="text" placeholder={slider.url} onChange={handleChange}/>
            <label>Position</label>
            <input name="position" type="text" placeholder={slider.position} onChange={handleChange}/>
            <div className="addProductItem">
            <label>Genre</label>
              <Select name="genre" id="genre"
                // onInputChange={handleInputChange} 
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={handleInputChange}
                value={genValue}
            
              />
                </div>
          </div>
          <div className="productFormRight">
              <div className="productUpload">
              {/* <div className="gen_btn" onClick={oldImgDimensions}>Show Image Details</div> */}
              <h3>Image</h3>
              {oldImgDimension.image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.image.dim}`}</p> : null}
                <img
                id="image"
                  src={imgSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="file" accept="image/webp" name="img"  onChange={handleChangeImg}/>
                {imgDimension.image.width && imgDimension.image.height != undefined ? <p>Image dimensions : {`${imgDimension.image.width} x ${imgDimension.image.height}`}</p> : null}
          {imgDimension.image.size !== 0 ? <p>Image size : {`${imgDimension.image.size}`}KB</p> : null}
              </div>


              {uploaded === 0 ? (
            <button className="addProductButton" onClick={handleSubmit}>
              Update
            </button>
            ) : (
            <button className="addProductButton" onClick={handleUpload}>
              Upload
            </button>
            )}

            {/* <button className="productButton">Update</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
