export const getInternalContestsStart = () => ({
  type: "GET_INTERNALCONTESTS_START",
});

export const getInternalContestsSuccess = (internalContestss) => ({
  type: "GET_INTERNALCONTESTS_SUCCESS",
  payload: internalContestss,
});

export const getInternalContestsFailure = () => ({
  type: "GET_INTERNALCONTESTS_FAILURE",
});

export const createInternalContestStart = () => ({
  type: "CREATE_INTERNALCONTEST_START",
});

export const createInternalContestSuccess = (internalContests) => ({
  type: "CREATE_INTERNALCONTEST_SUCCESS",
  payload: internalContests,
});

export const createInternalContestFailure = () => ({
  type: "CREATE_INTERNALCONTEST_FAILURE",
});

export const updateInternalContestStart = () => ({
  type: "UPDATE_INTERNALCONTEST_START",
});

export const updateInternalContestSuccess = (internalContests) => ({
  type: "UPDATE_INTERNALCONTEST_SUCCESS",
  payload: internalContests,
});

export const updateInternalContestFailure = () => ({
  type: "UPDATE_INTERNALCONTEST_FAILURE",
});

export const deleteInternalContestStart = () => ({
  type: "DELETE_INTERNALCONTEST_START",
});

export const deleteInternalContestSuccess = (id) => ({
  type: "DELETE_INTERNALCONTEST_SUCCESS",
  payload: id,
});

export const deleteInternalContestFailure = () => ({
  type: "DELETE_INTERNALCONTEST_FAILURE",
});

export const getInternalContestStart = () => ({
  type: "GET_INTERNALCONTEST_START",
});

export const getInternalContestSuccess = (internalContests) => ({
  type: "GET_INTERNALCONTEST_SUCCESS",
  payload: internalContests,
});

export const getInternalContestFailure = () => ({
  type: "GET_INTERNALCONTEST_FAILURE",
});
