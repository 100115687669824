import "./episodeList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { EpisodeContext } from "../../context/episodeContext/EpisodeContext";
import { deleteEpisode, getEpisodes } from "../../context/episodeContext/apiCalls";
// import Loader from "../../components/loader/Loader";

export default function EpisodeList() {
  const { isFetching,episodes, dispatch } = useContext(EpisodeContext);
  const [pageState, setPageState] = useState(0);
  var previousPgaeNo = localStorage.getItem("episodePageNo")


  useEffect(() => {
    if(localStorage.getItem("checkPreviousPage")==="yes"){
      setPageState(previousPgaeNo)
      localStorage.setItem("checkPreviousPage", "no");
    } 
    // console.log(pageState)
    getEpisodes(dispatch);
  }, [dispatch, previousPgaeNo]);

  // console.log(episodes);
  const handleDelete = (id) => {
    deleteEpisode(id, dispatch);
  };

  const handlePageChange = (value) => {
    localStorage.setItem("episodePageNo",value)
    setPageState(value)
  };

  const columns = [
    { field: "_id", headerName: "SNo", width: 84,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true, },
    {
      field: "episode",
      headerName: "Episode",
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
            {/* {params.row.title} */}
          </div>
        );
      },
    },
    { field: "title", headerName: "Title", width: 350 },
    { field: "genre", headerName: "Genre", width: 120 },
    { field: "year", headerName: "Year", width: 120 },
    { field: "limit", headerName: "Limit", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/episode/" + params.row._id, episode: params.row }}
            >
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <div className="productListHeading">
    <h1 className="addProductTitle" >Episode List</h1>
    <div className="productListHeadingR">
    <h2 className="productNumber">No of records : {episodes.length}</h2>
    <Link to="/newEpisode">
      <button className="productAddButton">Create</button>
    </Link>
    </div>
  </div>

      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rowHeight={90}
        rows={episodes}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        page={pageState}
        onPageChange={handlePageChange}
        // checkboxSelection
        // filterMode="server"
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
