export const getGenresStart = () => ({
  type: "GET_GENRES_START",
});

export const getGenresSuccess = (genres) => ({
  type: "GET_GENRES_SUCCESS",
  payload: genres,
});

export const getGenresFailure = () => ({
  type: "GET_GENRES_FAILURE",
});

export const createGenreStart = () => ({
  type: "CREATE_GENRE_START",
});

export const createGenreSuccess = (genre) => ({
  type: "CREATE_GENRE_SUCCESS",
  payload: genre,
});

export const createGenreFailure = () => ({
  type: "CREATE_GENRE_FAILURE",
});

export const updateGenreStart = () => ({
  type: "UPDATE_GENRE_START",
});

export const updateGenreSuccess = (genre) => ({
  type: "UPDATE_GENRE_SUCCESS",
  payload: genre,
});

export const updateGenreFailure = () => ({
  type: "UPDATE_GENRE_FAILURE",
});

export const deleteGenreStart = () => ({
  type: "DELETE_GENRE_START",
});

export const deleteGenreSuccess = (id) => ({
  type: "DELETE_GENRE_SUCCESS",
  payload: id,
});

export const deleteGenreFailure = () => ({
  type: "DELETE_GENRE_FAILURE",
});

export const getGenreStart = () => ({
  type: "GET_GENRE_START",
});

export const getGenreSuccess = (genre) => ({
  type: "GET_GENRE_SUCCESS",
  payload: genre,
});

export const getGenreFailure = () => ({
  type: "GET_GENRE_FAILURE",
});
