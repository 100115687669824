import axios from "axios";
import {
  createUserFailure,
  createUserStart,
  createUserSuccess,
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  getUsersFailure,
  getUsersStart,
  getUsersSuccess,
  getUserFailure,
  getUserStart,
  getUserSuccess,
} from "./UserActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getUsers = async (dispatch,page) => {
  dispatch(getUsersStart());
  try {
    const res = await axios1.get("/users?page="+ page, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getUsersSuccess(res.data));
  } catch (err) {
    dispatch(getUsersFailure());
  }
};

//create
export const createUser = async (user, dispatch) => {
  dispatch(createUserStart());
  try {
    const res = await axios1.post("/users", user, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createUserSuccess(res.data));
  } catch (err) {
    dispatch(createUserFailure());
  }
};

//delete
export const deleteUser = async (id, dispatch) => {
  dispatch(deleteUserStart());
  try {
    await axios1.delete("/users/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteUserSuccess(id));
  } catch (err) {
    dispatch(deleteUserFailure());
  }
};

export const getUser = async (id, dispatch) => {
  dispatch(getUserStart());
  try {
    const res = await axios1.get("/users/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getUserSuccess(res.data));
  } catch (err) {
    dispatch(getUserFailure());
  }
};

export const getSubsUsers = async (dispatch) => {
  dispatch(getUsersStart());
  try {
    const res = await axios1.get("/users/subscribed", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getUsersSuccess(res.data));
  } catch (err) {
    dispatch(getUsersFailure());
  }
};

export const getActiveSubUsers = async(dispatch) =>{
  dispatch(getUsersStart());
  try{
    const res = await axios1.get("/users/subscribed",{
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    const getActiveUsersList = [];
    res.data.map(user => {
      if(new Date(user.subscription_end_date).getTime() > new Date().getTime()){
        getActiveUsersList.push(user)
      }
    })
    dispatch(getUsersSuccess(getActiveUsersList))
  } catch(err){
    dispatch(getUsersFailure());
  }
};

export const getSubEndUsers = async(dispatch) =>{
  dispatch(getUsersStart());
  try{
    const {data} = await axios1.get("/users/subExp",{
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    console.log(data)
    // const getEndedUsersList = [];
    // res.data.map(user => {
    //   if(new Date(user.subscription_end_date).getTime() < new Date().getTime()){
    //     getEndedUsersList.push(user)
    //   }
    // })
    dispatch(getUsersSuccess(data))
  } catch(err){
    dispatch(getUsersFailure());
  }
};