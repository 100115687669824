import { useContext, useState } from "react";
import "./newGenre.css";
import { createGenre } from "../../context/genreContext/apiCalls";
import { GenreContext } from "../../context/genreContext/GenreContext";
import { useHistory } from "react-router-dom";


export default function NewGenre() {
  const history = useHistory()
  const [genre, setGenre] = useState(null);
  // const [img, setImg] = useState(null);
  // const [imgTitle, setImgTitle] = useState(null);
  // const [imgSm, setImgSm] = useState(null);
  // const [trailer, setTrailer] = useState(null);
  // const [video, setVideo] = useState(null);
  // const [uploaded, setUploaded] = useState(0);

  const { dispatch } = useContext(GenreContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setGenre({ ...genre, [e.target.name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    createGenre(genre, dispatch);
    history.push("/genres")
  };

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Genre</h1>
      <form className="addProductForm">
        <div className="addProductItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="John Wick"
            name="name"
            required
            onChange={handleChange}
          />
        </div>
          <button className="addProductButton" onClick={handleSubmit}>
            Create
          </button>
      </form>
    </div>
  );
}
