import { Link, useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import "./list.css";
import { ListContext } from "../../context/listContext/ListContext";
import { getListForUpdate, updateList, getListDataForUpdate } from "../../context/listContext/apiCalls";
import { getMovies } from "../../context/movieContext/apiCalls";
import Select from 'react-select'
import { MovieContext } from "../../context/movieContext/MovieContext";


export default function List() {
  // const location = useLocation();
  // const list = location.list;

  const {listId} = useParams();
  const {dispatch } = useContext(ListContext);
  // const [updateMovie, setUpdateMovie] = useState(movie);
  // const [uploaded, setUploaded] = useState(0);
  const [list, setList] = useState([]);
  // const [contentData, setContentData] = useState([]);
  const [contentVal, setContentVal] = useState([]);
  const { movies, dispatch: dispatchMovie } = useContext(MovieContext);

  // const [img, setImg] = useState(null);
  // const [title, setTitle] = useState(movie.title);
  // const [options, setOptions] = useState([]);
  const history = useHistory()
  


  useEffect(() => {
    
    getMovies(dispatchMovie);

    getListForUpdate(listId).then(function (res){
      setList(res)
    })

    getListDataForUpdate(listId).then(function (res){
      // setContentData(res)
      const contentVal = res.map((ge) => (
        {value:ge.content_data._id,label:ge.content_data.title}
      ))
      setContentVal(contentVal)
    })


  }, [dispatchMovie, listId]);


  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   setList({ ...list, [e.target.name]: value });
  // };

  // const selectedGenValues = () => {
  //   const test = options.filter(item => !genre.includes(item));
  //   console.log(test);
  // }

  // const upload = async (items) => {
  //   items.forEach((item) => {
  //     // const fileName = new Date().getTime() + item.label + item.file.name;
  //     const fileName = new Date().getTime() + uuid();

  //     ReactS3Client
  //       .uploadFile(item.file,'lists/'+fileName)
  //       .then((data) => {
  //         setList((prev) => {
  //                     return { ...prev, [item.label]: data.location };
  //                   });
  //         setUploaded((prev) => prev - 1);
  //         console.log(data)
  //       })
  //       .catch(err => console.error(err))
  //     });
  // };

  // const handleUpload = (e) => {
  //   e.preventDefault();
  //   upload([
  //     { file: img, label: "img" },
  //     // { file: imgTitle, label: "imgTitle" },
  //     // { file: imgSm, label: "imgSm" },
  //     // { file: trailer, label: "trailer" },
  //     // { file: video, label: "video" },
  //   ])
  // };

  // const handleChangeImg = (e) => {
  //   setImg(e.target.files[0])
  //   setUploaded((prev) => prev + 1);

  // }

  // const handleInputChange = (value) => {
  //   const val = []
  //   for (let i = 0; i < value.length; i++) {
  //      val.push(value[i].value);
  //   }
  //   setMovie({ ...movie, 'genre': val });
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    updateList(list._id,list, dispatch);
    history.push("/lists")
  };

   const allData =  movies.map((movie) => (
      {value:movie._id,label:movie.title}
  ))

  const handleInputContentChange = (value) => {
    const val = []
    // console.log(value)
    for (let i = 0; i < value.length; i++) {
       val.push(value[i].value);
    }
    Promise.resolve(setList({ ...list, 'content': val }));

    setContentVal(value)

  };

  const handleChange = (e) => {
    const value = e.target.value;
    setList({ ...list, [e.target.name]: value });
  };

  const typeValues = [
    { value: 'both', label: 'Both' },
    { value: 'movie', label: 'Movie' },
    { value: 'series', label: 'Series' },
  ]

  const viewTypeValues = [
    { value: 'default', label: 'Default' },
    { value: 'ext_height', label: 'Extended Height' },
    { value: 'ext_width', label: 'Extended Width' },
  ]

  const handleTypeInputChange = (value) => {
    // console.log(value)
    setList({ ...list, 'type': value.value });
    // setVip(value)
  };

  const handleViewTypeInputChange = (value) => {
    // console.log(value)
    setList({ ...list, 'view_type': value.value });
    // setVip(value)
  };

  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">List</h1>
        <Link to="/newList">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <span className="productName">{list.title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{list._id}</span>
            </div>
            {/* <div className="productInfoItem">
              <span className="productInfoKey">genre:</span>
              <span className="productInfoValue">{list.genre}</span>
            </div> */}
            <div className="productInfoItem">
              <span className="productInfoKey">type:</span>
              <span className="productInfoValue">{list.type}</span>
            </div>
          </div>
        </div>
        
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>List Title</label>
            <input name="title" type="text" placeholder={list.title} onChange={handleChange} />
            <div className="addProductItem">
                  <label>Type</label>
                  <Select name="type" id="type" 
                    options={typeValues}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={handleTypeInputChange}
                    value={(list.type==='movie')?typeValues[1]:(list.type==='series')?typeValues[2]:typeValues[0]}
                  />
                </div>
            <label>Position</label>
            <input name="position" type="number" placeholder={list.position} onChange={handleChange} />

            <div className="addProductItem">
                  <label>View Type</label>
                  <Select name="view_type" id="view_type" 
                    options={viewTypeValues}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={handleViewTypeInputChange}
                    value={(list.view_type==='ext_height')?viewTypeValues[1]:(list.view_type==='ext_width')?viewTypeValues[2]:viewTypeValues[0]}
                  />
                </div>
          </div>
          <div className="formRight">
          <div className="addProductItem">
            <label>Content</label>
            <Select name="genre" id="genre" 
            options={allData}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            value={contentVal}
            onChange={handleInputContentChange}
            />
          </div>
        </div>
          <div className="productFormRight">
            <button className="productButton" onClick={handleSubmit}>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
