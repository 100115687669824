import "./configList.css";
import { DataGrid } from "@material-ui/data-grid";
// import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
// import { PackagingContext } from "../../context/packagingContext/PackagingContext";
import { ConfigContext } from "../../context/configContext/ConfigContext";
// import { getPackagings } from "../../context/packagingContext/apiCalls";
import { getConfigs } from "../../context/configContext/apiCalls";
import Loader from "../../components/loader/Loader";
// import analytics from "../../firebase";
import { FirebaseDynamicLinks } from 'firebase-dynamic-links';

export default function ConfigList() {
  const {isFetching, configs, dispatch } = useContext(ConfigContext);




  
  useEffect(() => {
    getConfigs(dispatch);
    
    
    // const firebaseDynamicLinks = new FirebaseDynamicLinks('AIzaSyA5quCE2Ed3sPKWYmHquChfdYu-Z8yLF-I');
    
    // const { shortLink, previewLink }= firebaseDynamicLinks.createLink({
    //   // dynamicLinkInfo: {
    //   //   domainUriPrefix: 'https://flockshare.page.link',
    //   //   link: 'https://flockshare.page.link/',
    //   //   androidInfo: {
    //   //     androidPackageName: 'flock.media.entertaintment',
    //   //   },
    //   //   iosInfo: {
    //   //     iosBundleId: 'flock.media.entertaintment',
    //   //   },
    //   // },
    //   longDynamicLink: 'https://flockshare.page.link/?link=https://flockshare.page.link/&apn=flock.media.entertaintment&ibi=flock.media.entertaintment',
    // }).then((data) =>{
    //   console.log(data);
    // } )
    
  }, [dispatch]);
  
  // const handleDelete = (id) => {
  //   deletePackaging(id, dispatch);
  // };

  const columns = [
    { field: "_id", headerName: "SNo", width: 84,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true, },
    {
      field: "congig",
      headerName: "Config Name",
      flex:1,
      width: 200,
      renderCell: (params) => {
        return (
          <div className="packagingListItem">
            {params.row.config_type}
          </div>
        );
      },
    },
    { field: "value", headerName: "Value", width: 200 },
    { field: "mandatory_update", headerName: "Mandatory Update", width: 200 },
    { field: "release_note", headerName: "Release Note", width: 200 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Link
    //           to={{ pathname: "/packaging/" + params.row._id, packaging: params.row,id: params.row._id }}
    //         >
    //           <button className="packagingListEdit">Edit</button>
    //         </Link>
    //         {/* <DeleteOutline
    //           className="packagingListDelete"
    //           onClick={() => handleDelete(params.row._id)}
    //         /> */}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <div className="packagingList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Config List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {configs.length}</h2>
        {/* <Link to="/newSlider">
          <button className="productAddButton">Create</button>
        </Link> */}
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={configs}
        loading={isFetching}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
