import axios from "axios";
import {
  createConfigFailure,
  createConfigStart,
  createConfigSuccess,
  deleteConfigFailure,
  deleteConfigStart,
  deleteConfigSuccess,
  getConfigsFailure,
  getConfigsStart,
  getConfigsSuccess,
  getConfigFailure,
  getConfigStart,
  getConfigSuccess,
  updateConfigFailure,
  updateConfigStart,
  updateConfigSuccess,
} from "./ConfigActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getConfigs = async (dispatch) => {
  dispatch(getConfigsStart());
  try {
    const res = await axios1.get("/configs", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getConfigsSuccess(res.data));
    return res.data
  } catch (err) {
    dispatch(getConfigsFailure());
  }
};

//create
export const createConfig = async (config, dispatch) => {
  dispatch(createConfigStart());
  try {
    const res = await axios1.post("/configs", config, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createConfigSuccess(res.data));
  } catch (err) {
    dispatch(createConfigFailure());
  }
};

//delete
export const deleteConfig = async (id, dispatch) => {
  dispatch(deleteConfigStart());
  try {
    await axios1.delete("/configs/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteConfigSuccess(id));
  } catch (err) {
    dispatch(deleteConfigFailure());
  }
};

export const getConfig = async (id, dispatch) => {
  dispatch(getConfigStart());
  try {
    const res = await axios1.get("/configs/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getConfigSuccess(res.data));
  } catch (err) {
    dispatch(getConfigFailure());
  }
};

export const getConfigsList = async () => {
  try {
    const res = await axios1.get("/configs", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    // getConfigsSuccess(res.data);
    return res.data
  } catch (err) {
    return err
  }
};

export const getPackageForUpdate = async (id) => {
  try {
    const res = await axios1.get("/configs/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const updateConfig = async (id, config, dispatch) => {
  dispatch(updateConfigStart());
  try {
    const res = await axios1.put("/configs/"+ id, config, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateConfigSuccess(res.data));
    return res.data;
  } catch (err) {
    dispatch(updateConfigFailure());
  }
};

export const getConfigsForMovies = async () => {
  try {
    const res = await axios1.get("/configs", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};

export const getConfigDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/movies/config/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getConfigEpisodeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/episodes/config/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getConfigKidsEpisodeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/kidsepisodes/config/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getConfigKidsDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/kids/config/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};
