import "./seasonEpisodeList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SeasonEpisodeContext } from "../../context/seasonEpisodeContext/SeasonEpisodeContext";
import { deleteEpisode, getEpisodes } from "../../context/seasonEpisodeContext/apiCalls";
// import Loader from "../../components/loader/Loader";

export default function SeasonEpisodeList() {
  const {isFetching, episodes,dispatch } = useContext(SeasonEpisodeContext);
  const {seasonId,seriesId} = useParams()
  const [pageState, setPageState] = useState(0);
  var previousPgaeNo = localStorage.getItem("episodePageNo")



  // localStorage.setItem("checkPreviousPage", "yes");

  useEffect(() => {
    if(localStorage.getItem("checkPreviousPage")==="yes"){
      setPageState(previousPgaeNo)
      localStorage.setItem("checkPreviousPage", "no");
    } 
    getEpisodes(seriesId,seasonId,dispatch);
  }, [dispatch, seasonId, seriesId, previousPgaeNo]);

  // console.log(episodes);
  const handleDelete = (id) => {
    deleteEpisode(id, dispatch);
  };

  const handlePageChange = (value) => {
    localStorage.setItem("episodePageNo",value)
    setPageState(value)
  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 90, filterable: false },
    { field: "_id",
      headerName: "SNo",
      width: 86,
      align:'center',
      renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
      disableColumnMenu:true, 
    },
    {
      field: "episode",
      headerName: "Episode",
      width: 200,
      align:"center",
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
          </div>
        );
      },
    },
    { field: "title", headerName: "Title",flex:1, width: 120 },
    { field: "genre", headerName: "Genre", width: 120 },
    { field: "year", headerName: "Year", width: 120 },
    { field: "limit", headerName: "Limit", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/episode/" + params.row._id, episode: params.row }}
            >
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <div className="productListHeading">
      <h1 className="addProductTitle">Episode List</h1>
      <div className="productListHeadingR">
      <Link
              to={{ pathname: "/movies" }}
            >
              <button className="productListEdit">Go to content list</button>
            </Link>
        
        </div>
        </div>
      
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rowHeight={90}
        rows={episodes}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        rowCount={episodes.length}
        page={pageState}
        onPageChange={handlePageChange}
        // checkboxSelection
        loading={isFetching}
        // filterMode="server"
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
