import "./kidsList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { KidsContext } from "../../context/kidsContext/KidsContext";
import { deleteKids, getKidss } from "../../context/kidsContext/apiCalls";
import Loader from "../../components/loader/Loader";

export default function KidsList() {
  const {isFetching, kidss, dispatch } = useContext(KidsContext);

  useEffect(() => {
    getKidss(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deleteKids(id, dispatch);
  };

  const columns = [
    { field: "_id", headerName: "SNo", width: 84,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true, },
    {
      field: "kids",
      headerName: "Kids",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
          </div>
        );
      },
    },
    { field: "title", headerName: "Title",flex:1, width: 120 },
    { field: "genre", headerName: "Genre", width: 120 },
    { field: "year", headerName: "Year", width: 120 },
    { field: "limit", headerName: "Limit", width: 120 },
    { field: "type", headerName: "Type", width: 120 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/kids/" + params.row._id, kids: params.row ,id: params.row._id}}
            >
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Kids Content List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {kidss.length}</h2>
        <Link to="/newKids">
          <button className="productAddButton">Create</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rowHeight={120}
        rows={kidss}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
