const SubNowVideoReducer = (state, action) => {
  switch (action.type) {
    case "GET_SUBNOWVIDEOS_START":
      return {
        subnowvideos: [],
        isFetching: true,
        error: false,
      };
    case "GET_SUBNOWVIDEOS_SUCCESS":
      return {
        subnowvideos: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_SUBNOWVIDEOS_FAILURE":
      return {
        subnowvideos: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_SUBNOWVIDEO_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_SUBNOWVIDEO_SUCCESS":
      return {
        subnowvideos: [...state.subnowvideos, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_SUBNOWVIDEO_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_SUBNOWVIDEO_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_SUBNOWVIDEO_SUCCESS":
      return {
        subnowvideos: state.subnowvideos.map(
          (subnowvideo) => subnowvideo._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_SUBNOWVIDEO_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_SUBNOWVIDEO_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_SUBNOWVIDEO_SUCCESS":
      return {
        subnowvideos: state.subnowvideos.filter((subnowvideo) => subnowvideo._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_SUBNOWVIDEO_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_SUBNOWVIDEO_START":
        return {
          subnowvideo: {},
          isFetching: true,
          error: false,
        };
      case "GET_SUBNOWVIDEO_SUCCESS":
        return {
          subnowvideo: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_SUBNOWVIDEO_FAILURE":
        return {
          subnowvideo: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default SubNowVideoReducer;
