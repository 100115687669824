import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "./episode.css";
// import { Publish } from "@material-ui/icons";
import ReactS3Client from "../../s3";
import { EpisodeContext } from "../../context/episodeContext/EpisodeContext";
import { getEpisodeForUpdate, updateEpisode } from "../../context/episodeContext/apiCalls";
import {v4 as uuid} from "uuid";
import Select from 'react-select'
import { getPackagings, getPackagingEpisodeDataForUpdate } from "../../context/packagingContext/apiCalls";
// import { getGenres, getGenreEpisodeDataForUpdate } from "../../context/genreContext/apiCalls";
import { PackagingContext } from "../../context/packagingContext/PackagingContext";
// import { GenreContext } from "../../context/genreContext/GenreContext";



export default function Episode() {
  const {episodeId} = useParams();
  const {dispatch } = useContext(EpisodeContext);
  const [uploaded, setUploaded] = useState(0);
  const [episode, setEpisode] = useState([]);
  const [img, setImg] = useState(null);
  // const [vip, setVip] = useState([]);
  // const [imgTitle, setImgTitle] = useState(null);
  // const [imgSm, setImgSm] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  // const [imgTitleSrc, setImgTitleSrc] = useState(null);
  // const [imgSmSrc, setImgSmSrc] = useState(null);
  const history = useHistory()
  const [packagingOptions, setPackagingOptions] = useState([]);
  // const [genOption, setGenOptions] = useState([]);
  const { packagings, dispatch: dispatchPackaging } = useContext(PackagingContext);
  // const { genres, dispatch: dispatchGenre } = useContext(GenreContext);
  const [imgDimension, setImgDimensions] = useState({
    "prev_image": { width: '', height: '',size:0,oldWidth:0,oldHeight:0 }
  })
  const [oldImgDimension,setOldImgDimension]= useState({
    "prev_image":{ dim:''},
  })

  
  localStorage.setItem("checkPreviousPage", "yes");

  useEffect(() => {
    getEpisodeForUpdate(episodeId).then(function (res){
      setEpisode(res)
      setImgSrc(res.img)
      // setImgSmSrc(res.imgSm)
      // setImgTitleSrc(res.imgTitle)
    })
    // getGenres(dispatchGenre);
    getPackagings(dispatchPackaging);

    getPackagingEpisodeDataForUpdate(episodeId).then(function (pac){
      setPackagingOptions({value:pac[0]?.content_data?.url,label:pac[0]?.content_data?.title})
    })

    // getGenreEpisodeDataForUpdate(episodeId).then(function (res){
    //   console.log(res);
    //   // const genVal = res.map((ge) => (
    //   //   {value:ge.content_data._id,label:ge.content_data.name}
    //   // ))
    //   // setGenOptions(genVal)
    // })

  }, [dispatchPackaging, episodeId]);

//   const allGenreData =  genres.map((gen) => (
//     {value:gen._id,label:gen.name}
// ))

const allPackData =  packagings.map((pac) => (
  {value:pac.url,label:pac.title}
))

  const handleChange = (e) => {
    const value = e.target.value;
    setEpisode({ ...episode, [e.target.name]: value });
  };

  // const selectedGenValues = () => {
  //   const test = options.filter(item => !genre.includes(item));
  //   console.log(test);
  // }

  const upload = async (items) => {
    items.forEach((item) => {
      // const fileName = new Date().getTime() + item.label + item.file.name;
      const fileName = new Date().getTime() + uuid();

      ReactS3Client
        .uploadFile(item.file,'episode/'+fileName)
        .then((data) => {
          setEpisode((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
          setUploaded((prev) => prev - 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      // { file: imgTitle, label: "imgTitle" },
      // { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ])
  };

  const handleChangeImg = (e) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]))
    getImgDimensions("prev_img",e.target.files[0])
    setImg(e.target.files[0])
    setUploaded((prev) => prev + 1);
    
  };
  
  // const handleChangeImgSm = (e) => {
  //   setImgSmSrc(URL.createObjectURL(e.target.files[0]))
  //   setImgSm(e.target.files[0])
  //   setUploaded((prev) => prev + 1);
    
  // };
  
  // const handleChangeImgTitle = (e) => {
  //   setImgTitleSrc(URL.createObjectURL(e.target.files[0]))
  //   setImgTitle(e.target.files[0])
  //   setUploaded((prev) => prev + 1);

  // };

  // const handleInputChange = (value) => {
  //   const val = []
  //   for (let i = 0; i < value.length; i++) {
  //      val.push(value[i].value);
  //   }
  //   setMovie({ ...movie, 'genre': val });
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    updateEpisode(episode._id,episode, dispatch);
    // history.push("/episodes")
    history.goBack()
  };

  const handlePackInputChange = (value) => {
    setEpisode({ ...episode, 'packaging': value.value });
    setPackagingOptions(value)
  };

  // const handleInputGenChange = (value) => {
  //   const val = []
  //   for (let i = 0; i < value.length; i++) {
  //          val.push(value[i].label);
  //       }
  //       Promise.resolve(setEpisode({ ...episode, 'genre': val }));
  
  //   setGenOptions(value)
  
  // };

  const vipValue = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ]

  const handleVipInputChange = (value) => {
    // console.log(value)
    setEpisode({ ...episode, 'vip': value.value });
    // setVip(value)
  };

  const getImgDimensions = (str,target) => {
    let image = new Image()
    image.src = window.URL.createObjectURL(target)
    image.onload = () => {
  
      if(str === "prev_img"){
        // const oldImg = document.getElementById('image')
        setImgDimensions(prev => ({...prev, prev_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
    }
  }

  const oldImgDimensions = () =>{
    const oldPrevImg = document.getElementById('prev_image')

  
    const oldPrevImgDim = `${oldPrevImg.naturalWidth}` + ' x ' + `${oldPrevImg.naturalHeight}`;

  
    if(oldImgDimension.prev_image.dim == ''){
      setOldImgDimension(prev => ({...prev, prev_image:{ dim:oldPrevImgDim}}))
    }
  }


  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Episode Edit</h1>
        <Link to="/newEpisode">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={episode.img} alt="" className="productInfoImg" />
            <span className="productName">{episode.title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{episode._id}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">year:</span>
              <span className="productInfoValue">{episode.year}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">limit:</span>
              <span className="productInfoValue">{episode.limit}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Episode Title</label>
            <input type="text" name="title" placeholder={episode.title} onChange={handleChange}/>
            <label>Description</label>
            {/* <input type="textbox" name="desc" className=""  placeholder={episode.desc} onChange={handleChange}/> */}
            <textarea id="textarea" name="desc" rows="6" cols="55" placeholder={episode.desc} onChange={handleChange}></textarea>

            <label>Year</label>
            <input type="text" name="year" placeholder={episode.year} onChange={handleChange}/>
            <label>Skip Intro(in seconds)</label>
            <input type="number" name="skip_intro" placeholder={episode.skip_intro} onChange={handleChange}/>
            <label>Skip Intro End(in seconds)</label>
            <input type="number" name="skip_intro_end" placeholder={episode.skip_intro_end} onChange={handleChange}/>
            <label>Video Duration(in seconds)</label>
            <input type="number" name="video_duration" placeholder={episode.video_duration} onChange={handleChange}/>
            <label>Next Episode Play</label>
            <input type="number" name="next_ep_play" placeholder={episode.next_ep_play} onChange={handleChange}/>
            <label>Limit</label>
            <input type="text" name="limit"  placeholder={episode.limit} onChange={handleChange}/>
            {/* <div className="addProductItem">
              <label>Genre</label>
              <Select name="genre" id="genre" 
                options={allGenreData}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                onChange={handleInputGenChange}
                value={genOption}
                />
            </div> */}

            <div className="addProductItem">
                  <label>Vip</label>
                  <Select name="vip" id="vip" 
                    options={vipValue}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={handleVipInputChange}
                    value={episode.vip?vipValue[0]:vipValue[1]}
                  />
                </div>
              
            <div className="addProductItem">
              <label>Packaging</label>
              <Select name="packaging" id="packaging" 
                options={allPackData}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={handlePackInputChange}
                value={packagingOptions}
              />
            </div>
            <label>Video</label>
            <input type="text" name="video"  placeholder={episode.video} className="urlField" onChange={handleChange}/>
          </div>
          <div className="productFormRight">
              <div className="productUpload">
            <div className="gen_btn" onClick={oldImgDimensions}>Show Image Details</div>
            {oldImgDimension.prev_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.prev_image.dim}`}</p> : null}
                <h3>Preview Image</h3>
                <img
                id="prev_image"
                  src={imgSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="file" name="img" accept="image/webp" onChange={handleChangeImg}/>
                {imgDimension.prev_image.width && imgDimension.prev_image.height != undefined ? <p>Image dimensions : {`${imgDimension.prev_image.width} x ${imgDimension.prev_image.height}`}</p> : null}
          {imgDimension.prev_image.size !== 0 ? <p>Image size : {`${imgDimension.prev_image.size}`}KB</p> : null}
              </div>

              {/* <div className="productUpload">
                <img
                  src={imgSmSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                </label>
                <input type="file" id="imgSm" name="imgSm"  onChange={handleChangeImgSm}/>
              </div>

              <div className="productUpload">
                <img
                  src={imgTitleSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                </label>
                <input type="file" id="imgTitle" name="imgTitle"  onChange={handleChangeImgTitle}/>
              </div> */}

              {uploaded === 0 ? (
            <button className="addProductButton" onClick={handleSubmit}>
              Update
            </button>
            ) : (
            <button className="addProductButton" onClick={handleUpload}>
              Upload
            </button>
            )}

            {/* <button className="productButton">Update</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
