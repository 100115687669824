import "./internalContestList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { InternalContestContext } from "../../context/internalContestContext/InternalContestContext";
import { deleteInternalContest, getInternalContests } from "../../context/internalContestContext/apiCalls";
import { Visibility } from "@material-ui/icons";

export default function InternalContestList() {
  const {isFetching, internalcontests, dispatch } = useContext(InternalContestContext);

  useEffect(() => {
    getInternalContests(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deleteInternalContest(id, dispatch);
  };

  const columns = [
    { field: "_id", headerName: "SNo", width: 84,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true,  },
    {
      field: "packaging",
      headerName: "Internal Contest Name",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="packagingListItem">
            {params.row.title}
          </div>
        );
      },
    },
    { field: "url", headerName: "URL", width: 700 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      filterable:false,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/internal-contest/" + params.row._id, internalContest: params.row,id: params.row._id }}
            >
              <button className="packagingListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="packagingListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="packagingList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Internal Contest List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {internalcontests.length}</h2>
        <Link to="/new-internal-contest">
          <button className="productAddButton">Create</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
      sx={{ overflowX : 'scroll'}}
        rows={internalcontests}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
