export const getKidsEpisodesStart = () => ({
  type: "GET_KIDSEPISODES_START",
});

export const getKidsEpisodesSuccess = (kidsepisodes) => ({
  type: "GET_KIDSEPISODES_SUCCESS",
  payload: kidsepisodes,
});

export const getKidsEpisodesFailure = () => ({
  type: "GET_KIDSEPISODES_FAILURE",
});

export const createKidsEpisodeStart = () => ({
  type: "CREATE_KIDSEPISODE_START",
});

export const createKidsEpisodeSuccess = (kidsepisode) => ({
  type: "CREATE_KIDSEPISODE_SUCCESS",
  payload: kidsepisode,
});

export const createKidsEpisodeFailure = () => ({
  type: "CREATE_KIDSEPISODE_FAILURE",
});

export const updateKidsEpisodeStart = () => ({
  type: "UPDATE_KIDSEPISODE_START",
});

export const updateKidsEpisodeSuccess = (kidsepisode) => ({
  type: "UPDATE_KIDSEPISODE_SUCCESS",
  payload: kidsepisode,
});

export const updateKidsEpisodeFailure = () => ({
  type: "UPDATE_KIDSEPISODE_FAILURE",
});

export const deleteKidsEpisodeStart = () => ({
  type: "DELETE_KIDSEPISODE_START",
});

export const deleteKidsEpisodeSuccess = (id) => ({
  type: "DELETE_KIDSEPISODE_SUCCESS",
  payload: id,
});

export const deleteKidsEpisodeFailure = () => ({
  type: "DELETE_KIDSEPISODE_FAILURE",
});

export const getKidsEpisodeStart = () => ({
  type: "GET_KIDSEPISODE_START",
});

export const getKidsEpisodeSuccess = (kidsepisodes) => ({
  type: "GET_KIDSEPISODE_SUCCESS",
  payload: kidsepisodes,
});

export const getKidsEpisodeFailure = () => ({
  type: "GET_KIDSEPISODE_FAILURE",
});

export const getKidsSeasonsStart = () => ({
  type: "GET_KIDSSEASONS_START",
});

export const getKidsSeasonsSuccess = (kidsseasons) => ({
  type: "GET_KIDSSEASONS_SUCCESS",
  payload: kidsseasons,
});

export const getKidsSeasonsFailure = () => ({
  type: "GET_KIDSSEASONS_FAILURE",
});

export const getKidsSeriesStart = () => ({
  type: "GET_KIDSSERIESS_START",
});

export const getKidsSeriesSuccess = (kidsseries) => ({
  type: "GET_KIDSSERIESS_SUCCESS",
  payload: kidsseries,
});

export const getKidsSeriesFailure = () => ({
  type: "GET_KIDSSERIESS_FAILURE",
});