const LiveChannelReducer = (state, action) => {
  switch (action.type) {
    case "GET_LIVECHANNELS_START":
      return {
        liveChannels: [],
        isFetching: true,
        error: false,
      };
    case "GET_LIVECHANNELS_SUCCESS":
      return {
        liveChannels: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_LIVECHANNELS_FAILURE":
      return {
        liveChannels: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_LIVECHANNEL_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_LIVECHANNEL_SUCCESS":
      return {
        liveChannels: [...state.liveChannels, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_LIVECHANNEL_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_LIVECHANNEL_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_LIVECHANNEL_SUCCESS":
      return {
        liveChannels: state.liveChannels.map(
          (liveChannel) => liveChannel._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_LIVECHANNEL_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_LIVECHANNEL_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_LIVECHANNEL_SUCCESS":
      return {
        liveChannels: state.liveChannels.filter((liveChannel) => liveChannel._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_LIVECHANNEL_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_LIVECHANNEL_START":
        return {
          liveChannel: {},
          isFetching: true,
          error: false,
        };
      case "GET_LIVECHANNEL_SUCCESS":
        return {
          liveChannel: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_LIVECHANNEL_FAILURE":
        return {
          liveChannel: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default LiveChannelReducer;
