export const getLiveChannelTypesStart = () => ({
  type: "GET_LIVECHANNELTYPES_START",
});

export const getLiveChannelTypesSuccess = (liveChannelTypes) => ({
  type: "GET_LIVECHANNELTYPES_SUCCESS",
  payload: liveChannelTypes,
});

export const getLiveChannelTypesFailure = () => ({
  type: "GET_LIVECHANNELTYPES_FAILURE",
});

export const createLiveChannelTypeStart = () => ({
  type: "CREATE_LIVECHANNELTYPE_START",
});

export const createLiveChannelTypeSuccess = (liveChannelType) => ({
  type: "CREATE_LIVECHANNELTYPE_SUCCESS",
  payload: liveChannelType,
});

export const createLiveChannelTypeFailure = () => ({
  type: "CREATE_LIVECHANNELTYPE_FAILURE",
});

export const updateLiveChannelTypeStart = () => ({
  type: "UPDATE_LIVECHANNELTYPE_START",
});

export const updateLiveChannelTypeSuccess = (liveChannelType) => ({
  type: "UPDATE_LIVECHANNELTYPE_SUCCESS",
  payload: liveChannelType,
});

export const updateLiveChannelTypeFailure = () => ({
  type: "UPDATE_LIVECHANNELTYPE_FAILURE",
});

export const deleteLiveChannelTypeStart = () => ({
  type: "DELETE_LIVECHANNELTYPE_START",
});

export const deleteLiveChannelTypeSuccess = (id) => ({
  type: "DELETE_LIVECHANNELTYPE_SUCCESS",
  payload: id,
});

export const deleteLiveChannelTypeFailure = () => ({
  type: "DELETE_LIVECHANNELTYPE_FAILURE",
});

export const getLiveChannelTypeStart = () => ({
  type: "GET_LIVECHANNELTYPE_START",
});

export const getLiveChannelTypeSuccess = (liveChannelType) => ({
  type: "GET_LIVECHANNELTYPE_SUCCESS",
  payload: liveChannelType,
});

export const getLiveChannelTypeFailure = () => ({
  type: "GET_LIVECHANNELTYPE_FAILURE",
});
