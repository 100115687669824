import EpisodeReducer from "./EpisodeReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  episodes: [],
  series: [],
  seasons: [],
  seasonEpisodes: [],
  isFetching: false,
  error: false,
};

export const EpisodeContext = createContext(INITIAL_STATE);

export const EpisodeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EpisodeReducer, INITIAL_STATE);

  return (
    <EpisodeContext.Provider
      value={{
        episodes: state.episodes,
        series: state.series,
        seasons: state.seasons,
        seasonEpisodes: state.seasonEpisodes,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </EpisodeContext.Provider>
  );
};
