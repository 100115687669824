import {React,useContext,useEffect,useState} from "react";
import "./topbar.css";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AuthContext } from "../../context/authContext/AuthContext";
import { logout } from "../../context/authContext/AuthActions";
import { ConfigContext } from "../../context/configContext/ConfigContext";
import { getConfigs, updateConfig } from "../../context/configContext/apiCalls";

export default function Topbar() {
  const { dispatch } = useContext(AuthContext);
  const {dispatch: configsDispatch } = useContext(ConfigContext);
  const [guestLoginData, setGuestLoginData] = useState([]);
  const [geoLocationData, setGeoLocation] = useState([]);


  const [state, setState] = useState({
    guest_login: true,
    geo_location_lock: true
  });

  useEffect(() => {
    getConfigs(configsDispatch).then(function (res){
      // console.log(res)
      if (res) {
            // console.log(res)
            const guestLoginConfig = res.find(c => c.config_type === "guest_login");
            const geoLocationLockConfig = res.find(c => c.config_type === "geo_location_lock");
            if (guestLoginConfig) {
              setState(prevState => ({ ...prevState, guest_login: guestLoginConfig.value === "true" ? true:false }));
              setGuestLoginData(guestLoginConfig)
            }
      
            if (geoLocationLockConfig) {
              setState(prevState => ({ ...prevState, geo_location_lock: geoLocationLockConfig.value === "true"? true:false}));
              setGeoLocation(geoLocationLockConfig)

            }
          }
    })
  }, [configsDispatch]);

  // console.log(configs)

  const handleChange = (event) => {
    if (event.target.name === 'guest_login') {
      const updatedGuestLoginData = {
        ...guestLoginData,
        value: event.target.checked ? "true" : "false",
      };
  
      setGuestLoginData(updatedGuestLoginData);
  
      updateConfig(updatedGuestLoginData._id, updatedGuestLoginData, configsDispatch)
        .then((res) => {
          // console.log(res);
          setState(prevState => ({ ...prevState, guest_login: res.value === "true" ? true : false }));
        });
    } else {
      const updatedGeoLocationData = {
        ...geoLocationData,
        value: event.target.checked ? "true" : "false",
      };
  
      setGuestLoginData(updatedGeoLocationData);
  
      updateConfig(updatedGeoLocationData._id, updatedGeoLocationData, configsDispatch)
        .then((res) => {
          // console.log(res);
          setState(prevState => ({ ...prevState, geo_location_lock: res.value === "true" ? true : false }));
        });
    }
  };


  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <span className="logo">Flock Admin</span>
        </div>
        <div className="topRight">
        <div className="topbarIconContainer">
        <FormControlLabel
        label="Guest Login"
        control={
            <Switch
            checked={state.guest_login}
            onChange={handleChange}
            color="primary"
            name="guest_login"
             />

        }
        />
<FormControlLabel
        label="Geo Location Lock"
        control={
              <Switch
            checked={state.geo_location_lock}
            onChange={handleChange}
            color="primary"
            name="geo_location_lock"
             />
        }
        />
        <span onClick={() => dispatch(logout())}>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
}
