import "./seasonList.css";
import { DataGrid } from "@material-ui/data-grid";
import { Visibility } from "@material-ui/icons";
import { Link, useParams, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { EpisodeContext } from "../../context/episodeContext/EpisodeContext";
import { getSeasons } from "../../context/episodeContext/apiCalls";
// import Loader from "../../components/loader/Loader";


export default function SeasonList() {
  const {isFetching, seasons, dispatch } = useContext(EpisodeContext);
  const {seriesId} = useParams();
  const location = useLocation();
  const series = location.state;

  useEffect(() => {
    if (seriesId) {
      getSeasons(seriesId,dispatch);
    }
  }, [dispatch, seriesId]);

  const columns = [
    {
      field: "season",
      headerName: "Season",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row._id.season_id}
          </div>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/seasonEpisode/episodes/list/" + seriesId +"/"+ params.row._id.season_id, series: params.row }}
            >
              <button className="widgetSmButton"><Visibility className="widgetSmIcon" /></button>
            </Link>
          </>
        );
      },
    },
  ];


  return (
    <div className="productList">
      <h1 className="addProductTitle">Season List ({series.title})</h1>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={seasons}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        checkboxSelection={false}
        getRowId={(r) => r.items[0]._id}
      />
  {/* } */}
    </div>
  );
}
