import axios from "axios";
import {
  createPackagingFailure,
  createPackagingStart,
  createPackagingSuccess,
  deletePackagingFailure,
  deletePackagingStart,
  deletePackagingSuccess,
  getPackagingsFailure,
  getPackagingsStart,
  getPackagingsSuccess,
  getPackagingFailure,
  getPackagingStart,
  getPackagingSuccess,
  updatePackagingFailure,
  updatePackagingStart,
  updatePackagingSuccess,
} from "./PackagingActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getPackagings = async (dispatch) => {
  dispatch(getPackagingsStart());
  try {
    const res = await axios1.get("/packagings", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getPackagingsSuccess(res.data));
  } catch (err) {
    dispatch(getPackagingsFailure());
  }
};

//create
export const createPackaging = async (packaging, dispatch) => {
  dispatch(createPackagingStart());
  try {
    const res = await axios1.post("/packagings", packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createPackagingSuccess(res.data));
  } catch (err) {
    dispatch(createPackagingFailure());
  }
};

//delete
export const deletePackaging = async (id, dispatch) => {
  dispatch(deletePackagingStart());
  try {
    await axios1.delete("/packagings/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deletePackagingSuccess(id));
  } catch (err) {
    dispatch(deletePackagingFailure());
  }
};

export const getPackaging = async (id, dispatch) => {
  dispatch(getPackagingStart());
  try {
    const res = await axios1.get("/packagings/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getPackagingSuccess(res.data));
  } catch (err) {
    dispatch(getPackagingFailure());
  }
};

export const getPackagingsList = async () => {
  try {
    const res = await axios1.get("/packagings", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    // getPackagingsSuccess(res.data);
    return res.data
  } catch (err) {
    return err
  }
};

export const getPackageForUpdate = async (id) => {
  try {
    const res = await axios1.get("/packagings/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const updatePackaging = async (id, packaging, dispatch) => {
  dispatch(updatePackagingStart());
  try {
    const res = await axios1.put("/packagings/"+ id, packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updatePackagingSuccess(res.data));
  } catch (err) {
    dispatch(updatePackagingFailure());
  }
};

export const getPackagingsForMovies = async () => {
  try {
    const res = await axios1.get("/packagings", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};

export const getPackagingDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/movies/packaging/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getPackagingEpisodeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/episodes/packaging/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getPackagingKidsEpisodeDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/kidsepisodes/packaging/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const getPackagingKidsDataForUpdate = async (id) => {
  try {
    const res = await axios1.get("/kids/packaging/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};