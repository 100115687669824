const KidSliderReducer = (state, action) => {
  switch (action.type) {
    case "GET_KID_SLIDERS_START":
      return {
        Kidsliders: [],
        isFetching: true,
        error: false,
      };
    case "GET_KID_SLIDERS_SUCCESS":
      return {
        Kidsliders: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_KID_SLIDERS_FAILURE":
      return {
        Kidsliders: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_KID_SLIDER_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_KID_SLIDER_SUCCESS":
      return {
        Kidsliders: [...state.Kidsliders, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_KID_SLIDER_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_KID_SLIDER_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_KID_SLIDER_SUCCESS":
      return {
        Kidsliders: state.Kidsliders.map(
          (Kidslider) => Kidslider._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_KID_SLIDER_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_KID_SLIDER_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_KID_SLIDER_SUCCESS":
      return {
        Kidsliders: state.Kidsliders.filter((Kidslider) => Kidslider._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_KID_SLIDER_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_KID_SLIDER_START":
        return {
          Kidslider: {},
          isFetching: true,
          error: false,
        };
      case "GET_KID_SLIDER_SUCCESS":
        return {
          Kidslider: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_KID_SLIDER_FAILURE":
        return {
          Kidslider: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default KidSliderReducer;
