import { useContext, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./kidSlider.css";
import { Publish } from "@material-ui/icons";
import ReactS3Client from "../../s3";
import { KidSliderContext } from "../../context/kidsliderContext/KidSliderContext";
// import { SliderContext } from "../../context/sliderContext/SliderContext";
import { updateKidSlider, } from "../../context/kidsliderContext/apiCalls";
import Select from 'react-select'



export default function KidSlider() {
  const location = useLocation();
  const [slider, setMovie] = useState(location.slider);
  const { dispatch } = useContext(KidSliderContext);
  const [uploaded, setUploaded] = useState(0);
  const [img, setImg] = useState(slider.img);
  const history = useHistory();
  const [imgDimension, setImgDimensions] = useState({
    "prev_image": { width: '', height: '',size:0,oldWidth:0,oldHeight:0 }
  })
  const [oldImgDimension,setOldImgDimension]= useState({
    "prev_image":{ dim:''},
  })


  const handleChange = (e) => {
    const value = e.target.value;
    setMovie({ ...slider, [e.target.name]: value });
  };

  const upload = (items) => {
    items.forEach((item) => {

      ReactS3Client
        .uploadFile(item.file)
        .then((data) => {
          setMovie((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
                    setUploaded((prev) => prev + 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      // { file: imgTitle, label: "imgTitle" },
      // { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ]);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    updateKidSlider(slider._id,slider, dispatch);
    history.push("/kidsliders")

    // console.log(rr)
  };

  const sliderTypeValues = [
    { value: 'campaign', label: 'Campaign' },
    { value: 'movie', label: 'Movie' },
    { value: 'series', label: 'Series' },
  ]

  const handleTypeInputChange = (value) => {
    // console.log(value)
    setMovie({ ...slider, 'type': value.value });
    // setVip(value)
  };

  const oldImgDimensions = () =>{
    const oldPrevImg = document.getElementById('prev_image')

  
    const oldPrevImgDim = `${oldPrevImg.naturalWidth}` + ' x ' + `${oldPrevImg.naturalHeight}`;

  
    if(oldImgDimension.prev_image.dim == ''){
      setOldImgDimension(prev => ({...prev, prev_image:{ dim:oldPrevImgDim}}))
    }
  }

  const getImgDimensions = (str,target) => {
    let image = new Image()
    image.src = window.URL.createObjectURL(target)
    image.onload = () => {
  
      if(str === "prev_img"){
        // const oldImg = document.getElementById('image')
        setImgDimensions(prev => ({...prev, prev_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
    }
  }

  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Kids Slider</h1>
        <Link to="/newKidSlider">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={slider.img} alt="" className="productInfoImg" />
            <span className="productName">{slider.title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{slider._id}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">type:</span>
              <span className="productInfoValue">{slider.type}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">year:</span>
              <span className="productInfoValue">{slider.year}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Title</label>
            <input type="text" placeholder={slider.title} onChange={handleChange}/>
            <label>Content ID</label>
            <input name="content_id" type="text" placeholder={slider.content_id} onChange={handleChange}/>
            <label>Year</label>
            <input name="year" type="text" placeholder={slider.year} onChange={handleChange}/>
            <div className="addProductItem">
                  <label>Type</label>
                  <Select name="type" id="type" 
                    options={sliderTypeValues}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={handleTypeInputChange}
                    value={(slider.type==='movie')?sliderTypeValues[1]:(slider.type==='series')?sliderTypeValues[2]:sliderTypeValues[0]}
                  />
                </div>
            <label>Trailer</label>
            <input name="trailer" type="text" placeholder={slider.trailer} className="urlField" onChange={handleChange}/>
            <label>Video</label>
            <input name="video" type="text" placeholder={slider.video} className="urlField" onChange={handleChange}/>
          </div>
          <div className="productFormRight"> 
            <div className="productUpload">
            <div className="gen_btn" onClick={oldImgDimensions}>Show Image Details</div>
            {oldImgDimension.prev_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.prev_image.dim}`}</p> : null}
              <img
                id="prev_image"
                src={slider.img}
                alt=""
                className="productUploadImg"
              />
              <label htmlFor="file">
                <Publish />
              </label>
              <input type="file" id="file" name="img" accept="image/webp" style={{ display: "none" }} onChange={(e) => {setImg(e.target.files[0])
    getImgDimensions("prev_img",e.target.files[0])
              
              }}/>
              {imgDimension.prev_image.width && imgDimension.prev_image.height != undefined ? <p>Image dimensions : {`${imgDimension.prev_image.width} x ${imgDimension.prev_image.height}`}</p> : null}
          {imgDimension.prev_image.size !== 0 ? <p>Image size : {`${imgDimension.prev_image.size}`}KB</p> : null}
            </div>

            {uploaded === 0 ? (
          <button className="addProductButton" onClick={handleSubmit}>
            Update
          </button>
        ) : (
          <button className="addProductButton" onClick={handleUpload}>
            Upload
          </button>
        )}

            {/* <button className="productButton">Update</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
