import { useContext,useEffect, useState } from "react";
import { Link, useHistory,useParams } from "react-router-dom";
import "./slider.css";
// import { Publish } from "@material-ui/icons";
import ReactS3Client from "../../s3";
import { SliderContext } from "../../context/sliderContext/SliderContext";
import { updateSlider,getSliderForUpdate } from "../../context/sliderContext/apiCalls";
import Select from 'react-select'
import { GenreContext } from "../../context/genreContext/GenreContext";
import { getGenres, getSliderGenreDataForUpdate } from "../../context/genreContext/apiCalls";



export default function Slider() {
  // const location = useLocation();
  const {sliderId} = useParams();
  // const [slider, setMovie] = useState(location.slider);
  const [slider, setSlider] = useState([]);
  const { dispatch } = useContext(SliderContext);
  const [uploaded, setUploaded] = useState(0);
  const [img, setImg] = useState(slider.img);
  const [imgTitle, setImgTitle] = useState(null);
  const [imgSm, setImgSm] = useState(null);
  const [genOption, setGenOptions] = useState([]);
  const { genres, dispatch: dispatchGenre } = useContext(GenreContext);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgTitleSrc, setImgTitleSrc] = useState(null);
  const [imgSmSrc, setImgSmSrc] = useState(null);
  const [sliderStartDate, setSliderStartDate] = useState(null);
  const [sliderEndDate, setSliderEndDate] = useState(null);
  const [imgDimension, setImgDimensions] = useState({
    "image": { width: '', height: '',size:0 },
    "title_image": { width: '', height: '',size:0},
    "thumbnail_image": { width: '', height: '',size:0},
  })
  const [oldImgDimension,setOldImgDimension]= useState({
    "image":{ dim:''},
    "title_image":{ dim:''},
    "thumbnail_image":{ dim:''}
  })  

  const history = useHistory()



  useEffect(() => {
    
    getGenres(dispatchGenre);
    // getPackagings(dispatchPackaging);

    getSliderForUpdate(sliderId).then(function (res){
      let start_date = new Date(res.slider_start_date);
      let end_date = new Date(res.slider_end_date);
      start_date = start_date.toISOString().split('.')[0]
      end_date = end_date.toISOString().split('.')[0]
      setSliderStartDate(start_date)
      setSliderEndDate(end_date)
      setSlider(res)
      setImgSrc(res.img)
      setImgSmSrc(res.imgSm)
      setImgTitleSrc(res.imgTitle)
    })
    
    getSliderGenreDataForUpdate(sliderId).then(function (res){
      // console.log(res)
      const genVal = res.map((ge) => (
        {value:ge.content_data._id,label:ge.content_data.name}
      ))
      setGenOptions(genVal)
    })
    
  }, [dispatchGenre, sliderId]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSlider({ ...slider, [e.target.name]: value });
  };

  const handleSliderStartDateChange = (e) => {
    const value = e.target.value;
    setSliderStartDate(value)
    let dd = new Date(value)
    setSlider({ ...slider, [e.target.name]: dd });
  }

  const handleSliderEndDateChange = (e) => {
    const value = e.target.value;
    setSliderEndDate(value)
    let dd = new Date(value)
    setSlider({ ...slider, [e.target.name]: dd });
  }

  const upload = (items) => {
    items.forEach((item) => {

      ReactS3Client
        .uploadFile(item.file)
        .then((data) => {
          setSlider((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
                    setUploaded((prev) => prev - 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      { file: imgTitle, label: "imgTitle" },
      { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ]);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    updateSlider(slider._id,slider, dispatch);
    history.push("/sliders")
    // history.goBack();

    // console.log(rr)
  };

  const sliderTypeValues = [
    { value: 'campaign', label: 'Campaign' },
    { value: 'movie', label: 'Movie' },
    { value: 'series', label: 'Series' },
  ]

  const handleTypeInputChange = (value) => {
    // console.log(value)
    setSlider({ ...slider, 'type': value.value });
    // setVip(value)
  };

  const handleInputGenChange = (value) => {
    const val = []
    for (let i = 0; i < value.length; i++) {
           val.push(value[i].label);
        }
        Promise.resolve(setSlider({ ...slider, 'genre': val }));
    setGenOptions(value)
  };

  const allGenreData =  genres.map((gen) => (
    {value:gen._id,label:gen.name}
))

const handleChangeImg = (e) => {
  setImgSrc(URL.createObjectURL(e.target.files[0]))
  getImgDimensions("img",e.target.files[0])
  setImg(e.target.files[0])
  setUploaded((prev) => prev + 1);
  
};

const handleChangeImgSm = (e) => {
  setImgSmSrc(URL.createObjectURL(e.target.files[0]))
  getImgDimensions("thumb_img",e.target.files[0])
  setImgSm(e.target.files[0])
  setUploaded((prev) => prev + 1);
  
};

const handleChangeImgTitle = (e) => {
  setImgTitleSrc(URL.createObjectURL(e.target.files[0]));
  getImgDimensions("title_img",e.target.files[0])
  setImgTitle(e.target.files[0])
  setUploaded((prev) => prev + 1);

};

const oldImgDimensions = () =>{
  const oldImg = document.getElementById('image')
  const oldThumbImg = document.getElementById('thum_image')
  const oldTitleImg = document.getElementById('title_img')

  const oldImgDim = `${oldImg.naturalWidth}` + ' x ' + `${oldImg.naturalHeight}`;
  const oldThumImgDim = `${oldThumbImg.naturalWidth}` + ' x ' + `${oldThumbImg.naturalHeight}`;
  const oldTitleImgDim = `${oldTitleImg.naturalWidth}` + ' x ' + `${oldTitleImg.naturalHeight}`;

  // console.log(oldImgDim,oldThumImgDim,oldTitleImgDim)

  if(oldImgDimension.image.dim == ''){
    setOldImgDimension(prev => ({...prev, image:{ dim:oldImgDim}, title_image:{dim: oldTitleImgDim}, thumbnail_image:{dim: oldThumImgDim}}))
  }
}

const getImgDimensions = (str,target) => {
  let image = new Image()
  image.src = window.URL.createObjectURL(target)
  image.onload = () => {

    if(str === "img"){
      // const oldImg = document.getElementById('image')
      setImgDimensions(prev => ({...prev, image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "title_img"){
      setImgDimensions(prev => ({...prev, title_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
    else if(str === "thumb_img"){
      setImgDimensions(prev => ({...prev, thumbnail_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
    }
  }
}


  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Slider</h1>
        <Link to="/newSlider">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={slider.img} alt="" className="productInfoImg" />
            <span className="productName">{slider.title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{slider._id}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">type:</span>
              <span className="productInfoValue">{slider.type}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">year:</span>
              <span className="productInfoValue">{slider.year}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Title</label>
            <input name="title" type="text" placeholder={slider.title} onChange={handleChange}/>
            {/* <label>Description</label> */}
            {/* <input name="desc" type="text" placeholder={slider.desc} onChange={handleChange}/> */}
            {/* <textarea id="textarea" name="desc" rows="6" cols="55" placeholder={slider.desc} onChange={handleChange}></textarea> */}
            {/* <label>Content ID</label> */}
            {/* <input name="content_id" type="text" placeholder={slider.content_id} onChange={handleChange}/> */}
            {/* <label>Year</label> */}
            {/* <input name="year" type="text" placeholder={slider.year} onChange={handleChange}/> */}
            <div className="addProductItem">
                  <label>Type</label>
                  <Select name="type" id="type" 
                    options={sliderTypeValues}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={handleTypeInputChange}
                    value={(slider.type==='movie')?sliderTypeValues[1]:(slider.type==='series')?sliderTypeValues[2]:sliderTypeValues[0]}
                  />
                </div>
            
              {/* <div className="addProductItem">
              <label>Genre</label>
              <Select name="genre" id="genre" 
                options={allGenreData}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                onChange={handleInputGenChange}
                value={genOption}
                />
            </div> */}
            <div className="addProductItem">
          <label>Slider Start Date-Time</label>
          <input
            type="datetime-local"
            placeholder="Slider Start Date-TIme"
            required="required"
            name="slider_start_date"
            value={sliderStartDate}
            onChange={handleSliderStartDateChange}
            />
        </div>
        <div className="addProductItem">
          <label>Slider End Date-Time</label>
          <input
            type="datetime-local"
            placeholder="Slider End Date-Time"
            required="required"
            name="slider_end_date"
            value={sliderEndDate}
            onChange={handleSliderEndDateChange}
          />
        </div>
            {/* <label>Trailer</label> */}
            {/* <input name="trailer" type="text" placeholder={slider.trailer} className="urlField" onChange={handleChange}/> */}
            {/* <label>Video</label> */}
            {/* <input name="video" type="text" placeholder={slider.video} className="urlField" onChange={handleChange}/> */}
          </div>
          <div className="productFormRight">
              <div className="productUpload">
              <div className="gen_btn" onClick={oldImgDimensions}>Show Image Details</div>
              <h3>Mobile Slider Image</h3>
              Suggested Image Dimensions : 600 x 1000
              {oldImgDimension.image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.image.dim}`}</p> : null}
                <img
                id="image"
                  src={imgSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="file" accept="image/webp" name="img"  onChange={handleChangeImg}/>
                {imgDimension.image.width && imgDimension.image.height != undefined ? <p>Image dimensions : {`${imgDimension.image.width} x ${imgDimension.image.height}`}</p> : null}
          {imgDimension.image.size !== 0 ? <p>Image size : {`${imgDimension.image.size}`}KB</p> : null}
              </div>

              <div className="productUpload">
              <h3>TV/Web Slider Image</h3>
              Suggested Image Dimensions : 3840 x 2160
              {oldImgDimension.thumbnail_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.thumbnail_image.dim}`}</p> : null}
                <img
                id="thum_image"
                  src={imgSmSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="imgSm" name="imgSm" accept="image/webp"  onChange={handleChangeImgSm}/>
                {imgDimension.thumbnail_image.width && imgDimension.thumbnail_image.height != undefined ? <p>Image dimensions : {`${imgDimension.thumbnail_image.width} x ${imgDimension.thumbnail_image.height}`}</p> : null}
          {imgDimension.thumbnail_image.size !== 0 ? <p>Image size : {`${imgDimension.thumbnail_image.size}`}KB</p> : null}
              </div>

              <div className="productUpload title_img">
              <h3>Title Slider Image</h3>
              Suggested Image Dimensions : 400 x 300
              {oldImgDimension.title_image.dim != '' ? <p>Image Dimensions : {`${oldImgDimension.title_image.dim}`}</p> : null}
                <img
                  id="title_img"
                  src={imgTitleSrc}
                  alt=""
                  className="productUploadImg"
                />
                <label htmlFor="file">
                  {/* <Publish /> */}
                </label>
                <input type="file" id="imgTitle" accept="image/webp" name="imgTitle"  onChange={handleChangeImgTitle}/>
                {imgDimension.title_image.width && imgDimension.title_image.height != undefined ? <p>Image dimensions : {`${imgDimension.title_image.width} x ${imgDimension.title_image.height}`}</p> : null}
          {imgDimension.title_image.size !== 0 ? <p>Image size : {`${imgDimension.title_image.size}`}KB</p> : null}
              </div>

              {uploaded === 0 ? (
            <button className="addProductButton" onClick={handleSubmit}>
              Update
            </button>
            ) : (
            <button className="addProductButton" onClick={handleUpload}>
              Upload
            </button>
            )}

            {/* <button className="productButton">Update</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
