export const getSlidersStart = () => ({
  type: "GET_SLIDERS_START",
});

export const getSlidersSuccess = (sliders) => ({
  type: "GET_SLIDERS_SUCCESS",
  payload: sliders,
});

export const getSlidersFailure = () => ({
  type: "GET_SLIDERS_FAILURE",
});

export const createSliderStart = () => ({
  type: "CREATE_SLIDER_START",
});

export const createSliderSuccess = (sliders) => ({
  type: "CREATE_SLIDER_SUCCESS",
  payload: sliders,
});

export const createSliderFailure = () => ({
  type: "CREATE_SLIDER_FAILURE",
});

export const updateSliderStart = () => ({
  type: "UPDATE_SLIDER_START",
});

export const updateSliderSuccess = (slider) => ({
  type: "UPDATE_SLIDER_SUCCESS",
  payload: slider,
});

export const updateSliderFailure = () => ({
  type: "UPDATE_SLIDER_FAILURE",
});

export const deleteSliderStart = () => ({
  type: "DELETE_SLIDER_START",
});

export const deleteSliderSuccess = (id) => ({
  type: "DELETE_SLIDER_SUCCESS",
  payload: id,
});

export const deleteSliderFailure = () => ({
  type: "DELETE_SLIDER_FAILURE",
});

export const getSliderStart = () => ({
  type: "GET_SLIDER_START",
});

export const getSliderSuccess = (slider) => ({
  type: "GET_SLIDER_SUCCESS",
  payload: slider,
});

export const getSliderFailure = () => ({
  type: "GET_SLIDER_FAILURE",
});
