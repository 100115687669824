import "./listList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ListContext } from "../../context/listContext/ListContext";
import { deleteList, getLists } from "../../context/listContext/apiCalls";
import Loader from "../../components/loader/Loader";

export default function ListList() {
  const { isFetching, lists, dispatch } = useContext(ListContext);

  useEffect(() => {
    getLists(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deleteList(id, dispatch);
  };

  console.log(lists)

  const columns = [
    { field: "_id", headerName: "SNo", width: 84,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true, },
    { field: "title", flex:1,headerName: "Title", width: 250 },
    { field: "genre", headerName: "Genre", width: 150 },
    { field: "position", headerName: "Position", width: 150 },
    { field: "type", headerName: "type", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/list/" + params.row._id, list: params.row }}
            >
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Slider List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {lists.length}</h2>
        <Link to="/newList">
          <button className="productAddButton">Create</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={lists}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
  {/* } */}
    </div>
  );
}
