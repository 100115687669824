import "./subNowVideoList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SubNowVideoContext } from "../../context/subNowVideoContext/SubNowVideoContext";
import { deleteSubNowVideo, getSubNowVideos } from "../../context/subNowVideoContext/apiCalls";
import Switch from '@material-ui/core/Switch';
// import Loader from "../../components/loader/Loader";
import { getConfigs, updateConfig } from "../../context/configContext/apiCalls";
import { ConfigContext } from "../../context/configContext/ConfigContext";


export default function SubNowVideoList() {
  const {isFetching, subnowvideos, dispatch } = useContext(SubNowVideoContext);
  const {dispatch: configsDispatch } = useContext(ConfigContext);
  const [subNowVideoData, setSubNowVideoData] = useState([]);

  const [state, setState] = useState({
    sub_now_video_lock: false
  });

  useEffect(() => {
    getSubNowVideos(dispatch);
    getConfigs(configsDispatch).then(function (res){
      // console.log(res)
      if (res) {
            // console.log(res)
            const subNowVidoeConfig = res.find(c => c.config_type === "sub_now_video_lock");
            if (subNowVidoeConfig) {
              setState(prevState => ({ ...prevState, sub_now_video_lock: subNowVidoeConfig.value === "true" ? true:false }));
              setSubNowVideoData(subNowVidoeConfig)
            }
          }
    })
  }, [configsDispatch, dispatch]);

  const handleDelete = (id) => {
    deleteSubNowVideo(id, dispatch);
  };

  const handleChange = (event) => {

      const updatedSubNowVideoData = {
        ...subNowVideoData,
        value: event.target.checked ? "true" : "false",
      };
  
      setSubNowVideoData(updatedSubNowVideoData);
  
      updateConfig(updatedSubNowVideoData._id, updatedSubNowVideoData, configsDispatch)
        .then((res) => {
          // console.log(res);
          setState(prevState => ({ ...prevState, sub_now_video_lock: res.value === "true" ? true : false }));
        });
  };

  const columns = [
    { field: "_id", headerName: "SNo", width: 84,renderCell:(index) => index.api.getRowIndex(index.row._id) + 1 ,
    disableColumnMenu:true,  },
    {
      field: "packaging",
      headerName: "Package Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="packagingListItem">
            {params.row.title}
          </div>
        );
      },
    },
    { field: "url", headerName: "URL",flex:1, width: 200 },
    {
      field: "sub_now_video_lock",
      headerName: "Static Sub Now Video",
      width: 250,
      renderCell: (params) => {
        return (
          <>
            {/* <FormControlLabel
        // label="Guest Login"
        control={ */}
            <Switch
            checked={state.sub_now_video_lock}
            onChange={handleChange}
            color="primary"
            name="sub_now_video_lock"
             />

        {/* }
        /> */}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{ pathname: "/packaging/" + params.row._id, packaging: params.row,id: params.row._id }}
            >
              {/* <button className="packagingListEdit">Edit</button> */}
            </Link>
            <DeleteOutline
              className="packagingListDelete"
              onClick={() => {
                let answer = window.confirm("Do you want to delete this item?")
                if(answer){
                  handleDelete(params.row._id)
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="packagingList">
      <div className="productListHeading">
        <h1 className="addProductTitle" >Subscribe Now Video List</h1>
        <div className="productListHeadingR">
        <h2 className="productNumber">No of records : {subnowvideos.length}</h2>
        <Link to="/new-subscribe-video">
          <button className="productAddButton">Create</button>
        </Link>
        </div>
      </div>
      {/* {isFetching ? <Loader type={"spin"} color={"#000080"} className="spinner"></Loader>: */}
      <DataGrid
        rows={subnowvideos}
        disableSelectionOnClick
        columns={columns}
        pageSize={15}
        loading={isFetching}
        // checkboxSelection
        getRowId={(r) => r._id}
      />
   {/* } */}
    </div>
  );
}