import SliderReducer from "./SliderReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  sliders: [],
  isFetching: false,
  error: false,
};

export const SliderContext = createContext(INITIAL_STATE);

export const SliderContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SliderReducer, INITIAL_STATE);

  return (
    <SliderContext.Provider
      value={{
        sliders: state.sliders,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </SliderContext.Provider>
  );
};
