import axios from "axios";
import {
  createSubNowVideoFailure,
  createSubNowVideoStart,
  createSubNowVideoSuccess,
  deleteSubNowVideoFailure,
  deleteSubNowVideoStart,
  deleteSubNowVideoSuccess,
  getSubNowVideosFailure,
  getSubNowVideosStart,
  getSubNowVideosSuccess,
  getSubNowVideoFailure,
  getSubNowVideoStart,
  getSubNowVideoSuccess,
  updateSubNowVideoFailure,
  updateSubNowVideoStart,
  updateSubNowVideoSuccess,
} from "./SubNowVideoActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getSubNowVideos = async (dispatch) => {
  dispatch(getSubNowVideosStart());
  try {
    const res = await axios1.get("/subNowVideos", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getSubNowVideosSuccess(res.data));
  } catch (err) {
    dispatch(getSubNowVideosFailure());
  }
};

//create
export const createSubNowVideo = async (packaging, dispatch) => {
  dispatch(createSubNowVideoStart());
  try {
    const res = await axios1.post("/subNowVideos", packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createSubNowVideoSuccess(res.data));
  } catch (err) {
    dispatch(createSubNowVideoFailure());
  }
};

//delete
export const deleteSubNowVideo = async (id, dispatch) => {
  dispatch(deleteSubNowVideoStart());
  try {
    await axios1.delete("/subNowVideos/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteSubNowVideoSuccess(id));
  } catch (err) {
    dispatch(deleteSubNowVideoFailure());
  }
};

export const getSubNowVideo = async (id, dispatch) => {
  dispatch(getSubNowVideoStart());
  try {
    const res = await axios1.get("/subNowVideos/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getSubNowVideoSuccess(res.data));
  } catch (err) {
    dispatch(getSubNowVideoFailure());
  }
};

export const getSubNowVideosList = async () => {
  try {
    const res = await axios1.get("/subNowVideos", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    // getSubNowVideosSuccess(res.data);
    return res.data
  } catch (err) {
    return err
  }
};

export const getPackageForUpdate = async (id) => {
  try {
    const res = await axios1.get("/subNowVideos/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};

export const updateSubNowVideo = async (id, packaging, dispatch) => {
  dispatch(updateSubNowVideoStart());
  try {
    const res = await axios1.put("/subNowVideos/"+ id, packaging, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateSubNowVideoSuccess(res.data));
  } catch (err) {
    dispatch(updateSubNowVideoFailure());
  }
};

export const getSubNowVideosForMovies = async () => {
  try {
    const res = await axios1.get("/subNowVideos", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data
  } catch (err) {
    return err
  }
};

// export const getSubNowVideoDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/movies/subNowVideo/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getSubNowVideoEpisodeDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/episodes/subNowVideo/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getSubNowVideoKidsEpisodeDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/kidsepisodes/subNowVideo/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };

// export const getSubNowVideoKidsDataForUpdate = async (id) => {
//   try {
//     const res = await axios1.get("/kids/subNowVideo/" + id, {
//       headers: {
//         token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     return err
//   }
// };