import "./widgetSm.css";
// import { Visibility } from "@material-ui/icons";
import { useEffect, useState } from "react";
import axios from "axios";

export default function WidgetSm() {
  const [newUsers, setNewUsers] = useState([]);
  const [joiningDate, setJoiningDate] = useState(null);
  const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };



  useEffect(() => {
    const getNewUsers = async () => {
      try {
        const res = await axios1.get("/users?new=true", {
          headers: {
            token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        });
        setNewUsers(res.data);
        // let date = new Date(res.data.createdAt);
        // setJoiningDate(forma)

      } catch (err) {
        // console.log(err);
      }
    };
    getNewUsers();
  }, []);

  
  
  
  return (
    <div className="widgetSm">
      <span className="widgetSmTitle">New Join Members</span>
      <ul className="widgetSmList">
        {newUsers.map((user) => (
          <li className="widgetSmListItem" key={user._id}>
            <img
              src={
                user.profilePic ||
                "https://pbs.twimg.com/media/D8tCa48VsAA4lxn.jpg"
              }
              alt=""
              className="widgetSmImg"
            />
            <div className="widgetSmUser">
              <span className="widgetSmUsername">{user.username}</span>
            </div>
            <div className="wx-10 widgetSmImg">
              <span className="widgetSmIcon">{user.extra_metadata.length > 0 ? JSON.parse(user.extra_metadata).city + ', ' + JSON.parse(user.extra_metadata).country + ' (' + JSON.parse(user.extra_metadata).zip + ')' :'No Location' }</span>
            </div>
            {/* <button className="widgetSmButton"> */}
              {/* <Visibility className="widgetSmIcon" /> */}
              <div className="widgetSmUser">
              {/* <span className="widgetSmIcon">{new Date(user.createdAt).toLocaleString()}</span> */}
              <span className="widgetSmIcon">{new Date(user.createdAt).toLocaleString('en-us')}</span>
            </div>
              {/* <Visibility className="widgetSmIcon" /> */}
            {/* </button> */}
          </li>
        ))}
      </ul>
    </div>
  );
}
