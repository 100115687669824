const KidsEpisodeReducer = (state, action) => {
  switch (action.type) {
    case "GET_KIDSEPISODES_START":
      return {
        kidsepisodes: [],
        isFetching: true,
        error: false,
      };
    case "GET_KIDSEPISODES_SUCCESS":
      return {
        kidsepisodes: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_KIDSEPISODES_FAILURE":
      return {
        kidsepisodes: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_KIDSEPISODE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_KIDSEPISODE_SUCCESS":
      return {
        kidsepisodes: [...state.kidsepisodes, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_KIDSEPISODE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_KIDSEPISODE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_KIDSEPISODE_SUCCESS":
      return {
        kidsepisodes: state.kidsepisodes.map(
          (kidsepisode) => kidsepisode._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_KIDSEPISODE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_KIDSEPISODE_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_KIDSEPISODE_SUCCESS":
      return {
        kidsepisodes: state.kidsepisodes.filter((kidsepisode) => kidsepisode._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_KIDSEPISODE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_KIDSEPISODE_START":
        return {
          kidsepisode: {},
          isFetching: true,
          error: false,
        };
      case "GET_KIDSEPISODE_SUCCESS":
        return {
          kidsepisode: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_KIDSEPISODE_FAILURE":
        return {
          kidsepisode: {},
          isFetching: false,
          error: true,
        };
        case "GET_KIDSSERIESS_START":
          return {
            kidsseries: [],
            isFetching: true,
            error: false,
          };
        case "GET_KIDSSERIESS_SUCCESS":
          return {
            kidsseries: action.payload,
            isFetching: false,
            error: false,
          };
        case "GET_KIDSSERIESS_FAILURE":
          return {
            kidsseries: [],
            isFetching: false,
            error: true,
          };
          case "GET_KIDSSEASONS_START":
            return {
              kidsseasons: [],
              isFetching: true,
              error: false,
            };
          case "GET_KIDSSEASONS_SUCCESS":
            return {
              kidsseasons: action.payload,
              isFetching: false,
              error: false,
            };
          case "GET_KIDSSEASONS_FAILURE":
            return {
              kidsseasons: [],
              isFetching: false,
              error: true,
            };
    default:
      return { ...state };
  }
};

export default KidsEpisodeReducer;
