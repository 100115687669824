import { useContext, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import "./packaging.css";
import { SliderContext } from "../../context/sliderContext/SliderContext";
import { useEffect } from "react";
import { getPackageForUpdate, updatePackaging } from "../../context/packagingContext/apiCalls";



export default function Packaging() {
  const { dispatch } = useContext(SliderContext);
  const [packaging, setpackaging] = useState([]);
  const {packagingId} = useParams();
  const history = useHistory()




  useEffect(()=>{
    getPackageForUpdate(packagingId).then(function (res){
      setpackaging(res)
    })
  },[packagingId])


  const handleChange = (e) => {
    const value = e.target.value;
    setpackaging({ ...packaging, [e.target.name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    updatePackaging(packaging._id,packaging, dispatch);
    history.push("/packagings")

    // console.log(rr)
  };

  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Edit Packaging</h1>
        <Link to="/newMovie">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopRight">
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue">{packaging?._id}</span>
            </div>
            <div className="">
              <span className="productInfoKey">Package Name:</span>
              <span className="productInfoValue">{packaging?.title}</span>
            </div>
            <div className="">
              <span className="productInfoKey">URL:</span>
              <span className="productInfoValue">{packaging?.url}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Package Name</label>
            <input type="text" name="title" placeholder={packaging?.title} onChange={handleChange}/>
            <label>URL</label>
            <input type="text" name="url" className="urlField" placeholder={packaging?.url} onChange={handleChange}/>
          </div>
          <div className="productFormRight"> 
            <button className="productButton" onClick={handleSubmit}>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
