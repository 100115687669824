import S3 from 'react-aws-s3';

const config = {
    bucketName: 'flock-ott',
    dirName: '', /* optional */
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_S3_CLIENT_ID,
    secretAccessKey: process.env.REACT_APP_S3_CLIENT_SECRET,
  }
  
const ReactS3Client = new S3(config);
/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

export default ReactS3Client;
