import { useContext, useEffect, useState } from "react";
import "./newEpisode.css";
import ReactS3Client from "../../s3";
// import { createMovie } from "../../context/movieContext/apiCalls";
import { createEpisode } from "../../context/episodeContext/apiCalls";
// import { MovieContext } from "../../context/movieContext/MovieContext";
import { EpisodeContext } from "../../context/episodeContext/EpisodeContext";
import {v4 as uuid} from "uuid";
import { useHistory } from "react-router-dom";
import { getPackagingsForMovies } from "../../context/packagingContext/apiCalls";
import { getContentsList } from "../../context/movieContext/apiCalls";
import Select from 'react-select'



export default function NewEpisode() {
  const [episode, setEpisode] = useState(null);
  const history = useHistory()
  const [img, setImg] = useState(null);
  const [imgDimension, setImgDimensions] = useState({
    "prev_image": { width: '', height: '',size:0 },
  })
  // const [imgTitle, setImgTitle] = useState(null);
  // const [imgSm, setImgSm] = useState(null);
  // const [trailer, setTrailer] = useState(null);
  // const [video, setVideo] = useState(null);
  const [uploaded, setUploaded] = useState(0);
  const [packagingOptions, setPackagingOptions] = useState([]);
  const [seriesOptionsList,setSeriesOptionsList] = useState([]);
  const { dispatch } = useContext(EpisodeContext);

  useEffect(() => {

    getPackagingsForMovies().then(function (pac){
      const packValue = pac?.map((pa) => (
        {value:pa.url,label:pa.title}
    ))
    
    setPackagingOptions(packValue)
    })

    // getContentsList().then(function(series){
    //   // console.log(series)
    //   const seriesOptions = series.map((se) =>{
    //     if(se.type == 'series')
    //      return ({ value : se.title, label : se.title })
    //   }
    //   )
    //   setSeriesOptionsList(seriesOptions)
    // })
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setEpisode({ ...episode, [e.target.name]: value });
  };

  const upload = (items) => {
    items.forEach((item) => {
      // const fileName = new Date().getTime() + item.label + item.file.name;
      const fileName = new Date().getTime() + uuid();

      ReactS3Client
        .uploadFile(item.file,'episodes/'+fileName)
        .then((data) => {
          setEpisode((prev) => {
                      return { ...prev, [item.label]: data.location };
                    });
                    setUploaded((prev) => prev + 1);
          // console.log(data)
        })
        .catch(err => console.error(err))
      });
  };

  const handlePackInputChange = (value) => {
    setEpisode({ ...episode, 'packaging': value.value });
};

  const handleUpload = (e) => {
    e.preventDefault();
    upload([
      { file: img, label: "img" },
      // { file: imgTitle, label: "imgTitle" },
      // { file: imgSm, label: "imgSm" },
      // { file: trailer, label: "trailer" },
      // { file: video, label: "video" },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createEpisode(episode, dispatch)
    history.push("/episodes")
  };

  const getImgDimensions = (str,target) => {
    let dim;
    let image = new Image()
    image.src = window.URL.createObjectURL(target)
    image.onload = () => {
      if(str === "prev_img"){
        setImgDimensions(prev => ({...prev, prev_image : {width:image.width,height:image.height,size:(target.size/1024).toFixed(2)} }))
      }
    }
  }

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Episode</h1>
      <form className="addProductForm">
        <div className="input_fields">
        <div className="addContentItem">
          <label>Series</label>
          <input
            required
            type="text"
            placeholder="Series ID"
            name="series_id"
            onChange={handleChange}
          />
        </div>

        {/* <div className="addContentItem">
          <label>Series Name</label>
          <Select name="packaging" id="packaging" 
            options={seriesOptionsList}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={true}
            onChange={handlePackInputChange}
          />
        </div> */}
        <div className="inline_input_holder">
        <div className="addContentItem inline">
          <label>Season No.</label>
          <input
            required
            type="number"
            placeholder="Season No."
            name="season_id"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem inline">
          <label>Episode No.</label>
          <input
            required
            type="number"
            placeholder="Episode No."
            name="episode_id"
            onChange={handleChange}
          />
        </div>

        </div>
                <div className="addContentItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="John Wick"
            name="title"
            required
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem">
          <label>Description</label>
          <input
            required
            type="text"
            placeholder="Description"
            name="desc"
            onChange={handleChange}
          />
        </div>
        <div className="inline_input_holder">
        <div className="addContentItem inline">
          <label>Skip Intro</label>
          <input
            required
            type="number"
            placeholder="Skip Intro in sec"
            name="skip_intro"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem inline">
          <label>Skip Intro End</label>
          <input
            required
            type="number"
            placeholder="Skip Intro end in sec"
            name="skip_intro_end"
            onChange={handleChange}
          />
        </div>
        </div>
        
        <div className="inline_input_holder">
        <div className="addContentItem inline">
          <label>Year</label>
          <input
            required
            type="text"
            placeholder="Year"
            name="year"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem inline">
          <label>Limit</label>
          <input
            required
            type="text"
            placeholder="Limit"
            name="limit"
            onChange={handleChange}
          />
        </div>
        </div>
        {/* <div className="addContentItem">
          <label>Genre</label>
          <select name="genre" id="genre" onChange={handleChange}>
            <option value="" >Select Genre</option>
            <option value="action">Action</option>
            <option value="comedy">Comedy</option>
            <option value="horror">Horror</option>
            <option value="crime">Crime</option>
          </select>
        </div> */}
        <div className="addContentItem">
          <label>Packaging</label>
          <Select name="packaging" id="packaging" 
            options={packagingOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={true}
            onChange={handlePackInputChange}
          />
        </div>

        <div className="inline_input_holder">
        <div className="addContentItem inline">
          <label>Video Duration</label>
          <input
            required
            type="number"
            placeholder="Video duration in sec"
            name="video_duration"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem inline">
          <label>Next Episode Play</label>
          <input
            required
            type="number"
            placeholder="Video duration in sec"
            name="next_ep_play"
            onChange={handleChange}
          />
        </div>
        </div>
        
        
        
        
       
        </div>
        <div className="image_input">
        <div className="addContentItem">
          <label>Is VIP</label>
          <select name="vip" id="vip" onChange={handleChange}>
            <option value="">Select VIP </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <div className="addContentItem">
          <label>Video</label>
          <input  
            required
            type="text"
            name="video"
            onChange={handleChange}
          />
        </div>
        <div className="addContentItem">
          <label>Preview Image</label>
          <input
          
            type="file"
            accept="image/webp"
            id="img"
            name="img"
            required="required"
            onChange={(e) => {setImg(e.target.files[0])
              getImgDimensions("prev_img",e.target.files[0])}}
          />
          {imgDimension.prev_image.width && imgDimension.prev_image.height != undefined ? <p>Image dimensions : {`${imgDimension.prev_image.width} x ${imgDimension.prev_image.height}`}</p> : null}
          {imgDimension.prev_image.size !== 0 ? <p>Image size : {`${imgDimension.prev_image.size}`}KB</p> : null}
        </div>
        {/* <div className="addContentItem">
          <label>Title image</label>
          <input
            required
            type="file"
            id="imgTitle"
            name="imgTitle"
            onChange={(e) => setImgTitle(e.target.files[0])}
          />
        </div>
        <div className="addContentItem">
          <label>Thumbnail image</label>
          <input
            required
            type="file"
            id="imgSm"
            name="imgSm"
            onChange={(e) => setImgSm(e.target.files[0])}
          />
        </div> */}

        </div>
        <div className="button_holder">

        {uploaded === 1 ? (
          <button className="addProductButton padding-20" onClick={handleSubmit}>
            Create
          </button>
        ) : (
          <button className="addProductButton padding-20" onClick={handleUpload}>
            Upload
          </button>
        )}
        </div>
      </form>
    </div>
  );
}
