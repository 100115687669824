import axios from "axios";
import {
  createSliderFailure,
  createSliderStart,
  createSliderSuccess,
  deleteSliderFailure,
  deleteSliderStart,
  deleteSliderSuccess,
  getSlidersFailure,
  getSlidersStart,
  getSlidersSuccess,
  getSliderFailure,
  getSliderStart,
  getSliderSuccess,
  updateSliderFailure,
  updateSliderStart,
  updateSliderSuccess,
} from "./SliderActions";

const axios1 = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const getSliders = async (dispatch) => {
  dispatch(getSlidersStart(null));
  try {
    const res = await axios1.get("/sliders", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getSlidersSuccess(res.data));
  } catch (err) {
    dispatch(getSlidersFailure());
  }
};

//create
export const createSlider = async (slider, dispatch) => {
  dispatch(createSliderStart());
  try {
    const res = await axios1.post("/sliders", slider, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createSliderSuccess(res.data));
  } catch (err) {
    dispatch(createSliderFailure());
  }
};

//delete
export const deleteSlider = async (id, dispatch) => {
  dispatch(deleteSliderStart());
  try {
    await axios1.delete("/sliders/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteSliderSuccess(id));
  } catch (err) {
    dispatch(deleteSliderFailure());
  }
};

export const getSlider = async (id, dispatch) => {
  dispatch(getSliderStart());
  try {
    const res = await axios1.get("/sliders/find/ " + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getSliderSuccess(res.data));
  } catch (err) {
    dispatch(getSliderFailure());
  }
};

export const updateSlider = async (id, slider, dispatch) => {
  dispatch(updateSliderStart());
  try {
    const res = await axios1.put("/sliders/"+ id, slider, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateSliderSuccess(res.data));
  } catch (err) {
    dispatch(updateSliderFailure());
  }
};

export const getSliderForUpdate = async (id) => {
  try {
    const res = await axios1.get("/sliders/find/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    return res.data;
  } catch (err) {
    return err
  }
};
