import React, { useContext, useState } from "react";
import { login } from "../../context/authContext/apiCalls";
import { AuthContext } from "../../context/authContext/AuthContext";
import "./login.css";

//Importing Images
import Logo from '../../assets/images/FlockOtt_logo.png';

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    login({ email, password }, dispatch);
  };

  return (
    <div className="main_container">
      <div className="main_content">
        <div className="content_login">
          <form>
            <span>Flock Admin</span>
            <div className="form_control">
              <label htmlFor="email">Email Address</label>
              <input
                type="text"
                placeholder="Enter Email"
                className="loginInput"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form_control">
              <label htmlFor="email">Password</label>
              <input
                type="password"
                placeholder="Enter Password"
                className="loginInput"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="formBtn">
              <a href="#"><label htmlFor="pwdFgt">Forgot Password?</label></a>
              <button
                className="loginButton"
                onClick={handleLogin}
                disabled={isFetching}
              >Login</button>
            </div>
          </form>
        </div>
        <div className="content_details">
          <div className="upDet">
            <img src={Logo} alt="logo" />
            <div className="heading">Welcome to Flock OTT Admin Panel!</div>
            <div className="para">We are a next-gen media company dedicated to creating content that will inspire the future of streaming and film-making. Streaming, OTT and other visual platforms have become every individual's go-to mediums to stay entertained and informed!</div>
          </div>
        </div>
      </div>
    </div>
  );
}
