const ConfigReducer = (state, action) => {
  switch (action.type) {
    case "GET_CONFIGS_START":
      return {
        configs: [],
        isFetching: true,
        error: false,
      };
    case "GET_CONFIGS_SUCCESS":
      return {
        configs: action.payload,
        isFetching: false,
        error: false,
      };
    case "GET_CONFIGS_FAILURE":
      return {
        configs: [],
        isFetching: false,
        error: true,
      };
    case "CREATE_CONFIG_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "CREATE_CONFIG_SUCCESS":
      return {
        configs: [...state.configs, action.payload],
        isFetching: false,
        error: false,
      };
    case "CREATE_CONFIG_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "UPLOAD_CONFIG_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "UPLOAD_CONFIG_SUCCESS":
      return {
        configs: state.configs.map(
          (config) => config._id === action.payload._id && action.payload
        ),
        isFetching: false,
        error: false,
      };
    case "UPLOAD_CONFIG_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "DELETE_CONFIG_START":
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case "DELETE_CONFIG_SUCCESS":
      return {
        configs: state.configs.filter((config) => config._id !== action.payload),
        isFetching: false,
        error: false,
      };
    case "DELETE_CONFIG_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
      case "GET_CONFIG_START":
        return {
          config: {},
          isFetching: true,
          error: false,
        };
      case "GET_CONFIG_SUCCESS":
        return {
          config: action.payload,
          isFetching: false,
          error: false,
        };
      case "GET_CONFIG_FAILURE":
        return {
          config: {},
          isFetching: false,
          error: true,
        };
    default:
      return { ...state };
  }
};

export default ConfigReducer;
